@keyframes emphasize {
  0% {
    transform: translate(-50%, -50%) scale(1)
  }
  
  50% {
    transform: translate(-50%, -50%) scale(1.5)
  }
  
  100% {
    transform: translate(-50%, -50%) scale(1)
  }
}

.learnMoreWrapper {
  cursor: pointer;
  padding: 0;
  margin-top: 60px;
  position: relative;
  @include clearfix;
  padding: 23px 0 0 0;
  transition: all 300ms;
  transition: border-color .3s, opacity .6s, transform .5s, padding .3s, margin .3s;
  transform: translateY(25px);
  opacity: 0;
  font-size: .85em;
  
  .current & {
    transform: translateY(0);
    opacity: 1;
  }
  
  .toolTip {
    @include noHighlight;
    // position: absolute;
    // bottom: 58px;
    // left: 0;
    width: 100%;
    color: $basic;
    background: rgba($light, .75);
    padding: 15px 15px;
    text-align: center;
    font-size: 1.125em;
    z-index: 1;
    transition: .3s all;
    z-index: -1;
    
    label {
      pointer-events: none;
    }
  }
  
  input[type=checkbox] {
    display: none;
  }
  
  @media (min-width: $md) {
    @include hover {
      .toolTip {
        box-shadow: 0px 0px 8px 8px rgb(0 0 0 / 20%);
      }
      
      
      .icon {
        font-size: 4em;
        -webkit-text-stroke: 2px $rocket;
        margin-top: -10px;
      }
    }
  }
  
  &.selected {
    @media (min-width: $md) {
      @include hover {
        .lmNote {
          box-shadow: 0px 0px 8px 8px rgb(0 0 0 / 20%);
        }
        
        .toolTip {
          box-shadow: 0px 0px 8px 8px rgb(0 0 0 / 0);
        }
        
      }
    }
    
    .accordionWrapper & {}
    
    .lmNote {
      min-height: 86px;
      padding: 15px 15px;
      margin-top: 11px;
      font-size: 1.25em;
      height: auto;
      
      
      span {
        opacity: 1;
        display: block;
        width: 100%;
      }
    }
    
    .toolTip {
      display: none;
    }
    
    .icon {
      
      &:before {
        content: 'a';
      }
    }
    
    @media (min-width: $md) {
      @include hover {
        .iconWrap {}
      }
    }
  }
  
  .iconWrap {
    @include absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    color: $light;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    padding-top: 10px;
    transform-origin: center center;
    height: 60px;
    z-index: 1;
    // border: 5px solid;
    // border-image-source: linear-gradient(90deg, $monarch 10%, $rocket 49%);
    // border-image-slice: 1;
    transition: .3s all;
    //background: $light;
  }
  
  .icon {
    font-size: 3.5em;
    transform-origin: center center;
    top: 50%;
    left: 50%;
    -webkit-text-stroke: 2px $rocket;
    // text-shadow:
    //   -5px -5px 5px $light,
    //   5px -5px 5px $light,
    //   -5px 5px 5px $light,
    //   5px 5px 5px $light;
    transform: translate(-50%, -50%) scale(1);
    position: absolute;
    transition: .3s all;
    margin-top: -7px;
  }
}

.lmNote {
  @include noHighlight;
  pointer-events: none;
  position: relative;
  color: $light;
  background: $basic;
  padding: 10px 15px;
  transition: .3s all;
  line-height: 1.2em;
  opacity: 1;
  height: 0;
  overflow: hidden;
  padding: 3px 15px;
  margin-top: 12px;
  background: $horizGradient;
  color: $light;
  text-align: center;
  z-index: 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  
  span {
    opacity: 0;
    padding: 20px 10px;
    background: rgba($basic, .35);
    transition: .5s all;
  }
}

.slide.learnMore {
  //background: $light;
  
  .learnMoreLinksWrap {
    @include clearfix;
    @include maxWidth;
    padding: 20px;
    text-align: center;
    
    >.personalized:first-child {
      width: 100% !important;
      float: none !important;
    }
  }
  
  .linkGroup {
    font-family: $basicFont;
    padding: 25px 0 30px;
    
    &.default {
      margin: 0 auto;
      width: 40%;
      float: left;
      
      .learnMoreLinks {}
    }
    
    &.personalized {
      display: block;
      width: 55%;
      float: right;
      background: lighten($grey, 15%);
      
      .learnMoreLinks {
        margin: 0 auto;
      }
      
      li {
        a {
          background: $basic;
          color: $light;
          
          &:before {
            color: $light;
          }
        }
      }
    }
    
    h4 {
      text-align: center;
      font-size: 1.25em;
      text-transform: uppercase;
      font-weight: 600;
      margin-top: 25px;
      margin-bottom: 15px;
    }
    
    h3 {
      color: $basic;
      font-size: 1.65em;
      font-family: $primaryFont;
      margin-bottom: 15px;
      margin-top: 0;
      font-weight: 400;
      
      &:before {
        display: none;
      }
    }
  }
  
  .learnMoreLinks {
    list-style-type: none;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 575px;
    border-bottom: 0 !important;
    text-align: left;
    
    li {
      display: block;
      font-weight: 400;
      padding-left: 0 !important;
      margin: 5px 0;
      
      &::before {
        display: none !important;
      }
      
      a {
        display: block;
        padding: 10px 25px 10px 25px;
        text-decoration: none;
        font-size: 1.3em;
        color: $basic;
        position: relative;
        text-decoration: none !important;
        outline: 0;
        background: $light;
        border: 2px solid $dark;
        border-radius: 0 6px 6px 6px;
        text-align: center;
        transition: .3s all;
        font-weight: 600;
        
        span {
          font-size: 0.85em;
          text-transform: uppercase;
          display: none;
        }
        
        @include hover {
          background: $primary;
          color: $basic;
          border-color: $basic;
          border-radius: 6px 6px 6px 0;
          
          &:before {
            color: $basic;
          }
        }
        
        &:before {
          @include iconFont;
          content: "d";
          display: inline-block;
          margin-right: 10px;
          font-size: 0.9em;
          color: $basic;
        }
        
        &[href*='.pdf'] {
          &::before {
            content: "p";
            font-size: 1em;
          }
        }
      }
    }
  }
}

@media (max-width: $md) {
  .learnMoreWrapper {
    margin: 40px 0 25px;
  }
  
  .learnMore {
    .learnMoreLinks {
      li {
        padding-left: 0;
      }
    }
    
    .linkGroup {
      p {
        padding: 5px;
      }
    }
  }
  
  .slide.learnMore {
    .linkGroup {
      &.default {
        width: 100%;
        float: none;
      }
      
      &.personalized {
        display: block;
        width: 100%;
        float: none;
      }
    }
  }
}

@media (max-width: $xs) {
  .learnMoreWrapper {
    position: relative;
    
    &:before {
      content: '';
      position: absolute;
      top: -50px;
      left: 0;
      width: 100%;
      height: calc(100% + 50px);
    }
    
    margin-top: 90px;
    
    &.selected {
      margin-bottom: 60px;
    }
  }
  
  .slide.learnMore .learnMoreLinks li a {
    font-size: 1.15em;
    
    &::before {
      left: 10px;
    }
  }
  
  .learnMore .linkGroup h3 {
    font-size: 1.25em;
  }
  
  .slide.learnMore {
    .learnMoreLinksWrap {
      padding: 0;
    }
    
    .learnMoreLinks {
      padding: 0;
    }
    
    .linkGroup {
      
      &.default,
      &.personalized {
        width: 100%;
        float: none;
      }
      
      &.personalized {
        padding: 10px;
      }
    }
  }
}
