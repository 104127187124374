/*********FLIP STYLES*****************/
.revealSlide.Flip {
  
  .copyWrap {
    .listHeading {
      color: $light;
      font-size: 1em;
      line-height: 1.35em;
    }
    
    li {
      padding-left: 20px;
      margin-bottom: 2px;
      line-height: 1.3em;
    }
    
    ul {
      margin-top: -5px;
      margin-bottom: 10px;
    }
    
    p {
      line-height: 1.5em;
    }
  }
  
  &.noImg {
    @media (max-width: $lg) {
      padding-right: 25px;
    }
    
    .content {
      display: block;
      max-width: 2500px;
      width: 100%;
    }
    
    .revealContentHolder {
      // padding: 15px;
      // margin-top: -35px;
      // width: 57%;
      // @media (max-width: $lg){
      //   padding: 0;
      //   padding-left: 10px;
      //   width: 59%;
      //   margin-top: 0;
      // }
      // @media (max-width: $sm){
      //   padding-left: 0;
      // }
    }
    
    .revealCopy {
      //  display: flex;
      //  justify-content: space-between;
      //  padding-left: 40px;
      //  @media (max-width: $lg){
      //    padding-left: 20px;
      //  }
      //  @media (max-width: $sm){
      //    padding-left: 0;
      //  }
      // .slideCopyHolder{
      //   width: 43%;
      //   padding-right: 20px;
      //   @media (max-width: $lg){
      //     width: 41%;
      //   }
      // }
      // @media (max-width: $md){
      //   flex-direction: column;
      //   .slideCopyHolder{
      //     width: 100%;
      //     padding-right: 0;
      //     .smTip{
      //       display: none;
      //     }
      //   }
      //   .revealContentHolder{
      //     width: 100%;
      //     margin-top: 15px;
      //   }
      //   .slideSide{
      //     display: block;
      //     .tips{
      //       display: block;
      //     }
      //   }
      // }
    }
  }
  
  .flipOnly {
    display: block;
  }
  
  .revealContentHolder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin-top: 25px;
    
    &[data-qty="4"] {
      .revealItemHolder {
        margin: 10px 0 0 1%;
        width: calc(98% * (1/2) - 10px - 1px);
        max-width: 350px;
        
        @media (max-width: $xs) {
          height: 200px;
          width: 100%;
          max-width: 450px;
          
          &.picked {
            width: 100%;
            margin: 5px 0;
            max-width: 600px;
            height: auto !important;
            
            .flipper .back {
              position: relative;
              height: auto;
              min-height: 200px;
            }
          }
        }
        
        @media (max-width: $xxs) {
          height: 150px;
          
          &.picked {
            width: 100%;
            margin: 5px 0;
            max-width: 600px;
            height: auto;
          }
        }
      }
    }
    
    &.TitleOnly {
      .revealBtnWrap {
        background: $primary;
      }
      
      .revealBtn {
        
        .titleWrap {
          opacity: 1;
          margin-top: 0;
        }
      }
    }
    
    &.ImageAndTitle {
      .revealBtn .titleWrap {
        opacity: 1;
      }
    }
  }
  
  .revealItemHolder {
    perspective: 1000px;
    transition: opacity .5s, transform .4s, margin .25s;
    margin: 10px .5% 0 .5%;
    width: calc(98% * (1/3) - 10px - 1px);
    flex-grow: 1;
    height: 280px;
    position: relative;
    
    @media (max-width: $sm) {
      height: 315px;
      width: calc(98% * (1/2) - 10px - 1px);
      
      &.picked {
        .flipper .back {
          min-height: 315px;
        }
      }
    }
    
    @media (max-width: $xs) {
      height: 215px;
      width: 100%;
      
      &.picked {
        .flipper .back {
          min-height: 215px;
        }
      }
    }
    
    @include hover {
      .flipper {
        transform: rotateY(7deg);
      }
      
      .titleWrap {
        margin-top: 0;
        opacity: 1;
      }
      
      .topicImage {
        opacity: .85;
      }
    }
    
    &.open {
      height: auto !important;
      
      .flipper .back {
        position: relative;
        height: auto;
      }
    }
    
    &.picked {
      .flipper {
        transform: rotateY(0deg);
      }
      
      .flipper .front {
        transform: rotateY(180deg);
      }
      
      .flipper .back {
        transform: rotateY(0deg);
        min-height: 340px;
      }
      
      @include hover {
        .flipper {
          transform: rotateY(2deg);
        }
      }
    }
  }
  
  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
    @include fillSpace;
  }
  
  .tile {
    @include fillSpace();
    @include absolute;
    backface-visibility: hidden;
  }
  
  .front {
    z-index: 2;
    transform: rotateY(0deg);
    cursor: pointer;
    text-align: center;
    //@include boxShadow();
  }
  
  .back {
    transform: rotateY(180deg);
    color: $basic;
    padding: 0 0 0 33px;
    background: $light;
    border: 3px solid $basic;
    @include transition(.2s);
  }
  
  .revealContent {
    display: none;
  }
  
  .flipBack {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 7px;
    font-size: 1.2em;
    height: 30px;
    top: -6px;
    left: -6px;
    width: 30px;
    border: 3px solid $basic;
    background: $primary;
    text-align: center;
    padding: 1px 1px 0 0;
    transition: .2s all;
    
    &:before {
      content: 'b';
    }
    
    .icon {}
    
    @include hover {
      background: $basic;
      color: $primary;
    }
  }
  
  .topicImageHolder {
    @include absolute;
    @include fillSpace;
    
    .topicImage {
      @include absolute;
      @include fillSpace;
      @include backgroundCover;
    }
    
    img {
      display: none;
    }
  }
  
  .revealBtn {
    .titleWrap {
      display: block !important;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin-top: 0;
      opacity: 0;
      @include transition($speed: .35s);
      text-align: center;
      
      h2 {
        @include zeroMargPad;
        font-size: 1.125em;
        line-height: 1.25em;
        color: $primary;
        text-transform: none;
        font-family: $primaryFont;
        font-weight: 400;
        display: block;
        background: $basic;
        color: $light;
        padding: 5px 10px;
      }
    }
  }
  
  
  .revealContent {
    max-height: 100%;
    overflow: hidden;
    padding: 15px 7px 15px 0;
    font-size: .9em;
    
    .copyWrap {
      padding-right: 10px;
      
      .listHeading {
        font-size: 1em;
        line-height: 1.25em;
      }
    }
    
    .topicTitle {
      @include zeroMargPad();
      margin-bottom: 7px;
      color: $basic;
      font-size: 1.4em;
      font-weight: 600;
    }
    
    .imgHolder {
      max-width: 100%;
      
      img {
        width: 100%;
      }
    }
  }
  
  // &.Circle_Style{
  //   .revealContentHolder{
  //   //  &[data-qty="5"]{.revealItemHolder{width: 275px; height: 275px;}}
  //   }
  //   .revealItemHolder{
  //     width: 275px;
  //     height: 275px;
  //     &.picked{
  //       .flipper .back{
  //         min-height: 275px;
  //       }
  //     }
  //     @media (max-width: $md + 100){
  //       width: 248px;
  //       height: 248px;
  //       &.picked{
  //         .flipper .back{
  //           min-height: 248px;
  //         }
  //       }
  //     }
  //     // @media (max-width: $sm){
  //     //   width: 248px;
  //     //   height: 248px;
  //     // }
  //   }
  //   .tile.front{
  //     @include rounded(50%);
  //     overflow: hidden;
  //   }
  // }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .revealItemHolder {
      &.picked {
        .flipper {
          transform: rotateY(180deg);
        }
        
        @include hover {
          .flipper {
            transform: rotateY(175deg);
          }
        }
        
        .flipper .front {
          transform: rotateY(0);
        }
        
        .flipper .back {
          transform: rotateY(180deg);
        }
      }
    }
  }
}
