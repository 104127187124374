@import '_revealTabs.scss';
@import '_revealFlip.scss';
@import '_revealExpand.scss';
@import '_revealModal.scss';

.revealSlide {
  &.noImg {
    // .slideCopy{
    //     max-width: 1060px !important;
    // }
  }
  
  .content {
    display: flex;
    padding: 0 0 0 60px;
    justify-content: space-between;
    margin: 0 auto;
    
    @media (max-width: $md) {
      flex-direction: column;
      max-width: 960px;
      margin: 0 auto;
    }
    
    @media (max-width: $xs) {
      padding: 0 0;
    }
  }
  
  .slideCopy {
    width: 56%;
    
    @media (max-width: $lg) {
      width: 62%;
    }
    
    @media (max-width: $md) {
      width: 100%;
      padding-bottom: 15px;
    }
  }
  
  .slideSide {
    width: 42%;
    
    @media (max-width: $lg) {
      width: 36%;
    }
    
    @media (max-width: $md) {
      width: 100%;
    }
  }
  
  .revealContentHolder {
    opacity: 1;
  }
  
  .revealContent {
    .imgHolder {
      width: 100%;
      
      img {
        width: 100%;
      }
    }
  }
  
  .topicImage {
    img {
      display: none;
    }
  }
  
  &.noImg {
    .content {
      max-width: 1400px !important;
      display: block;
      padding: 0;
      
      .slideCopy {
        width: 100%;
      }
    }
    
    @media (max-width: $md) {
      padding-right: 10px;
    }
  }
  
  .content {
    position: relative;
  }
  
  .slideCopy {
    // padding-right: 0;
  }
  
  .revealBtn {
    cursor: pointer;
  }
  
  .flipOnly {
    display: none;
  }
  
  .ImageOnly {
    .titleWrap {
      display: none;
    }
  }
  
  .TitleOnly {
    .topicImageHolder {
      display: none;
    }
  }
  
  &.Flip, &.Expand, &.Modal {
    .tabsContentContainer {
      display: none;
    }
  }
  
}
