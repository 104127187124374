//******GLOSSARY*********//

#glossary {
  .alertInnerContent {
    display: block;
    max-width: 1500px;
    padding: 50px 75px;
    text-align: center;
    margin: 0 auto;
  }
  
  dl {
    @include zeroMargPad;
  }
  
  .toggleBtn {
    position: relative;
    padding: 10px 15px 10px 55px;
    display: inline-block;
    border-radius: 40px;
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 600;
    color: $primary;
    background: $basic;
    margin-bottom: 40px;
    cursor: pointer;
    
    .toggle {
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 26px;
      width: 45px;
      border-radius: 90px;
      background: $light;
      
      .inner {
        position: absolute;
        top: 3px;
        right: calc(100% - 24px);
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: $basic;
        transition: right 200ms;
        border: 2px solid $basic;
      }
    }
  }
  
  .toggleBtn.toggleOpen {
    
    .toggle {
      .inner {
        right: 2px;
        background: $primary;
      }
    }
  }
  
  .glossaryList {
    @include reformatList;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-right: 0;
    padding-top: 30px;
  }
  
  .termWrap {
    width: 25%;
    margin-bottom: 25px;
    padding: 0 20px;
  }
  
  .termVar {
    display: none;
  }
  
  .glosDef {
    margin: 0;
    padding: 10px 7px 20px;
    display: none;
    border-bottom: 2px solid $basic;
    background: lighten($grey, 15%);
  }
  
  .glosTerm {
    position: relative;
    font-size: 1.2em;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    color: $basic;
    cursor: pointer;
    transition: .2s all;
    
    &:before {
      content: 'v';
      font-size: 14px;
      font-family: $iconFont;
      position: absolute;
      top: 50%;
      left: -10px;
      z-index: 10;
      transform: translateY(-50%);
      border-radius: 50%;
      background: $light;
      text-align: center;
      transition: color .2s;
      height: 25px;
      width: 25px;
      left: -10px;
      padding-top: 3px;
    }
    
    .termPrim {
      position: relative;
      display: block;
      padding: 10px 20px 10px 20px;
      
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        border: 1px solid $basic;
        transition: all 200ms;
        transition: .2s all;
      }
    }
  }
  
  .glosTerm {
    @include hover {
      color: $basic;
      font-weight: 600;
      
      &:before {
        background: $primary;
        color: text-contrast($primary);
        height: 25px;
        width: 25px;
        left: -10px;
        padding-top: 3px;
      }
      
      .termPrim {
        //font-weight: 600;
        
        &:before {
          //border-color: $primary;
        }
      }
    }
  }
  
  .glosTerm.open {
    color: $basic;
    font-weight: 600;
    
    .termPrim {
      &:before {
        height: 100%;
        border: 2px solid $basic;
      }
    }
    
    &:before {
      background: $primary;
      color: text-contrast($primary);
      height: 25px;
      width: 25px;
      left: -10px;
      padding-top: 3px;
    }
  }
}

@media (max-width: $md + 100) {
  #glossary {
    .termWrap {
      padding: 0 10px;
      width: 33%;
    }
    
    .alertInnerContent {
      //padding-right: 45px !important;
    }
  }
}

@media (max-width: $md) {
  #glossary {
    .termWrap {
      width: 50%;
    }
  }
}

@media (max-width: $sm) {
  #glossary {
    .termWrap {
      width: 100%;
    }
  }
}

@media (max-width:$xs) {
  #glossary {
    .alertInnerContent {
      padding: 25px 20px 0;
    }
    
    .toggleBtn {
      margin-bottom: 0;
    }
  }
}
