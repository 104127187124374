.slide {
  .table-responsive {
    width: auto;
    
    @media all and (max-width: $sm) {
      &:before {
        content: 'If not in view, swipe from right to left to view the entire table.';
        display: block;
        background: lighten($dark, 10%);
        padding: 5px;
        text-align: center;
        font-size: 0.85em;
        color: white;
        line-height: 1.35em;
      }
    }
  }
  
  .infoTable {
    margin: 0 0 30px 0;
    //max-width: 650px !important;
    width: 100% !important;
    line-height: 1.2em;
    
    p {
      @include zeroMargPad;
    }
    
    // @include transition($speed: .3s);
    caption {
      background: $primary;
      color: $light;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.1em;
      padding: 10px 15px;
    }
    
    td {
      border: $light 1px solid;
      padding: 5px;
      text-align: center;
      vertical-align: middle;
    }
    
    &.twoColumn {
      tr {
        td:first-child {
          text-align: right;
          vertical-align: top;
          padding-top: 7px;
          padding-right: 10px;
          //background: lighten($dark,20%);
          background: $grey;
          color: darken($dark, 10%);
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
    
    th {
      border-bottom: 2px solid $basic;
      background: $basic;
      padding: 5px 10px;
      color: $light;
    }
    
    tr:nth-child(odd) {}
    
    tr:nth-child(even) {
      background: lighten($primary, 35%);
    }
    
    &.multiColumn {
      tr:first-child {
        font-weight: 700;
        font-family: $primaryFont;
        line-height: 1.25em;
        
        td {
          background: $grey;
          color: darken($dark, 10%);
          text-transform: uppercase;
          
          p {
            color: darken($dark, 10%);
          }
          
          strong {
            color: darken($dark, 20%);
          }
        }
      }
    }
  }
}
