@keyframes growReveal {
  0%, 100% {
    transform: scale(1)
  }
  
  40% {
    transform: scale(1.2)
  }
  
  50% {
    transform: scale(1.2)
  }
}

@keyframes growRevealLg {
  0%, 100% {
    transform: scale(1)
  }
  
  40% {
    transform: scale(1.3)
  }
  
  50% {
    transform: scale(1.3)
  }
}

.intInstructions {
  margin-bottom: 15px;
  position: relative;
  @include transition(.35s);
  
  &.inactive {
    
    h3 {
      font-size: 1.4em;
    }
  }
  
  .intIcon {
    @include pseudoContent;
    @include absolute;
    left: -5px;
    top: -8px;
    border: 2px solid $light;
    background: $basic;
    height: 70px;
    width: 70px;
    @include rounded(50%);
    padding-top: 5px;
    text-align: center;
    transform: rotate(10deg);
    
    img {
      width: 80%;
      @include vertHorzCenter;
      position: absolute;
    }
  }
  
  h3 {
    color: text-contrast($secondary);
    font-weight: 400;
    text-transform: none;
    font-size: 1.2em;
    padding: 7px 25px 7px 75px;
    margin: 0;
    @include transition(.35s);
  }
}

.matchingSlide {
  .tips {
    display: none;
  }
  
  .thumbImg {
    display: none;
  }
  
  .rspTipHolder {
    display: none;
    
    &.showTip {
      display: none;
    }
  }
  
  .returnContent {
    display: none;
  }
  
  &.activityComplete {
    .introCopyLB {
      display: none;
    }
    
    .tips {
      opacity: 1;
      display: block;
      
      &.smTip {
        display: none;
        opacity: 0;
      }
    }
    
    .matchingGrid {
      width: 44%;
      
      .primary {}
    }
    
    .intInstructions {
      display: none;
    }
    
    .matchingContent {
      width: 55%;
    }
    
    .card {
      width: calc(98% * (1 / 4) - 10px - 1px) !important;
      
      .back {
        background-color: $light !important;
      }
    }
  }
  
  &.activityCompleteReturning {
    .matchingGrid {
      width: 30%;
      
      .returnContent {
        font-size: 1.3em;
        color: $primary;
        font-family: $primaryFont;
        font-weight: 700;
        line-height: 1.5em;
        display: block;
        margin-top: 20px;
      }
      
      .deck {
        display: none;
      }
    }
    
    .matchingContent {
      width: 68%;
      
      .matchingAnswers {
        
        ul {
          display: flex;
          justify-content: space-between;
          flex-flow: wrap;
        }
        
        .matchAnswer {
          width: 48%;
          min-width: 190px;
          display: block;
          transform: scale(1);
          padding: 0;
          font-weight: 600;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 8px;
          border-bottom: 1px solid rgba($primary, .2);
          padding-bottom: 10px;
          
          &:before {
            display: none;
          }
          
          .matchText {
            display: block;
            width: calc(100% - 120px);
            color: text-contrast($secondary);
          }
          
          .text {
            font-weight: 400;
            font-style: italic;
            font-size: .9em;
            line-height: 1.35em;
            margin-bottom: 0;
          }
        }
        
        .thumbImg {
          display: flex;
          align-items: center;
          height: 105px;
          width: 105px;
          overflow: hidden;
          text-align: center;
          margin: 0 auto;
          border: 3px solid $light;
          @include rounded(50%);
          
          &.fillSpace {
            @include backgroundCover;
            
            img {
              display: none;
            }
          }
          
          &.noFill {
            background-image: none !important;
          }
          
          img {
            height: auto;
            width: 90%;
            margin: 0 auto;
          }
        }
      }
    }
  }
  
  
  
  .content {
    max-width: 1450px;
    margin: 0 auto;
    padding: 20px 25px 0 !important;
    
    &.loading {
      .matchingGrid {
        opacity: .3;
      }
      
      .matchingContent {
        opacity: .3;
      }
    }
  }
  
  .matchingHolder {
    display: flex;
    position: relative;
    justify-content: space-between;
  }
  
  .matchingContent {
    width: 40%;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    background: $secondary;
    transition: width .35s;
    
    ul {
      @include zeroMargPad;
    }
    
    .matchAnswer {
      font-weight: 700;
      color: text-contrast($secondary);
      font-size: 1.25em;
      padding: 6px 12px 7px 45px !important;
      display: none;
      margin-bottom: 0;
      transform: scale(0);
      border: 1px solid $light;
      
      &:before {
        color: $light;
      }
      
      &.show {
        transform: scale(1);
        display: block;
      }
      
      &.current {
        @include transparentBG($light, .15);
        animation: growReveal .8s ease-out;
        transform: scale(1);
        
        &:before {
          color: $primary;
        }
      }
      
      .matchText {
        padding: 0 4px;
      }
      
      .matchHeading {
        padding-left: 40px;
        font-size: 1.2em;
        margin-bottom: 2px;
      }
      
      .text {
        font-weight: 400;
        font-style: italic;
        font-size: .85em;
        line-height: 1.35em;
        margin-bottom: 0;
      }
      
      &:before {
        @include iconFont;
        content: 'a';
        font-size: 1.3em;
        left: 11px;
        top: 8px;
        display: inline-block;
        position: absolute;
      }
    }
  }
  
  .matchingGrid {
    width: 58%;
    position: relative;
    transition: opacity .25s, width .35s;
    
    &.loading {
      opacity: 0;
    }
  }
  
  .deck {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    //border: 2px solid $basic;
    background: $basic;
    padding: 5px 15px;
    transition: opacity .25s, transform .5s;
    
    &.loading {
      opacity: 0;
      transform: scale(0);
    }
  }
  
  .card {
    @include useGPU;
    width: 133px;
    height: 133px;
    margin: 8px 0;
    transition: transform 0.2s;
    cursor: pointer;
    @include transition();
    
    .cardTitle {
      position: absolute;
      display: none;
    }
    
    @include hover {
      padding: 2px;
      
      .front {
        box-shadow: 2px 2px 10px 12px rgba(0, 0, 0, 0.2) inset;
      }
    }
    
    &.fillSpace {
      .back {
        overflow: hidden;
        @include backgroundCover;
      }
      
      &.correct {
        .cardImage {
          width: 105%;
        }
      }
      
      .cardImage {
        display: none;
      }
    }
    
    &.noFill {
      .back {
        background-image: none !important;
      }
    }
    
    &.flipped {
      .flipper {
        transform: rotateY(0);
        
        .front {
          transform: rotateY(180deg);
        }
        
        .back {
          transform: rotateY(0deg);
        }
      }
    }
    
    &.wrong {
      transform: scale(.9);
      
      .back {
        background-color: $wrong;
      }
      
      .cardImage {
        opacity: .7;
      }
    }
    
    &.correct {
      .back {
        //background: $horizGradient;
      }
      
      .cardImage {
        width: 60%;
      }
    }
    
    &.clicked {
      .front {
        background-color: lighten($primary, 12%)
      }
    }
    
    .flipper {
      transition: transform 0.35s, background-color .2s;
      transform-style: preserve-3d;
      position: relative;
      @include fillSpace;
    }
    
    .tile {
      @include fillSpace();
      @include absolute;
      backface-visibility: hidden;
    }
    
    .front {
      z-index: 2;
      transform: rotateY(0deg);
      background-image: url('/images/concrete-wall-light.png');
      background-repeat: repeat;
      background-color: $primary;
      color: text-contrast($primary);
      padding: 10px;
    }
    
    .tempText {
      /* You can change this to display block and it will show you which cards to match */
      background: $secondary;
      text-align: center;
      opacity: .6;
      font-size: .85em;
      font-weight: bold;
      position: absolute;
      bottom: 5px;
      left: 5px;
      line-height: 1em;
      height: 20px;
      width: 20px;
      text-transform: uppercase;
      padding-top: 4px;
      display: none;
    }
    
    .back {
      transform: rotateY(180deg);
      background-color: $grey;
    }
    
    .cardImage {
      @include transition();
      width: 55%;
      @include vertCenter;
      margin: 0 auto;
      
      img {
        width: 100%;
      }
    }
  }
  
  .correctContentLB {
    @include fillSpace;
    @include absolute;
    @include transparentBG($light, .75);
    display: none;
    
    .lbHolder {
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
      @include vertCenter;
      background: $primary;
      color: text-contrast($primary);
      font-family: $secondaryFont;
      font-weight: 700;
      padding: 15px;
      
      p {
        @include zeroMargPad;
      }
    }
  }
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .matchingSlide .card {
    &.flipped {
      .flipper {
        transform: rotateY(180deg);
        
        .front {
          transform: rotateY(0);
        }
        
        .back {
          transform: rotateY(180deg);
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
}

@media (max-width: $xl) {
  .matchingSlide {
    .returnContent {
      padding-left: 30px;
    }
    
    .matchContent {
      padding: 20px 15px !important;
    }
    
    &.activityComplete {}
  }
}

@media (max-width: $lg) {
  .matchingSlide {
    &.activityCompleteReturning {
      .matchingGrid {
        .returnContent {
          font-size: 1.2em;
        }
      }
    }
    
    .content {
      padding: 20px !important;
    }
    
    .matchContent {
      padding: 20px 15px;
    }
    
    .intInstructions {
      
      &.inactive {
        h3 {
          font-size: 1.1em;
        }
      }
      
      h3 {
        font-size: 1.1em;
        padding: 5px 10px 5px 65px;
        left: -10px;
      }
      
      .intIcon {
        height: 60px;
        width: 60px;
      }
    }
  }
}

@media (max-width: $md) {
  .matchingSlide {
    padding-right: 30px;
    
    .matchingContent {
      &.activityComplete {
        .matchingGrid {}
        
        .matchingContent {}
      }
      
      .matchAnswer {
        padding: 4px 5px 5px 30px;
        line-height: 1.45em;
        font-size: .9em;
        
        &:before {
          left: 3px;
        }
      }
    }
  }
}


@media (max-width: 900px) {
  .matchingSlide {
    padding-right: 15px;
    
    &.activityComplete {
      .matchingHolder {
        flex-direction: column-reverse;
      }
      
      .matchingGrid {
        width: 100%;
      }
      
      .matchingContent {
        width: 100%;
      }
    }
    
    &.activityCompleteReturning {
      padding-right: 30px;
      
      .matchingHolder {
        flex-direction: column;
      }
      
      .matchingGrid {
        width: 100%;
      }
      
      .matchingContent {
        width: 100%;
      }
    }
    
    .intInstructions {
      .intIcon {
        height: 50px;
        width: 50px;
      }
    }
    
    .matchingHolder {
      flex-direction: column;
      max-width: 700px;
      margin: 0 auto;
    }
    
    .matchingGrid {
      width: 100%;
      margin-bottom: 30px;
    }
    
    .matchingContent {
      width: 100%;
    }
    
    .rspTipHolder {
      @include absolute;
      @include fillSpace;
      z-index: 5;
      @include transparentBG($light, .6);
      display: none;
      
      .closeBtn {
        text-align: center;
        max-width: 150px;
        background: $secondary;
        color: text-contrast($secondary);
        margin: 0 auto;
        padding: 5px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: .9em;
        margin-top: 15px;
      }
      
      &.showTip {
        .rspTip {
          animation: growReveal .8s ease-out;
          transform: scale(1);
          
          p {}
        }
      }
      
      .rspTip {
        max-width: 450px;
        width: 95%;
        margin: 20% auto 0;
        position: relative;
        transform: scale(0);
        background: $primary;
        color: text-contrast($primary);
        padding: 7px 10px 10px;
        font-weight: 600;
        font-size: 1.1em;
        @include transition($speed: .35s);
        @include boxShadow();
        
        h4 {
          font-size: 1.1em;
        }
        
        p {
          margin-bottom: 0;
          line-height: 1.35em;
          text-align: left;
          font-weight: 400;
          font-size: .9em;
        }
      }
    }
  }
}

@media (max-width: $xs) {
  .matchingSlide {
    &.activityCompleteReturning {
      padding-right: 15px;
      
      .matchingHolder {}
      
      .matchingGrid {
        width: 100%;
        
        .returnContent {
          padding-left: 0;
          font-size: 1em;
          line-height: 1.4em;
          margin-top: 0;
        }
      }
      
      .matchingContent {
        padding: 15px 10px;
        
        .matchingAnswers {
          ul {
            display: block;
            
            .matchAnswer {
              width: 100% !important;
            }
          }
        }
      }
    }
    
    .matchingContent {
      ul {
        margin-left: 0;
      }
      
      .matchAnswer {
        .matchHeading {
          padding-left: 0;
        }
      }
    }
    
    .content {
      padding: 0 !important;
    }
    
    .intInstructions {
      h3 {
        padding-left: 10px;
        font-size: .9em;
        line-height: 1.45em;
      }
      
      .intIcon {
        display: none;
      }
    }
    
    .matchingGrid {
      max-width: 575px;
      margin: 0 auto 30px;
    }
    
    .deck {
      padding: 5px 8px;
    }
    
    .card {
      margin: 4px 0;
    }
  }
}

@media (max-width: $xxs) {
  .rspTip {
    font-size: 1em;
    
    p {}
  }
}

@media (max-width: $tiny) {
  .matchingSlide.activityCompleteReturning .matchingContent .matchingAnswers ul .matchAnswer {
    flex-direction: column;
    text-align: center;
    
    .matchText {
      width: 100%;
    }
  }
}
