//***==MULTI QUIZ KEYFRAMES==***//
@keyframes quizCardHover {
    0% {
        transform: rotate(0deg) scale(1,1);
    }

    100% {
        transform: rotate(2deg) scale(1.1,1.1);
        box-shadow: 5px 5px 1px 1px rgba(0,0,0,0.5);
    }
}

@keyframes answeredRight {
    0% {
        transform: rotateY(0deg) scale(.8);
    }

    50% {
        transform: rotateY(180deg) scale(1.6);
        z-index: 5;
    }

    100% {
        transform: rotateY(180deg) scale(1);
    }
}

@keyframes answeredWrong {
    0% {
        font-size: 2em;
        top: 0;
    }

    40% {
        font-size: 5em;
        left: -20px;
        top: -20px;
        color: $wrong;
    }

    100% {
        font-size: 2.2em;
        left: -2px;
        top: -2px;
        color: $wrong;
    }
}

@keyframes animateScoreMsg {
    0% {
        transform: scale(0)
    }

    100% {
        transform: scale(1)
    }
}

@keyframes animateScore {
    0% {
        transform: scale(0)
    }

    50% {
        transform: scale(0)
    }

    100% {
        transform: scale(1)
    }
}
