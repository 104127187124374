@mixin wordSearchMaxW {
  max-width: 900px;
  
  @media(max-width: $md) {
    max-width: 700px;
  }
  
  @media(max-width: $sm + 30px) {
    width: 518px;
  }
  
  @media(max-width: $xs) {
    width: 420px;
  }
  
  @media(max-width: $xxs), (max-height: 400px) {
    width: 362px;
  }
  
  @media(max-width: $tiny), (max-height: 360px) {
    width: 322px;
  }
  
  @media(max-width: 330px), (max-height: 300px) {
    width: 282px;
  }
}

.wordSearchSlide {
  .content {
    &.loading {
      .intInstructions {
        opacity: 0;
        transform: scale(.2);
      }
      
      .puzzleWrap {
        opacity: 0;
        transform: scale(.2);
      }
    }
  }
  
  .tips {
    max-width: 750px;
    margin: 35px auto 15px;
    display: none;
  }
  
  .wordSearchContent {
    transition: opacity .2s;
    opacity: 1;
    
    &.lbOpen {
      opacity: .3;
    }
    
    &.allWordsFound {
      .puzzleWrap {
        transform: scale(0);
      }
    }
  }
  
  .intInstructions {
    padding-bottom: 30px;
    transition: transform .4s, opacity .4s, height .4s;
    
    &.hide {
      transform: scale(.2);
      opacity: 0;
      height: 0;
      overflow: hidden;
      display: block !important
    }
    
    .inner {
      background: $secondary;
      color: text-contrast($secondary);
      display: inline-block;
      padding: 7px 10px;
      @include horzCenter;
      max-width: 850px;
      width: 100%;
      margin: 0 auto;
      padding: 10px 10px 10px 75px;
      font-weight: 600;
      
      p {
        @include zeroMargPad();
      }
    }
    
    .intIcon {
      @include pseudoContent;
      @include absolute;
      font-size: 3em;
      left: -8px;
      top: -15px;
      border: 2px solid $light;
      background: $basic;
      height: 70px;
      width: 70px;
      @include rounded(50%);
      padding-top: 5px;
      text-align: center;
      
      img {
        width: 80%;
        @include vertHorzCenter;
        position: absolute;
      }
    }
  }
  
  .puzzleWrap {
    @include flexJustify();
    align-items: stretch;
    @include wordSearchMaxW;
    margin: 0 auto;
    transition: transform .4s, opacity .4s;
  }
  
  
  .intInstructions {
    @include wordSearchMaxW;
    margin: 0 auto;
  }
  
  /* Styles for the puzzle */
  .puzzleHolder {
    border: 1px solid $secondary;
    padding: 20px;
  }
  
  #puzzle {
    border: 10px solid $light;
    
    /* style for each square in the puzzle */
    .puzzleSquare {
      height: 54px;
      width: 54px;
      text-transform: uppercase;
      background: $light;
      border: 0;
      outline: none;
      font-size: 1.8em;
      font-family: $primaryFont;
      font-weight: 400;
      color: $dark;
      padding: 0 !important;
      display: inline-block;
      -webkit-appearance: none;
      -moz-appearance: none;
      margin: 0 !important;
      
      @include hover {
        color: $basic;
      }
      
      &.selected {
        /* indicates when a square has been selected */
        background: $grey;
        color: text-contrast($grey);
        outline: none;
        
        &.found {
          background: $grey;
          color: text-contrast($grey);
        }
        
        &:focus {
          border: none;
        }
      }
      
      &.found {
        background: $primary;
        color: text-contrast($primary);
      }
      
      &.solved {
        color: $wrong;
      }
    }
  }
  
  
  button::-moz-focus-inner {
    border: none;
    outline: none;
  }
  
  /* Styles for the word list */
  .wordList {
    background: $secondary;
    width: 275px;
    
    h3 {
      font-size: 1.3em;
      font-weight: 700;
      line-height: 1.5em;
      border-bottom: 3px solid text-contrast($secondary);
      padding: 15px 0 10px;
      margin: 0 15px 10px;
      color: text-contrast($secondary);
      text-align: center;
    }
  }
  
  #words {
    padding: 10px 20px 20px;
    
    ul {
      list-style-type: none;
      @include zeroMargPad;
      column-count: 2;
    }
    
    li {
      @include useGPU;
      @include zeroMargPad;
      font-size: 1.1em;
      font-weight: 600;
      font-family: $primaryFont;
      color: text-contrast($secondary);
      padding-bottom: 15px;
      line-height: 1em;
      text-transform: uppercase;
      
      &:before {
        display: none;
      }
      
      /* indicates that the word has been found */
      &.wordFound {
        text-decoration: line-through;
        color: $dark;
      }
    }
  }
  
  /* Styles for the button */
  #solve {
    margin: 30px 30px 0 40px;
    background: transparent;
    color: $wrong;
    padding: 10px 20px;
    border: 2px solid $wrong;
    border-radius: 25px;
    opacity: .5;
    transition: opacity .25s ease-in;
    
    &.gameSolved {
      opacity: 1;
    }
    
    @include hover {
      opacity: 1;
    }
  }
  
  
  .wsAnswer {
    .wsInner {
      display: flex;
    }
    
    .wsTitle {
      @include zeroMargPad;
      color: text-contrast($secondary);
      text-transform: none;
      font-size: 1.5em;
      border-bottom: 3px solid text-contrast($secondary);
      padding-bottom: 7px;
      margin-bottom: 15px;
      padding-top: 15px;
      position: relative;
    }
    
    .wsDescrip {
      font-family: $primaryFont;
      font-weight: 300;
      line-height: 1.55em;
      font-size: .95em;
    }
    
    .wsImg {
      @include backgroundCover;
      @include rounded(50%);
    }
  }
  
  .wsAnswerLB {
    @include fixed;
    @include fillSpace;
    padding: 20px 50px 20px 90px;
    display: none;
    
    .lbInner {
      background: $secondary;
      @include boxShadow();
      max-width: 775px;
      width: 100%;
      @include vertHorzCenter;
      position: fixed;
      padding: 35px 50px 50px 40px;
      margin-top: -150px;
      opacity: 0;
      transition: margin .4s, opacity .3s;
      
      &.show {
        margin-top: 0;
        opacity: 1;
        
        .wsAnswer {
          color: text-contrast($secondary);
          transform: scale(1);
        }
      }
    }
    
    .wsAnswer {
      flex-direction: row;
      transform: scale(0);
      transition: transform .4s;
    }
    
    .wsAnsText {
      padding-left: 200px;
    }
    
    .wsImgWrap {
      position: absolute;
      left: 0;
      top: 0;
    }
    
    .wsImg {
      width: 175px;
      height: 175px;
    }
    
    .wsBtnWrap {
      margin-top: 25px;
      margin-left: 7px;
    }
    
    .wsBtn {
      background: $light;
      color: $secondary;
      font-weight: 600;
      text-transform: uppercase;
      position: relative;
      padding: 6px 20px;
      font-size: 1.15em;
      display: inline-block;
      cursor: pointer;
      
      &:before {
        @include pseudoContent;
        border: 1px solid $light;
        position: absolute;
        height: calc(100% + 14px);
        width: calc(100% + 14px);
        top: -7px;
        left: -7px;
        transition: border .2s;
      }
      
      @include hover {
        &:before {
          border-width: 8px;
        }
      }
    }
  }
  
  .wordSearchAnswers {
    max-width: 1060px;
    margin: 0 auto;
    width: 100%;
    padding-top: 30px;
    
    .inner {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    
    .wsInner {
      display: block;
      transform: scale(0);
      transition: transform .4s;
      
      &.show {
        transform: scale(1);
      }
    }
    
    .wsAnswer {
      width: 0;
      max-height: 0;
      overflow: hidden;
      margin: 0;
      position: relative;
      @include transition();
      
      &.show {
        width: calc(98% * (1/2) - 10px - 1px);
        margin: 10px 0 0 1%;
        overflow: visible;
        max-height: 1000px;
      }
    }
    
    .wsAnsText {
      background: $primary;
      
      @include hover {
        background: $primaryHover;
      }
    }
    
    .wsImgWrap {
      position: absolute;
      left: 10px;
      top: 7px;
      z-index: 1;
    }
    
    .wsImg {
      height: 75px;
      width: 75px;
    }
    
    .wsTitle {
      color: text-contrast($primary);
      font-size: 1.2em;
      min-height: 90px;
      border: 0;
      display: flex;
      align-items: center;
      z-index: 2;
      margin: 0;
      padding: 5px 50px 5px 100px;
      cursor: pointer;
      transition: border-radius .2s;
      
      .ie11 & {
        padding: 30px 50px 30px 100px
      }
      
      &:after {
        @include iconFont;
        content: 'q';
        @include vertCenter;
        position: absolute;
        right: 15px;
        font-size: 1.4em;
      }
    }
    
    .wsDecrip {
      display: none;
      background: $light;
      color: $basic;
      padding: 10px;
      border-top: 3px solid $secondary;
      
      p {
        margin: 0;
      }
    }
    
    .wsBtn {
      display: none;
    }
  }
  
  .completedMsg {
    text-align: center;
    font-size: 1.3em;
    color: $basic;
    
    h2 {
      color: $basic;
      margin-bottom: 25px;
      font-size: 1.3em;
    }
  }
  
  .returnContent {
    display: none;
  }
  
  &.activityComplete {
    .intInstructions {
      display: none !important;
    }
    
    .wordSearchAnswers {
      .tips {
        opacity: 1;
        display: block;
        margin-top: 130px;
      }
    }
  }
  
  &.activityCompleteReturning {
    .intInstructions {
      display: none;
    }
    
    .puzzleWrap {
      display: none;
    }
    
    .returnContent {
      display: block;
      font-size: 1.2em;
      color: $basic;
      font-family: $primaryFont;
      line-height: 1.6em;
      margin-top: 15px;
      width: 30%;
    }
    
    .wordSearchAnswers {
      width: 68%;
      
      .tips {
        width: calc(100% - 20px);
        max-width: 2000px;
      }
    }
    
    .wordSearchContent {
      display: flex;
      max-width: 1450px;
      width: 100%;
      margin: 0 auto;
    }
  }
  
  @media (max-width: $lg) {
    &.activityCompleteReturning {
      &.bgSlide {
        .slideMain {
          padding-right: 25px;
        }
      }
      
      .returnContent {
        width: 41%;
      }
      
      .wordSearchAnswers {
        width: 57%;
        
        .wsAnswer {
          &.show {
            width: 98%;
            margin: 0 auto;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
  
  @media (max-width: $lg - 100) {
    .puzzleHolder {
      padding: 5px;
    }
    
    #puzzle {
      border-width: 6px;
      
      .puzzleSquare {
        font-size: 1.6em;
        height: 48px;
        width: 48px;
      }
    }
  }
  
  @media (max-width: $md + 30) {
    .wordList {
      width: 225px;
      
      h3 {
        font-size: 1.2em;
        padding-bottom: 6px;
      }
    }
    
    #words {
      li {
        font-size: .9em;
      }
    }
    
    .puzzleHolder {
      padding: 10px;
    }
    
    #puzzle {
      border-width: 8px;
      
      .puzzleSquare {
        font-size: 1.3em;
        height: 43px;
        width: 43px;
      }
    }
    
    .slideMain .content {
      padding: 35px 10px !important;
    }
  }
  
  @media (max-width: $sm + 100px) {
    .intInstructions {
      padding-bottom: 20px;
      
      .inner {
        padding-left: 60px;
        
        .intIcon {
          font-size: 2.5em;
          height: 60px;
          width: 60px;
          top: -10px;
          left: -7px;
        }
      }
      
      p {
        font-size: .9em;
        line-height: 1.4em;
      }
    }
    
    .puzzleWrap {
      flex-direction: column-reverse;
    }
    
    .wordList {
      width: 100%;
      display: flex;
      justify-content: center;
      
      h3 {
        font-size: 1.2em;
        padding: 0;
        padding-right: 10px;
        border-bottom: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
      }
    }
    
    .wsAnswerLB {
      .lbInner {
        max-width: calc(100% - 100px);
        transform: translate(0, -50%);
        padding: 25px 40px 40px 40px;
        left: 0;
        right: 0;
        margin: auto;
      }
      
      .wsImg {
        height: 155px;
        width: 155px;
      }
      
      .wsAnsText {
        padding-left: 180px;
      }
    }
    
    .wordSearchAnswers {
      .wsTitle {
        font-size: 1.1em;
        padding-left: 85px;
        padding-right: 40px;
        
        &:after {
          font-size: 1.2em;
          right: 12px;
        }
      }
      
      .wsImgWrap {
        top: 10px;
      }
      
      .wsImg {
        height: 65px;
        width: 65px;
      }
    }
    
    #words {
      padding: 5px;
      display: flex;
      align-items: center;
      
      ul {
        flex: 1 1 0;
        column-count: 3;
      }
      
      li {
        padding: 3px 0;
      }
    }
    
    #puzzle {
      .puzzleSquare {
        font-size: 1.5em;
        height: 48px;
        width: 48px;
      }
    }
    
    &.activityCompleteReturning {
      .wordSearchContent {
        flex-direction: column;
        padding-left: 10px;
      }
      
      .returnContent {
        width: 100%;
      }
      
      .wordSearchAnswers {
        width: 100%;
      }
    }
  }
  
  @media(max-width: $xs), (max-height: 400px) {
    .slideMain {
      padding-left: 0;
      padding-right: 0;
      
      .slideTitleHolder {
        padding: 0 10px;
      }
    }
    
    .puzzleHolder {
      padding: 8px;
    }
    
    #puzzle {
      border-width: 6px;
      
      .puzzleSquare {
        font-size: 1.2em;
        height: 39px;
        width: 39px;
      }
    }
    
    .wsAnswerLB {
      .lbInner {
        max-width: 100%;
        width: 94%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 10px 25px 40px 25px;
      }
      
      .wsImg {
        display: none;
      }
      
      .wsAnsText {
        padding-left: 0;
      }
      
      .wsBtn {
        padding: 3px 15px;
      }
    }
    
    .wsAnswer {
      .wsTitle {
        font-size: 1.2em;
        margin-top: 0;
      }
      
      .wsDecrip {
        font-size: .9em;
        
        p {
          line-height: 1.35em;
        }
      }
    }
    
    .completedMsg {
      font-size: 1.2em;
      padding: 0 7px;
    }
    
    .allWordsFound {
      .wordSearchAnswers {
        padding-top: 15px;
      }
    }
    
    .wordSearchAnswers {
      width: calc(100% - 15px);
      margin-left: 5px;
      
      .wsTitle {
        min-height: 75px;
      }
      
      .wsAnswer {
        margin-left: 0;
        
        &.show {
          width: 98%;
        }
      }
      
      .wsImgWrap {
        top: 7px;
      }
      
      .wsImg {
        height: 60px;
        width: 60px;
      }
    }
    
    &.activityCompleteReturning {
      .returnContent {
        font-size: 1.1em;
      }
    }
  }
  
  @media(max-width: $xxs), (max-height: 400px) {
    .intInstructions {
      width: 100% !important;
      
      .inner {
        padding-left: 50px;
        
        .intIcon {
          font-size: 2.1em;
          height: 50px;
          width: 50px;
          top: -8px;
          left: -5px;
        }
      }
      
      p {
        font-size: .8em;
        line-height: 1.4em;
      }
    }
    
    .puzzleHolder {
      padding: 0;
    }
    
    #puzzle {
      border-width: 0;
      
      .puzzleSquare {
        font-size: 1.2em;
        height: 35px;
        width: 36px;
      }
    }
    
    .wordList {
      width: 100%;
      display: block;
      
      h3 {
        font-size: .9em;
        line-height: 1.2em;
        text-align: center;
        width: 100%;
        display: block;
        background: $primary;
        margin: 0;
        color: text-contrast($primary);
      }
    }
    
    #words {
      padding: 5px;
      display: flex;
      align-items: center;
      
      ul {
        flex: 1 1 0;
        column-count: 3;
      }
      
      li {
        padding: 3px 0;
        font-weight: 700;
      }
    }
    
    .completedMsg {
      font-size: 1em;
      
      h2 {
        margin-bottom: 15px;
      }
    }
  }
  
  @media(max-width: $tiny), (max-height: 400px) {
    #puzzle {
      .puzzleSquare {
        font-size: 1.2em;
        font-weight: 700;
        width: 32px;
        height: 32px;
      }
    }
    
    .wordSearchAnswers {
      .wsTitle {
        padding-left: 10px;
        font-size: 1.1em;
      }
      
      .wsAnswer {
        &.show {
          width: 98%;
        }
      }
      
      .wsImgWrap {
        display: none;
      }
    }
  }
  
  @media(max-width: 330px), (max-height: 400px) {
    #puzzle {
      .puzzleSquare {
        font-size: 1.2em;
        font-weight: 700;
        width: 28px;
        height: 32px;
      }
    }
  }
}
