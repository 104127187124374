$moveTime: 15s;

#backgroundImg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  .ie11 & {
    z-index: 0;
  }
  
  .gecko & {
    filter: blur(5px);
  }
  
  &:before {
    content: '';
    @include fillSpace;
    @include fixed;
    width: 50%;
    background: linear-gradient(90deg, $basic 10%, transparent 99%);
    z-index: 1;
    transition: .5s all;
    
    .alertMode & {
      opacity: 0;
    }
  }
  
  &:after {
    content: '';
    @include fillSpace;
    @include fixed;
    top: auto;
    bottom: 0;
    height: 50%;
    background: linear-gradient(0deg, $basic 10%, transparent 99%);
    z-index: 1;
    transition: .5s all;
    
    .alertMode & {
      opacity: 0;
    }
  }
  
  div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    @include backgroundCover;
    background-position: 50% 100%;
    transform: scale(1.05) rotate(0.02deg);
    opacity: 0;
    display: block !important;
    transition: filter .5s;
    
    .alertMode & {
      filter: blur(10px);
    }
    
    &:first-child {
      opacity: 1;
    }
    
    &.scale {
      background-size: 130%;
    }
    
    &.bgImg:first-of-type {
      //display: block !important;
    }
    
    // &.start {
    //   &.current {
    //     &:before {
    //       opacity: 0;
    //       @include transition(1.5s);
    //     }
    //   }
    
    //   &:before {
    //     opacity: .85;
    //   }
    // }
    
    //@media (min-width:$sm + 1) {
    &.zoomIn {
      &.start {
        span {
          transform: scale(1.125, 1.125);
          
          .ie11 & {
            transform: scale(1, 1);
          }
        }
      }
      
      span {
        transform: scale(1, 1);
      }
    }
    
    &.zoomOut {
      &.start {
        span {
          transform: scale(1, 1);
        }
      }
      
      span {
        transform: scale(1.125, 1.125);
        
        .ie11 & {
          transform: scale(1, 1);
        }
      }
    }
    
    &.moveLeft {
      &.start {
        span {
          left: -100px;
        }
      }
      
      span {
        left: 0;
        width: calc(100% + 100px);
      }
    }
    
    &.moveRight {
      &.start {
        span {
          right: -100px;
        }
      }
      
      span {
        left: auto;
        right: 0;
        width: calc(100% + 100px);
      }
    }
    
    &.moveUp {
      &.start {
        span {
          top: -100px;
        }
      }
      
      span {
        top: 0;
        height: calc(100% + 100px);
      }
    }
    
    &.moveDown {
      &.start {
        span {
          bottom: -100px;
        }
      }
      
      span {
        top: auto;
        bottom: 0;
        height: calc(100% + 100px);
      }
    }
    
    span {}
    
    span {
      @include useGPU;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include backgroundCover;
      background-position: 50% 50%;
      //transition: transform 20s, filter 1s;
      transition: all $moveTime linear;
    }
    
    img {
      display: none;
    }
  }
  
  //}
}

@media (max-width:$sm) {
  #backgroundImg:before {
    width: 100%;
    background: rgba($basic, .65);
  }
}
