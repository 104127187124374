.slide {
  
  /***DEFAULT & COPY SLIDE***/
  .slideSide {
    width: 56%;
  }
  
  .slideCopy {
    width: 43%;
  }
  
  /***VIDEO SLIDE***/
  &.videoSlide {
    .slideSide {
      width: 56%;
    }
    
    .slideCopy {
      width: 40%;
    }
  }
  
  /***QUIZ SLIDE***/
  &.quizSlide {
    .slideFlexWrap {
      flex-direction: row;
    }
    
    .slideSide {
      width: 54%;
      //margin-top: 49px;
    }
    
    .slideCopy {
      width: 42%;
    }
  }
  
  /***NO IMG***/
  &.noImg {
    .slideFlexWrap {
      flex-direction: column;
      max-width: 1050px;
      margin: 0 auto;
    }
    
    .slideSide {
      max-width: 800px !important;
      width: 100% !important;
      margin: 0 auto;
      float: none;
    }
    
    .slideCopy {
      max-width: 800px !important;
      width: 100% !important;
      margin: 0 auto;
      float: none;
    }
    
    .tips.smTip {
      display: block !important;
    }
  }
  
  &.imgGallery {
    .slideFlexWrap {
      max-width: 1000px;
      margin: 0 auto;
      flex-direction: column;
    }
  }
  
  &.vidBelow {
    .slideFlexWrap {
      max-width: 1000px;
      margin: 0 auto;
      flex-direction: column;
    }
    
    .slideSide {
      width: 100%;
      margin: 0 auto;
      padding-bottom: 25px;
    }
    
    .slideCopy {
      width: 100%;
      margin: 0 auto !important;
    }
  }
  
  .slideFlexWrap {
    @include flexJustify;
    flex-direction: row-reverse;
    align-items: flex-start;
    /*Comment this out if you want to align vertically centered*/
  }
  
  @media (max-width: $md) {
    .slideFlexWrap {
      flex-direction: column;
      /*If the html needs to switch, use column-reverse*/
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    
    .slideSide {
      width: 100% !important;
      margin-bottom: 20px;
    }
    
    .slideCopy {
      width: 100% !important;
    }
    
    &.copySlide {
      .slideSide {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}
