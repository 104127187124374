.faqMode {}

#faqContentWrap {
  display: none;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  @include fillSpace;
  @include clearfix;
  height: 100%;
  padding-bottom: 80px;
  background: $grey;
  
  .closeFaqBtn {
    background: $primary;
    color: text-contrast($primary);
    width: 55px;
    height: 50px;
    position: absolute;
    top: 7px;
    left: 10px;
    font-size: 1.5em;
    padding: 11px 0 0;
    text-align: center;
    border: 2px solid $light;
    transition: .3s all;
    
    @include hover {
      cursor: pointer;
      color: $primary;
      background: $basic;
    }
  }
}

#faqContent {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  position: fixed;
  padding: 0 0 95px;
  color: $basic;
  top: -120%;
  @include transition($speed: 1s);
  
  &.results {
    .questCol {
      width: 40%;
      height: calc(100% - 60px);
      position: fixed;
      padding-bottom: 100px;
    }
    
    .resultsCol {
      right: 0;
      padding-bottom: 120px;
    }
  }
  
  &.showing {
    top: 0;
  }
  
  &.noFooter {
    .questCol {
      max-height: calc(100% - 65px) !important;
    }
    
    .resultsCol {
      height: calc(100% - 65px) !important;
    }
  }
  
  .questCol {
    text-align: center;
    padding: 50px;
    background: $grey;
    position: absolute;
    width: 100%;
    max-height: calc(100% - 128px);
    overflow-y: auto;
    @include transition(0.5s);
    
    h2 {
      text-align: center;
      margin-bottom: 15px;
    }
    
    .error {
      background: $wrong;
      color: $light;
      padding: 5px 15px;
      display: block;
      max-width: 800px;
      margin: 0 auto 20px auto;
    }
    
    #question {
      display: block;
      padding: 6px 10px;
      width: 100%;
      min-height: 75px;
      max-width: 800px;
      margin: 20px auto 20px;
    }
    
    .btn {
      @include button;
      font-size: 1.2em;
      margin: 0 auto;
    }
  }
  
  .viewAll {
    border-top: 1px solid darken($grey, 20%);
    max-width: 800px;
    padding: 30px 20px 20px;
    margin: 30px auto 0;
  }
  
  .resultsCol {
    width: 60%;
    height: calc(100% - 128px);
    background: $light;
    padding: 50px 50px 0;
    position: fixed;
    right: -60%;
    overflow-y: auto;
    border-radius: 15px 0 0 0;
    @include transition(0.5s);
    
    .message {
      background: $grey;
      color: $basic;
      padding: 10px 15px;
    }
    
    .accordionButtonWrapper {
      margin-top: 0 !important;
    }
    
    .accordionWrapper {
      font-size: 1.165em;
    }
  }
  
  .questNotAnswered {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 70px;
    height: auto;
    background: $primary;
    padding: 10px 20px;
    position: fixed;
    right: 0;
    text-align: center;
    bottom: -200px;
    overflow-y: auto;
    border-top: 2px solid $light;
    @include boxShadow($x: 0, $y: 0, $blur: 2px, $spread: 2px, $color: rgba(0, 0, 0, .2));
    @include transition(0.5s);
    
    &.showing {
      bottom: 0;
    }
    
    h3 {
      color: text-contrast($primary);
    }
    
    .btn {
      @include button($secondary, $secondaryHover);
      margin-left: 10px;
      border: 2px solid $basic;
      transition: .2s all;
      
      @include hover {
        background: $primary;
        color: $basic;
      }
    }
  }
  
  .questNotAnsweredLightBox {
    font-family: $secondaryFont;
    display: none;
    @include fixed;
    @include fillSpace;
    @include transparentBG($basic, .85);
    z-index: 1;
    
    .questNotAnsweredLightBoxWrap {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 400px;
      margin: -200px 0 0 -200px;
      color: $light;
      text-align: center;
      
      h3 {
        font-family: $secondaryFont;
        font-size: 1.6em;
      }
      
      .questionToBeAnswered {
        display: block;
        margin: 15px 0;
        font-size: 1.25em;
      }
      
      .submitQuestion {
        @include button;
        font-size: 1.2em;
      }
      
      .cancel {
        @include button($secondary, $secondaryHover);
        font-size: 1.2em;
        margin-left: 10px;
        
        @include hover {
          background: $primary;
          color: $basic;
        }
      }
    }
  }
  
  .questThanks {
    @include fixed;
    @include fillSpace;
    @include transparentBG($basic, .85);
    z-index: 2;
    
    .questThanksWrap {
      position: absolute;
      top: 30%;
      left: 0;
      max-width: 500px;
      margin: auto;
      color: $light;
      text-align: center;
      width: 100%;
      right: 0;
      padding: 0 10px;
      
      h3 {
        font-family: $secondaryFont;
        font-size: 1.6em;
      }
      
      h4 {
        display: block;
        margin: 15px 0;
        font-size: 1.25em;
      }
    }
    
    .thanksCloseBtn {
      @include button;
      margin-top: 20px;
      font-size: 1.2em;
    }
  }
  
  .titleWrap {
    color: $basic;
    padding: 15px 0;
    text-align: center;
    background: $grey;
  }
  
  h1 {
    text-transform: uppercase;
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 1.7em;
    display: inline-block;
    border-bottom: 4px solid;
    border-image-source: $horizGradient;
    border-image-slice: 1;
    font-size: 2.75em;
    padding: 12px 40px 5px 40px;
    
    .safari & {
      position: relative;
      border: 0;
      
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: $horizGradient;
      }
    }
  }
}

@media (max-width:$sm) {
  #faqContentWrap {
    position: absolute;
  }
  
  #faqContent {
    position: relative;
    
    &.results {
      .resultsCol {
        display: block;
      }
    }
    
    .titleWrap {
      //padding: 15px 0 15px 77px;
      //text-align: left;
    }
    
    .questCol,
    .resultsCol {
      position: relative !important;
      top: auto !important;
      bottom: auto !important;
      left: auto !important;
      right: auto !important;
      width: 100% !important;
      height: auto !important;
      overflow-y: visible;
      padding: 25px 25px 100px 25px !important;
    }
    
    .resultsCol {
      display: none;
    }
    
    .accordionButton {
      padding-right: 20px !important;
    }
    
    .questNotAnsweredLightBox {
      .questNotAnsweredLightBoxWrap {
        top: 50px;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 20px;
      }
    }
  }
}

@media (max-width:$xs) {
  #faqContent {
    h1 {
      font-size: 1.65em;
    }
  }
}
