.carousel-inner {
  overflow: visible !important;
}

.imgGallery {
  .imgWrap {
    .item {
      position: relative;
      display: none;
      //padding-bottom: 50px;
    }
    
    .slideImg {
      @include backgroundCover;
      width: 100%;
      height: auto;
      background: none !important;
      background-color: $light;
      
      img {
        //padding-bottom: 50px;
      }
    }
    
    .caption {
      background: $basic;
      border: 0;
      width: calc(100% + 8px);
      position: absolute;
      top: 100%;
      text-align: left;
      left: -4px;
      display: block;
      color: $light;
      padding: 15px;
      
      .inner {
        max-height: 190px;
        overflow: visible;
        overflow-y: auto;
        text-align: center;
        @include scrollbar();
      }
      
      h2, h3 {
        color: $light;
        border: 0;
        margin-bottom: 10px;
      }
      
      .listHeading {
        color: $light;
      }
      
      ul li:before {
        color: $secondary;
      }
      
      a {
        &:hover, &:focus {
          color: $basic !important;
          background: $primaryHover;
          text-decoration: none !important;
        }
      }
      
      .inner {
        width: calc(100% - 100px);
        margin-left: 50px;
        
        a {
          color: $primary;
          
          @include hover {
            color: $basic;
          }
        }
      }
    }
    
    .carousel-inner>.active,
    .carousel-inner>.next,
    .carousel-inner>.prev {
      display: block;
    }
  }
  
  .copyBG {
    display: none;
  }
  
  .content, .titleWrap {
    max-width: 1050px;
    margin: 0 auto;
  }
  
  .slideSide {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 25px;
    position: static;
  }
  
  .slideCopy {
    width: 100% !important;
    margin: 0 auto 15px !important;
    float: none !important;
    padding-bottom: 0;
  }
  
  ol.carousel-indicators {
    position: relative;
    bottom: 0;
    top: auto;
    background: $basic;
    width: 100%;
    left: 0;
    margin: 0 !important;
    padding: 5px 0 0 0;
    padding-left: 0 !important;
    
    li {
      border-color: $light;
      height: 18px;
      width: 18px;
      margin-bottom: 1px !important;
      border-radius: 0 !important;
      
      &:before {
        display: none;
      }
      
      &.active {
        background: $horizGradient;
        height: 18px;
        width: 18px;
        margin-bottom: 0;
      }
    }
  }
}

.viewCapBtn {
  position: absolute;
  top: -31px;
  right: 0;
  @include transparentBG($primary, .9);
  z-index: 200;
  height: 31px;
  font-family: $primaryFont;
  color: text-contrast($primary);
  display: none;
  
  .txt {
    display: block;
    text-transform: uppercase;
    font-size: .8em;
    font-weight: 700;
    padding: 3px 25px 3px 7px;
    
    &:before {
      @include iconFont;
      text-transform: none;
      font-size: .8em;
      font-weight: normal;
      right: 3px;
      @include vertCenter;
      position: absolute;
      content: 'n';
      padding-bottom: 2px;
    }
  }
  
  .showCap {
    display: none;
    
    &:before {
      font-size: 1.2em;
      right: 7px;
      content: 'q';
    }
  }
  
  @include hover {
    cursor: pointer;
    background: $primaryHover;
    color: text-contrast($primaryHover);
  }
}

.imgGallery {
  .imgWrap {
    .capHidden {
      .caption {
        padding: 5px 15px;
        
        .inner {
          max-height: 40px;
          overflow: hidden;
        }
      }
      
      .viewCapBtn {
        .hideCap {
          display: none;
        }
        
        .showCap {
          display: block;
        }
      }
    }
  }
}

.galleryNav {
  position: relative;
  z-index: 1;
  
  .carousel-control {
    font-size: 1.8em;
    width: 40px;
    height: 47px;
    top: 100%;
    position: absolute;
    opacity: 1;
    color: $basic;
    text-align: center;
    background: $primary;
    border: 0;
    text-decoration: none !important;
    border-radius: 0;
    
    &:focus {
      color: text-contrast($secondaryHover);
      background: $secondaryHover;
      text-decoration: none !important;
      border-radius: 0;
    }
    
    @include hover {
      color: text-contrast($secondary);
      background: $secondary;
      text-decoration: none !important;
      border-radius: 0;
    }
    
    .icon {
      @include vertCenter;
      display: inline-block;
      text-shadow: none;
    }
  }
  
  .left {
    left: 0;
    border-left: 0;
    padding-left: 8px;
    padding-bottom: 2px;
  }
  
  .right {
    left: auto;
    right: 0;
    border-right: 0;
    padding-left: 2px;
    padding-bottom: 2px;
  }
}

@media (max-width: 1215px) {
  .imgGallery {
    .imgWrap {
      max-width: 960px;
    }
  }
}

@media (max-width: $md) {
  .imgGallery {
    .imgWrap {
      max-width: 800px;
      
      .slideImg {
        //height: 500px;
      }
      
      .caption {
        .inner {
          max-height: 150px;
        }
      }
    }
  }
}

@media (max-width: $sm) {
  .imgGallery {
    .imgWrap {
      max-width: 700px;
      
      .caption {
        padding: 5px 15px;
        position: relative;
        left: auto;
        top: auto;
        width: 100%;
        
        .inner {
          font-size: .9em;
          max-height: none;
          width: calc(100% - 53px);
          margin-left: 25px;
        }
      }
      
      .slideImg {
        //height: 400px;
      }
    }
  }
  
  .galleryNav {
    .carousel-control {
      width: 40px;
      //height: 50px;
      
      .icon {
        width: 40px;
      }
    }
  }
}

@media (max-width: $xs) {
  .imgGallery {
    .slideTitle {
      margin-bottom: 0;
    }
    
    .imgWrap {
      .caption {
        .inner {
          //max-height: 150px;
        }
      }
      
      .slideImg {
        //height: 300px;
      }
    }
  }
  
  .galleryNav {
    .carousel-control {
      width: 30px;
      height: 30px;
      
      .left {
        padding-left: 6px;
      }
      
      .icon {
        width: 30px;
      }
      
      // .left{
      //   padding-left: 1px;
      // }
    }
  }
}

@media (max-width: $xxs) {
  .imgGallery {
    .imgWrap {
      .item {}
    }
  }
}
