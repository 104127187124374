#slideImageEnlarged {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include transparentBG($basic, 0.75);
  z-index: 9999;
  display: table;
  padding-left: 10%;
  @include opacity(0, 0);
  cursor: pointer;
  
  @media all and (max-width: $sm) {
    padding-left: 0;
    @include transparentBG($basic, 0.90);
    display: block;
    overflow: auto;
  }
  
  div {
    width: 90%;
    height: 90%;
    margin: 0 auto;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    
    @media all and (max-width: $sm) {
      width: 100%;
      display: block;
    }
  }
  
  img {
    max-width: 100%;
  }
  
  .close {
    position: fixed;
    top: 0;
    right: 0;
    cursor: pointer;
    color: $basic;
    opacity: 1;
    font-size: 2em;
    background: $monarch;
    padding: 12px 15px;
    border: 5px solid $monarch;
    line-height: 1em;
    float: none;
    @include opacity(100, 1);
    @include transition;
    
    @media all and (max-width: $sm) {
      padding: 5px;
      font-size: 1.25em;
    }
    
    @include hover {
      background: $basic;
      color: $light;
    }
  }
}

.slideSide {
  .enlarge {
    cursor: pointer;
    position: relative;
    
    &:before {
      font-family: $iconFont;
      content: 'f';
      position: absolute;
      top: -4px;
      right: -4px;
      padding: 8px 12px;
      
      display: block;
      border: 4px solid $monarch;
      //   border-image-source: $rocket;
      //   border-image-slice: 1;
      @include opacity(100, 1);
      transition: .2s all;
      font-size: 1.5em;
      color: $basic;
      background: $monarch;
      z-index: 1;
      
      @media all and (max-width: $sm) {
        font-size: 1.2em;
        padding: 4px 5px 0;
      }
    }
    
    @include hover {
      &:before {
        color: $light;
        background: $basic;
      }
    }
    
    &:first-of-type {
      img {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}

.ie8 {
  .slideSide {
    .enlarge {
      &:before {
        display: none;
      }
    }
  }
}
