.slide {
  .slideCopy {
    .tips {
      margin-top: 110px;
    }
  }
  
  .tips {
    background: $basic;
    padding: 0;
    color: $basic;
    border: 2px solid $light;
    position: relative;
    margin-top: 120px;
    padding: 0 0 10px 0;
    transition: opacity .6s, transform .75s;
    transform: translateY(25px);
    opacity: 0;
    
    
    .current & {
      transform: translateY(0);
      opacity: 1;
      //transition-delay: 1s;
    }
    
    h3 {
      margin-top: 0 !important;
      background: $primary;
      color: $basic !important;
      padding: 16px 45px 16px 150px !important;
      font-size: 2em !important;
      font-weight: 400;
      border-bottom: 1px solid darken($dark, 30%);
      text-transform: uppercase;
      @include clearfix;
      margin-bottom: 15px;
      display: block !important;
      position: relative;
      
      &:before {
        content: '';
        @include absolute;
        @include fillSpace;
        background: linear-gradient(0deg, #000 -47%, transparent 18%);
      }
    }
    
    .icon {
      height: 174px;
      position: absolute;
      top: -116px;
      left: 0;
      background: url(/images/mascot.png);
      color: #000;
      font-size: 2.2em;
      width: 170px;
      text-align: center;
      background-size: 160px;
      background-repeat: no-repeat;
      background-position: 0px 100%;
      z-index: 1;
      
      &:before {
        display: none;
      }
    }
    
    .tip {
      padding: 0 20px;
      color: $light;
      
      a {
        color: $primary;
        
        @include hover {
          color: $basic;
        }
      }
    }
  }
}

@media (max-width: $lg) {
  .slide {
    .tips {
      .tip {
        font-size: .9em;
        line-height: 1.5em;
      }
    }
  }
}

@media (max-width: $md) {
  .slide {
    .tips {
      
      
      h3 {}
      
      .tip {}
      
      .icon {}
    }
  }
}

@media (max-width: $xs) {
  .slide {
    .tips {
      margin-top: 0 !important;
      
      h3 {
        padding: 16px 15px 16px 15px !important;
      }
      
      .icon {
        display: none;
      }
    }
  }
}
