.slide:not(.checkSlide) {
  h2 {
    font-weight: normal;
    font-family: $primaryFont;
  }
  
  h2.heading {
    font-weight: 400;
    font-size: 1.7em;
    color: $basic;
    margin-bottom: 10px;
    line-height: 1.2em;
  }
  
  h2.subheading {
    font-family: $primaryFont;
    font-weight: 700;
    font-size: 1.35em;
    color: $secondary;
    margin: 5px 0 10px;
    padding-right: 3px;
    display: inline-block;
    
    &:first-child {
      margin-top: 0;
    }
  }
  
  h2.listheading {
    background: lighten($grey, 15%);
    color: $basic;
    font-family: $primaryFont;
    font-size: 1.05em;
    padding: 10px 15px;
    line-height: 1.2em;
    margin-bottom: 10px;
    margin-top: 20px;
    border-bottom: 2px solid $primary;
    font-weight: 700;
    display: block;
    
    &:first-child {
      margin-top: 0;
    }
  }
  
  .largeText {
    display: block;
    color: $basic;
    font-size: 1.3em;
    line-height: 1.5em;
    font-family: $primaryFont;
    position: relative;
    padding-top: 13px;
    margin-bottom: 25px;
    
    &:before {
      @include pseudoContent;
      width: 3px;
      background: $primary;
      height: 0;
      position: absolute;
      left: -20px;
      bottom: -5px;
      transition: height .35s;
      
      .current & {
        height: calc(100% + 5px);
      }
    }
    
    &:after {
      @include pseudoContent;
      width: 0;
      background: $primary;
      height: 3px;
      position: absolute;
      left: -20px;
      top: 0;
      transition: width .5s .35s;
      
      .current & {
        width: calc(100% + 20px);
      }
    }
  }
  
  .primaryBlock {
    background: $primary;
    color: text-contrast($primary);
    padding: 10px 10px;
    border-radius: 4px;
    display: block;
  }
  
  .secondaryBlock {
    background: $secondary;
    color: text-contrast($secondary);
    padding: 10px 10px;
    border-radius: 4px;
    display: block;
  }
  
  .highlight {
    background: $grey;
    color: $basic;
    border: 2px solid darken($primary, 20%);
    padding: 10px 15px;
    display: block;
    background: lighten($primary, 40%);
    
  }
  
  .button {
    
    a {
      text-decoration: none;
      background-color: $primary;
      color: $basic;
      cursor: pointer;
      border: 2px solid $basic;
      border-radius: 6px;
      padding: 5px 10px;
      line-height: 1.2em;
      text-decoration: none;
      transition: .2s all;
      
      @include hover {
        background: $basic;
        color: $primary;
      }
    }
  }
  
  ul.primaryBlock, ul.secondaryBlock {
    margin-left: 0;
    padding-left: 35px;
  }
}

.slide:not(.quizSlide) {
  ul:not(.list):not(.learnMoreLinks) {
    @include ulStyle;
    margin-bottom: 20px;
    
    li {
      padding-left: 22px !important;
      
      &:before {
        text-transform: none !important;
        color: $rocket;
        width: auto;
      }
    }
  }
  
  ol {
    margin-left: 0 !important;
    padding-left: 15px !important;
    list-style-type: none;
    counter-reset: counter;
    
    li {
      counter-increment: counter;
      margin-bottom: 5px;
      padding-left: 20px;
      position: relative;
      
      &:before {
        content: counter(counter)". ";
        color: $rocket;
        font-weight: bold;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
