@mixin clearfix {
  &:after {
    content: " ";
    /* Older browser do not support empty content */
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

/*---TEXT AND FONT---*/
@mixin bodyCopy {
  color: $basic;
  font-family: $basicFont;
}

@mixin headerFont($weight:500, $color: $primary) {
  color: $color;
  font-family: $primaryFont;
  font-weight: $weight;
}

@mixin textShadow ($x: 0px, $y: 1px, $blur: 0px, $color: rgba(0, 0, 0, .75)) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=$blur, Direction=0, Color=$color)";
  /*IE 8*/
  text-shadow: $x $y $blur $color;
  //filter: progid:DXImageTransform.Microsoft.Shadow(Strength=$blur, Direction=135, Color=$color); /*IE 5.5-7*/
}

@mixin iconFont($font: $iconFont) {
  font-family: $font !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: never;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}



@mixin btnStyle($bgColor: $secondary, $color: $light, $hoverbg: darken($secondary, 10%)) {
  background: $bgColor;
  text-decoration: none;
  font-size: .9em;
  font-family: $primaryFont;
  font-weight: 400;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: $color;
  text-transform: uppercase;

  &:hover {
    background: $hoverbg;
    color: $light;
  }
}

/*---BACKGROUND---*/
@mixin backgroundCover {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 50% 50%;

  &.topLeft {
    background-position: 0 0;
  }

  &.topCenter {
    background-position: 50% 0;
  }

  &.topRight {
    background-position: 100% 0;
  }

  &.bottomLeft {
    background-position: 100% 0;
  }

  &.bottomCenter {
    background-position: 100% 50%;
  }

  &.bottomRight {
    background-position: 100% 100%;
  }
}

@mixin linear-gradient($from, $to) {
  /* Old browsers */
  background: $to;
  /* FIREFOX*/
  background: -moz-linear-gradient($from, $to);
  /* Chrome,Safari4+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, $from), color-stop(1, $to));
  /* Chrome10+,Safari5.1+*/
  background: -webkit-linear-gradient($from, $to);
  /* Opera 11.10+ */
  background: -o-linear-gradient($from, $to);
  /* IE10+ */
  background: -ms-linear-gradient($from, $to);
  /* W3C */
  background: linear-gradient($from, $to);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=0);
}

@mixin horGradient($color1, $color2) {
  background: -moz-linear-gradient(90deg, $color1 0, $color2 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, 90deg, color-stop(0, F9423A), color-stop(100%, f36f32));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(90deg, $color1 0, $color2 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(90deg, $color1 0, $color2 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(90deg, $color1 0, $color2 100%);
  /* IE10+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color1', endColorstr='$color2', GradientType='1');
  /* for IE */
  background: linear-gradient(90deg, $color1 0, $color2 100%);
  /* W3C */
}

@mixin transparentBG($color, $opacity) {
  background-color: $color;
  background: rgba($color, $opacity);
}

@mixin fillSpace {
  height: 100%;
  width: 100%;
}


/*---BOX---*/
@mixin rounded($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin boxShadow($x: 0px, $y: 0px, $blur: 2px, $spread: 2px, $color: rgba(0, 0, 0, .2)) {
  -webkit-box-shadow: $x $y $blur $spread $color;
  -moz-box-shadow: $x $y $blur $spread $color;
  box-shadow: $x $y $blur $spread $color;
}

@mixin shadow-inset($x: 0px, $y: 1px, $blur: 3px, $spread: 0px, $color: rgba(0, 0, 0, .2)) {
  -webkit-box-shadow: inset $x $y $blur $spread $color;
  -moz-box-shadow: inset $x $y $blur $spread $color;
  box-shadow: inset $x $y $blur $spread $color;
}

@mixin border($color: $light, $size: 1px) {
  border: $size solid $color;
}



/*---POSITIONING---*/
@mixin absolute {
  position: absolute;
  top: 0;
  left: 0;
}

@mixin fixed {
  position: fixed;
  top: 0;
  left: 0;
}

@mixin resetPos($pos:relative) {
  position: $pos;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}

@mixin zeroMargPad {
  margin: 0;
  padding: 0;
}



/*---CONTENT---*/
@mixin pseudoContent {
  content: " ";
  height: 100%;
  width: 100%;
  display: block;
}


/*---LISTS---*/

@mixin reformatList {
  list-style-type: none;
  @include zeroMargPad;
  @include clearfix;
}


@mixin olStyle {
  margin-top: 5px;
  list-style-type: none;
  counter-reset: li;
  /* Initiate a counter */
  margin-left: 0;
  /* Remove the default left margin */
  padding-left: 0;

  /* Remove the default left padding */
  li {
    position: relative;
    /* Create a positioning context */
    margin-left: 2em;
    /* Give each list item a left margin to make room for the numbers */
    list-style: none;
    /* Disable the normal item numbering */

    &:before {
      content: counter(li);
      /* Use the counter as content */
      counter-increment: li;
      /* Increment the counter by 1 */
      /* Position and style the number */
      position: absolute;
      top: 3px;
      left: -2em;
      @include borderBox;
      width: 1.8em;
      /* Some space between the number and the content in browsers that support
               generated content but not positioning it (Camino 2 is one example) */
      margin-right: 8px;
      padding: 2px;
      color: $light;
      background: $secondary;
      font-weight: bold;
      text-align: center;
      font-size: .9em;
      @include rounded(50%);
    }
  }
}

@mixin ulStyle($content: 'E') {
  list-style-type: none;
  @include zeroMargPad;
  margin-left: 10px;
  margin-bottom: 5px;

  li {
    @include zeroMargPad;
    padding-left: 15px;
    margin-bottom: 7px;
    position: relative;

    &:before {
      @include iconFont;
      @include absolute;
      top: 6px;
      color: $secondary;
      @include pseudoContent;
      content: $content;
      font-size: .75em;
    }
  }
}

/*---BORDER ARROWS---*/
@mixin rightArrow($size: 8px, $sizeTB: $size, $color: $light, $top: auto, $right: auto) {
  width: 0;
  height: 0;
  border-top: $sizeTB solid transparent;
  border-bottom: $sizeTB solid transparent;
  border-left: $size solid $color;
  border-right: 0;
  position: absolute;
  top: $top;
  right: $right;
}

@mixin leftArrow($size: 8px, $sizeTB: $size, $color: $light, $top: auto, $left: auto) {
  width: 0;
  height: 0;
  border-top: $sizeTB solid transparent;
  border-bottom: $sizeTB solid transparent;
  border-left: 0;
  border-right: $size solid $color;
  position: absolute;
  top: $top;
  left: $left;
}

@mixin topArrow($size: 8px, $sizeLR: $size, $color: $light, $top: auto, $left: auto) {
  width: 0;
  height: 0;
  border-left: $sizeLR solid transparent;
  border-right: $sizeLR solid transparent;
  border-bottom: $size solid $color;
  border-top: 0;
  position: absolute;
  top: $top;
  left: $left;
}

@mixin bottomArrow($size: 8px, $sizeLR: $size, $color: $light, $top: auto, $left: auto) {
  width: 0;
  height: 0;
  border-left: $sizeLR solid transparent;
  border-right: $sizeLR solid transparent;
  border-top: $size solid $color;
  border-bottom: 0;
  position: absolute;
  top: $top;
  left: $left;
}




/*---TRANSFORMS---*/
@mixin rotate($deg: -5deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin transform($type, $value) {
  -webkit-transform: $type($value);
  -moz-transform: $type($value);
  -o-transform: $type($value);
  transform: $type($value);
}

/*---FILTERS---*/
@mixin grayscale($value: 1) {
  -ms-filter: grayscale($value);
  -webkit-filter: grayscale($value);
  -moz-filter: grayscale($value);
  -o-filter: grayscale($value);
  filter: grayscale($value);
}

@mixin blur($value) {
  -ms-filter: blur($value);
  -webkit-filter: blur($value);
  -moz-filter: blur($value);
  -o-filter: blur($value);
  filter: blur($value);
}


@mixin brightness($value) {
  -ms-filter: brightness($value);
  -webkit-filter: brightness($value);
  -moz-filter: brightness($value);
  -o-filter: brightness($value);
  filter: brightness($value);
}

@mixin filters($gs: 0, $blur: 0px, $bright: 100%, $hue: 360deg) {
  -ms-filter: grayscale($gs) blur($blur) brightness($bright) hue-rotate($hue);
  -webkit-filter: grayscale($gs) blur($blur) brightness($bright) hue-rotate($hue);
  -moz-filter: grayscale($gs) blur($blur) brightness($bright) hue-rotate($hue);
  -o-filter: grayscale($gs) blur($blur) brightness($bright) hue-rotate($hue);
  filter: grayscale($gs) blur($blur) brightness($bright) hue-rotate($hue);
}

/*---TRANSITIONS---*/
@mixin transition($property: all, $speed: .2s) {
  -webkit-transition: $property $speed ease;
  -moz-transition: $property $speed ease;
  -o-transition: $property $speed ease;
  transition: $property $speed ease;
}




/*---UTILITY---*/
@mixin borderBox {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin contentBox {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

@mixin opacity($oldBrowser, $newBrowser) {
  /* Theoretically for IE 8 & 9 (more valid) */
  /* ...but not required as filter works too */
  /* should come BEFORE filter */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$oldBrowser)";

  /* This works in IE 8 & 9 too */
  /* ... but also 5, 6, 7 */
  //filter: alpha(opacity=$oldBrowser);

  /* Older than Firefox 0.9 */
  -moz-opacity: $newBrowser;

  /* Safari 1.x (pre WebKit!) */
  -khtml-opacity: $newBrowser;

  /* Modern!
    /* Firefox 0.9+, Safari 2?, Chrome any?
    /* Opera 9+, IE 9+ */
  opacity: $newBrowser;
}

@mixin useGPU {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
}


/*---Images---*/
@mixin rspImage {
  display: block;
  max-width: 100%;
  height: auto;
}


/*---Video---*/

@mixin rspVideo($ratio: 75%, $gifPath: url(../images/video-loader.gif)) {
  position: relative;
  padding-bottom: $ratio;
  padding-top: 0;
  height: 0;
  background: $gifPath 50% 50% no-repeat;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@mixin videoBG($bgImg) {
  width: 100%;
  @include absolute;
  overflow: hidden;

  video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    background-image: $bgImg;
    @include backgroundCover
  }
}


/**Forms**/

@mixin placeholder($color: lighten($basic, 60%), $style: italic, $font: $basicFont, $size: 1em) {
  &::-webkit-input-placeholder {
    color: $color;

    font: {
      style: $style;
      family: $font;
      size: $size;
    }
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;

    font: {
      style: $style;
      family: $font;
      size: $size;
    }
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;

    font: {
      style: $style;
      family: $font;
      size: $size;
    }
  }

  &:-ms-input-placeholder {
    color: $color;

    font: {
      style: $style;
      family: $font;
      size: $size;
    }
  }
}

@mixin columns($col: 2) {
  -webkit-column-count: $col;
  /* Chrome, Safari, Opera */
  -moz-column-count: $col;
  /* Firefox */
  column-count: $col;
}

/*Breaks long words and urls*/
@mixin wordBreak {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin vertCenter($percent: 50%, $trans: 50%) {
  position: relative;
  top: $percent;
  transform: translateY(-$trans);
  -webkit-transform: translateY(-$trans);
}

@mixin horzCenter($percent: 50%, $trans: 50%) {
  position: relative;
  left: $percent;
  transform: translateX(-$trans);
  -webkit-transform: translateX(-$trans);
}

@mixin vertHorzCenter {
  @include vertCenter;
  @include horzCenter;
  transform: translate(-50%, -50%);
}

@mixin screenReader() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin legendFix {
  //This fixes for responsive
  display: table;
  /* Enable line-wrapping in IE8+ */
  white-space: normal;
  /* Enable line-wrapping in old versions of some other browsers */
  width: 100%;
}

@mixin scrollbar($width: 5px, $color: #444444, $bgColor:#F5F5F5, $shadow: true) {

  // Nonstandard webkit properties, works on Chrome, Modern Edge, and Safari
  &::-webkit-scrollbar-track {
    @if $shadow {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    background-color: $bgColor;
  }

  &::-webkit-scrollbar {
    width: $width;
    height: $width;
    background-color: $bgColor;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color;
  }

  // The proper, standard way to style scrollbars. Currently only supported
  // by Firefox, with more browsers coming soon(?)
  scrollbar-color: $color $bgColor;
  scrollbar-width: thin;
}



@mixin flexJustify($dir:row, $justify: space-between) {
  //alternate would be space-around
  display: flex;
  justify-content: $justify;
  align-items: center;
  flex-direction: $dir;
}

@mixin flexCenter($dir: row) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $dir;
}

@mixin flexEnd($dir: row) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: $dir;
}

@mixin flexStart($dir: row) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: $dir;
}

@mixin slideImage($vh: 80vh) {
  width: auto;
  height: auto;
  max-height: $vh;
  max-width: 100%;
}

@mixin noHighlight {
  //Makes elements unhighlightable (Don't use on input fields)
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin absoluteFlex($max-num-of-children: 7, $orientation: "vertical", $gutter: 0%) {

  @if type-of($max-num-of-children) !=number {
    @error "Input `$max-num-of-children` must be a number.";
  }

  @if $orientation !="horizontal"and $orientation !="vertical" {
    @error "Input `$max-num-of-children` must be set to either `horizontal` or `vertical`";
  }

  @while $max-num-of-children>1 {
    $size: 100% / $max-num-of-children;
    $gutterSizeOffset: $gutter / $max-num-of-children;

    // We can detect the number of items a parent has purely with CSS. Crazy, huh!
    >*:first-child:nth-last-child(#{$max-num-of-children}),
    >*:first-child:nth-last-child(#{$max-num-of-children})~* {
      @if ($orientation=="horizontal") {
        width: calc(#{$size} - #{$gutter - $gutterSizeOffset});
      }

      @else if ($orientation=="vertical") {
        height: calc(#{$size} - #{$gutter - $gutterSizeOffset});
      }



      // Calculate and set child position.
      @for $i from 1 through $max-num-of-children {
        &:nth-child(#{$i}) {
          @if ($orientation=="horizontal") {
            left: calc(#{$size * $i - $size} + #{$gutter - $gutterSizeOffset * $i});
          }

          @else if ($orientation=="vertical") {
            top: $size * $i - $size;
          }
        }
      }
    }

    $max-num-of-children: $max-num-of-children - 1;
  }

  >* {
    position: absolute;
  }
}

@mixin frostedGlass($color: $light, $opacity: 85%) {
  background: rgba($color, $opacity);

  @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(10px);

    @if ($opacity - 20% > 0) {
      background: rgba($color, $opacity - 20%)
    }

    @else {
      background: none;
    }
  }
}

@mixin hover() {
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }

  .ie11 &:hover {
    @content;
  }
}

/*------------------------
Function that calculates if text needs to be light or dark
depending on the background color passed.

From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
			
Color brightness is determined by the following formula: 
((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
------------------------*/
@function text-contrast($n) {
  $color-brightness: round((red($n) * 299) + (green($n) * 587) + (blue($n) * 114) / 1000);
  $light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000);

  @if abs($color-brightness) < ($light-color/2) {
    @return white;
  }

  @else {
    @return black;
  }
}

/// Accessible backgrounds! Ensure your elements always have proper contrast.
/// @author Ben Richardson
/// @param {string} $bg - The background to use for the element.
/// @param {string} $textDark [dark] - Text color to use when element's background is above a certain threshold in lightness.
/// @param {string} $textLight [white] - Text color to use when element's background is below a certain threshold in lightness.
@mixin bgWithContrast($bg, $textDark: black, $textLight: white) {
  background-color: $bg;

  $color-brightness: round((red($bg) * 299) + (green($bg) * 587) + (blue($bg) * 114) / 1000);
  $light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000);

  @if abs($color-brightness) < ($light-color/2) {
    color: $textLight;
  }

  @else {
    color: $textDark;
  }
}

/// Accessible text! Ensure your elements always have proper contrast.
/// @author Ben Richardson
/// @param {string} $textColor - The text color to use for the element.
/// @param {string} $bgDark [dark] - Background color to use when element's text is above a certain threshold in lightness.
/// @param {string} $bgLight [white] - Background color to use when element's text is below a certain threshold in lightness.
@mixin colorWithContrast($textColor, $bgDark: black, $bgLight: white) {
  color: $textColor;

  $color-brightness: round((red($textColor) * 299) + (green($textColor) * 587) + (blue($textColor) * 114) / 1000);
  $light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000);

  @if abs($color-brightness) < ($light-color/2) {
    background-color: $bgLight;
  }

  @else {
    background-color: $bgDark;
  }
}

.test {
  color: green;
}