//***==LoadBar KEYFRAMES==***//
@keyframes loading {
    0% {
        left: -10%;
    }

    100% {
        left: 90%;
    }
}

@keyframes loadingBar {
    0% {
        width: 0
    }

    20% {
        width: 35%
    }

    40% {
        width: 38%
    }

    50% {
        width: 40%
    }

    100% {
        width: 100%
    }
}

.loadingScreenHolder {
    @include fixed;
    height: 100vh;
    width: 100vw;
    background: $light;
    overflow: hidden;
    transition: left .5s .45s;
    z-index: 9999;
    display: none;

    &.in {
        .innerWrap {
            opacity: 1;
        }

        .loadLogoWrap {
            transform: translateY(0);
        }
    }

    .loading & {
        display: block;
    }

    &.out {
        left: -150vw;
    }

    .innerWrap {
        max-width: 750px;
        width: 100%;
        @include vertHorzCenter;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 25px;
        opacity: 0;
        transition: opacity .35s;
    }

    .loadLogoWrap {
        max-width: 350px;
        width: 100%;
        transform: translateY(-50px);
        transition: transform .5s;

        img {
            width: 100%;
        }
    }

    .loadBar {
        position: relative;
        width: 100%;
        height: 40px;
        background: $secondary;
        margin: 40px 0 30px;
        overflow: hidden;

        .ie11 & {
            margin-top: -120px;
        }

        .loadBarInner {
            @include absolute;
            top: 4%;
            height: 120%;
            background: $primary;
            width: 0;

            &.loading {
                width: 110%;
                animation: loadingBar 3.25s ease-in;
            }
        }
    }

    h5 {
        text-transform: uppercase;
        font-size: 1.3em;
        @include zeroMargPad;
        color: $primary;
        text-align: center;
    }
}

@media (max-width: $xs) {
    .loadingScreenHolder {
        .innerWrap {
            height: calc(100vh - 100px);
        }

        .loadLogoWrap {
            max-width: 225px;
        }

        .loadBar {
            margin: 30px 0 20px;
        }

        &.in {
            .innerWrap {
                width: calc(100vw - 50px);
            }
        }
    }
}
