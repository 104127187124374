.menuArrow {
  width: 50px;
  height: 50px;
  position: fixed;
  text-align: center;
  bottom: 70px;
  z-index: 6;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 2px solid $primary;
  color: $primary;
  transition: .2s background, .2s color, .5s bottom;
  cursor: pointer;
  
  .noCarousel & {
    display: none;
  }
  
  &.hidden {
    display: none;
  }
  
  @include hover {
    background: $primary;
    color: $basic;
  }
  
  .out & {
    bottom: -150px;
  }
  
  .alertMode &, .searchMode & {
    bottom: -150px;
  }
  
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 0;
    padding-left: 12px;
    width: 100%;
    font-size: 2em;
  }
  
  &.prevArrow {
    left: 50px;
    padding-left: 0px;
    
    .icon {}
  }
  
  &.nextArrow {
    justify-content: flex-start;
    right: 50px;
    
    .icon {
      padding-left: 4px;
    }
    
  }
}

@media (min-width:$sm + 1) {
  
  
  #main-nav {
    
    @include reformatList;
    //overflow: hidden;
    width: calc(100vw - 225px);
    position: fixed;
    left: 100px;
    bottom: 20px;
    height: 370px;
    // background: $light;
    transition: bottom .6s;
    
    .noCarousel & {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 100px;
    }
    
    .inSection & {
      opacity: 0;
    }
    
    width: calc(100vw - 280px);
    left: 130px;
    
    
    .contentOut & {
      bottom: -500px;
      top: auto;
    }
    
    $visibleBtns: 5;
    
    .button {
      list-style-type: none;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: (100% * 1) / $visibleBtns;
      position: absolute;
      display: none;
      transition: left .5s, width .3s, padding .25s, transform .3s, opacity .5s;
      height: 100%;
      top: 0px;
      padding-bottom: 50px;
      background-image: none !important;
      padding-left: 45px;
      padding-right: 45px;
      
      .available {
        display: none;
      }
      
      .notAvailable {
        display: block;
        background: $primary;
        color: $secondary;
        margin: 4px auto;
        padding: 5px 10px;
        font-size: 1em;
      }
      
      .viewingModule2 & {
        padding-bottom: 75px;
      }
      
      //overflow: hidden;
      .noCarousel & {
        @include resetPos;
        display: flex;
        padding-bottom: 40px;
      }
      
      &[data-module="Module 1"] {
        .module {
          display: none;
        }
      }
      
      &.pos1 {
        left: 0px;
        @include navFlex;
      }
      
      @for $i from 1 through $visibleBtns {
        $num: $i - 1;
        
        &[data-pos=pos#{$i}] {
          left: 100% * ($num / $visibleBtns);
          @include navFlex;
          opacity: 1;
        }
      }
      
      &[data-pos=offL] {
        left: - (100% * (1 / $visibleBtns));
        @include navFlex;
        opacity: 0 !important;
      }
      
      &[data-pos=offR] {
        left: 100%;
        @include navFlex;
        opacity: 0 !important;
      }
      
      $activeHeight: (100vw * 1) / $visibleBtns;
      
      &.unlocked:not(.completed):not(.active) {
        &[data-image-position=Left] {
          .titleWrap {
            .imgWrap {
              &:before {
                width: calc(100% - 11px) !important;
                height: calc(100% + 10px) !important;
                right: -5px !important;
                left: auto !important;
                bottom: -5px !important;
              }
            }
            
          }
        }
        
        cursor: pointer;

        .titleWrap {
          // &:before {
          //   content: '';
          //   width: calc(100% + 30px);
          //   height: calc(100% + 30px);
          //   position: absolute;
          //   top: -15px;
          //   left: -15px;
          //   border: 5px solid;
          //   border-image-source: $horizGradient;
          //   border-image-slice: 1;
          //   opacity: 1;
          //   transition: .3s all;
          // }
          
          &:after {
            content: 'a';
            color: $primary;
            font-size: 1.25em;
            display: none;
          }
          
          @include hover {
            &:before {
              opacity: 0;
            }
            
            .imgWrap {
              position: absolute;
              bottom: 80px;
              left: 50%;
              transform: translate(-50%, 0) scale(.75);
              
              &:before {
                position: absolute;
                width: calc(100% + 10px);
                height: calc(100% - 8px);
                left: -5px;
                bottom: -5px;
                content: '';
                border: 5px solid;
                border-image-source: $horizGradient;
                border-image-slice: 1;
                z-index: -1;
              }
              
              &:after {
                content: '';
                width: 25px;
                height: 25px;
                transform: rotate(45deg) translate(-50%, 0);
                z-index: -2;
                background: $horizGradient;
                position: absolute;
                bottom: -20px;
                left: 50%;
                
              }
            }
            
            img {
              display: block;
              
            }
            
            .section-title {
              color: $light;
            }
          }
        }
        
        .section-title {
          color: $primary;
          padding: 5px 10px;
          transition: .3s all;
        }
      }
      
      &.active {
        //width: 330px;
        height: calc(((100vw - 265px) / #{$visibleBtns}) - 27px);
        top: auto;
        bottom: 30px;
        
        .noCarousel & {
          width: 330px;
          height: 305px;
        }
        
        &[data-image-position=Left] {
          img {
            width: calc(100% - 20px);
            top: 19px;
            left: -1px;
          }
        }
        
        @include hover {
          .section-title {
            transform: translate(0, 12px);
            
            &:before {
              bottom: 20px;
            }
          }
          
          .gradientBox {
            &.on {
              left: 0;
              // background: linear-gradient(-90deg, #D9272E 10%, #EF7622 94%);
            }
            
            &.off {
              left: 100%;
              //background: linear-gradient(90deg, $monarch 10%, $rocket 49%);
            }
          }
          
          img {
            transform: scale(1.1);
          }
        }
        
        &[data-pos=offL], &[data-pos=offR] {
          @include hover {
            cursor: default;
            
            .titleWrap {
              cursor: default;
            }
          }
        }
        
        .titleWrap {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          padding: 0;
          
          &:after {
            display: none;
          }
        }
        
        .section-title {
          text-transform: uppercase;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          height: 100%;
          width: 100%;
          padding: 0 30px 50px;
          font-size: 1.5vw;
          font-weight: 600;
          transition: .5s all;
          //overflow: hidden;
          
          &:before {
            @include useGPU;
            content: '';
            position: absolute;
            bottom: 22px;
            left: 20px;
            width: calc(100% - 40px);
            height: 50%;
            background: radial-gradient(farthest-side at 50% 75%, #000 0, transparent 100%);
            //background: radial-gradient(100% 98%, circle cover, $basic 0, transparent 100%);
            /* W3C */
            z-index: -1;
            transition: .5s all;
            display: none;
          }
        }
        
        .gradientBoxes {
          @include absolute;
          @include fillSpace;
          overflow: hidden;
        }
        
        .gradientBox {
          display: block;
          @include absolute;
          @include fillSpace;
          z-index: -1;
          background: $horizGradient;
          opacity: .80;
          transition: all .5s;
          
          &.on {
            left: -100%;
            background: linear-gradient(90deg, #D9272E 10%, #EF7622 94%);
          }
        }
        
        img {
          display: block;
          position: absolute;
          width: calc(100% - 40px);
          top: 4px;
          left: 20px;
          z-index: -1;
          transition: transform .5s;
        }
        
        .startBtn {
          font-size: 1.3em;
          color: $primary;
          display: flex;
          right: auto;
          left: 0px;
          
          .startTxt {
            display: inline-block;
          }
          
          .lockTxt, .reviewTxt {
            display: none;
          }
          
          &:before {
            display: block;
          }
        }
        
        .iconWrap {
          padding-bottom: 10px;
        }
        
        .iconInner {
          height: 78px;
          width: 78px;
          background: $secondary;
          margin-right: 20px;
          padding-bottom: 0px;
          
          .icon {
            &.sectionIcon {
              font-size: 3.5em;
            }
          }
          
          @media (min-width: $xxl + 1) {
            height: 88px;
            width: 88px;
            
            .icon {
              &.sectionIcon {
                font-size: 4em;
              }
            }
          }
          
          &:before {
            height: calc(100% + 20px);
            width: calc(100% + 20px);
            display: block;
          }
          
        }
      }
      
      &.completed {
        &[data-image-position=Left] {
          .titleWrap {
            .imgWrap {
              &:before {
                width: calc(100% - 11px) !important;
                height: calc(100% + 10px) !important;
                right: -5px !important;
                left: auto !important;
                bottom: -5px !important;
              }
            }
            
          }
        }
        
        .titleWrap {
          &:before {
            content: '';
            width: calc(100% + 30px);
            height: calc(100% + 30px);
            position: absolute;
            top: -15px;
            left: -15px;
            border: 5px solid;
            border-image-source: $horizGradient;
            border-image-slice: 1;
            opacity: 1;
            transition: .3s all;
          }
          
          &:after {
            content: 'a';
            color: $primary;
            font-size: 1.25em;
            
            .viewingModule2 & {
              display: block;
            }
          }
          
          @include hover {
            color: $light;
            
            .section-title {
              color: $light;
            }
            
            &:before {
              opacity: 0;
            }
            
            .imgWrap {
              position: absolute;
              bottom: 80px;
              left: 50%;
              transform: translate(-50%, 0) scale(.75);
              
              &:before {
                position: absolute;
                width: calc(100% + 10px);
                height: calc(100% - 8px);
                left: -5px;
                bottom: -5px;
                content: '';
                border: 5px solid;
                border-image-source: $horizGradient;
                border-image-slice: 1;
                z-index: -1;
              }
              
              &:after {
                content: '';
                width: 25px;
                height: 25px;
                transform: rotate(45deg) translate(-50%, 0);
                z-index: -2;
                background: $horizGradient;
                position: absolute;
                bottom: -20px;
                left: 50%;
                
              }
            }
            
            img {
              display: block;
              
            }
          }
        }
        
        .section-title {
          color: $primary;
          padding: 5px 10px;
          transition: .3s all;
        }
      }
      
      &.active, &.completed {
        .titleWrap {
          cursor: pointer;
        }
      }
      
      &.allComplete {
        padding-right: 15px;
        
        .section-title {
          text-transform: uppercase;
          font-size: 3em;
          letter-spacing: .015em;
          
          @media (min-width: $xxl + 1) {
            font-size: 3.1em;
          }
        }
        
        .startBtn {
          font-size: 1.15em;
          right: auto;
          left: 0px;
        }
        
        .iconInner {
          //height: 
        }
      }
      
      .gradientBox {
        display: none;
      }
      
      img {
        display: none;
      }
      
      .iconInner {
        background: $alt;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        position: relative;
        margin-right: 10px;
        padding-bottom: 10px;
        
        &:before {
          @include pseudoContent;
          @include vertHorzCenter();
          border-radius: 50%;
          border: 1px solid $primary;
          transition: all .25s;
          display: none;
        }
        
        .icon {
          display: inline-block;
          @include vertHorzCenter;
          position: absolute;
          color: $light;
          font-size: 2em;
          padding-bottom: 3px;
          
          &.sectionIcon {
            display: none;
          }
        }
      }
      
      .iconWrap {
        position: relative;
      }
      
      .completeIcon {
        @include iconFont;
        content: 'a';
        background: $secondary;
        height: 30px;
        width: 30px;
        color: $light;
        border: 3px solid $light;
        position: absolute;
        bottom: -4px;
        right: -2px;
        border-radius: 50%;
        display: none;
        
        &:before {
          @include vertHorzCenter;
          position: absolute;
        }
      }
      
      .titleWrap {
        @include useGPU;
        position: relative;
        text-align: center;
        transition: .3s all;
        padding: 5px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .viewingModule2 & {
          cursor: pointer;
        }
        
        &:after {
          content: 'c';
          font-family: $iconFont;
          color: $light;
          display: block;
          font-size: 1.125em;
          
          .viewingModule2 & {
            display: none;
          }
        }
      }
      
      .sectionNum {
        display: none;
      }
      
      .section-title {
        color: $light;
        font-weight: 400;
        font-size: 1.25em;
        line-height: 1.1em;
        padding-bottom: 5px;
        text-transform: uppercase;
      }
      
      .module {
        display: block;
        text-align: center;
        font-size: 1.25rem;
        color: $primary;
        line-height: 1.45em;
        
        &:empty {
          display: none;
        }
        
        &:before {
          content: '(';
        }
        
        &:after {
          content: ')';
        }
      }
      
      .startBtn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        bottom: -25px;
        right: 0px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1em;
        color: $alt;
        
        .startTxt, .reviewTxt {
          display: none;
        }
        
        &:before {
          @include iconFont;
          color: $accent;
          content: 'z';
          font-size: 1.3em;
          padding-right: 3px;
          transform: translateY(-1px);
          display: none;
        }
        
      }
      
      .abbr-title {
        display: none;
      }
    }
  }
}

.translucentBg {
  position: fixed;
  transition: all 400ms;
  //@include transitionTiming;
  //@include backdropFilter($light, 55%);
  background-color: rgba($light, 0.55);
}

.out {
  #main-nav {
    bottom: -200px;
    //@include transitionTiming;
    transition: bottom 500ms;
  }
  
  .translucentBg {
    left: 0 !important;
    top: $headerHeight !important;
    height: calc(100% - #{$headerHeight}) !important;
    width: 100% !important;
    transition: all 600ms;
    //@include backdropFilter($light, 80%);
    background-color: rgba($light, 0.8);
  }
}

.alertMode {
  #main-nav {
    bottom: -200px;
    //@include transitionTiming;
    transition: bottom 500ms;
  }
  
  .translucentBg {
    left: 0 !important;
    top: $headerHeight !important;
    height: calc(100% - #{$headerHeight}) !important;
    width: 100% !important;
    transition: all 600ms;
    //@include backdropFilter($light, 70%);
    background-color: rgba($light, 0.7);
  }
}

.searchMode {
  #main-nav {
    bottom: -200px;
    //@include transitionTiming;
    transition: bottom 500ms;
  }
  
  .translucentBg {
    left: 0 !important;
    top: 0 !important;
    height: 100vh !important;
    width: 100% !important;
    transition: all 600ms;
    //@include backdropFilter($light, 90%);
    background-color: rgba($light, 0.9);
  }
}

@media (max-width:$xl) {
  #main-nav {
    .button {
      $visibleBtns: 4;
      width: (100% * 1) / $visibleBtns;
      
      @for $i from 1 through $visibleBtns {
        $num: $i - 1;
        
        &[data-pos=pos#{$i}] {
          left: 100% * ($num / $visibleBtns);
          @include navFlex;
        }
      }
      
      &[data-pos=offL] {
        left: - (100% * (1 / $visibleBtns));
        @include navFlex;
      }
      
      &.active {
        height: calc(((100vw - 265px) / #{$visibleBtns}) - 27px);
        
        .section-title {
          font-size: 1.85vw;
        }
      }
    }
  }
}

@media (max-width: $lg) {
  #main-nav {
    .button {
      $visibleBtns: 3;
      width: (100% * 1) / $visibleBtns;
      
      @for $i from 1 through $visibleBtns {
        $num: $i - 1;
        
        &[data-pos=pos#{$i}] {
          left: 100% * ($num / $visibleBtns);
          @include navFlex;
        }
      }
      
      &[data-pos=offL] {
        left: - (100% * (1 / $visibleBtns));
        @include navFlex;
      }
      
      &.active {
        height: calc(((100vw - 265px) / #{$visibleBtns}) - 27px);
        
        .section-title {
          font-size: 1.95vw;
        }
      }
    }
  }
}

@media (max-width: $md) {
  #main-nav {
    bottom: -10px;
    
    .button.unlocked:not(.active) {
      .titleWrap:hover {
        .imgWrap {
          bottom: 60px;
          transform: translate(-50%, 0) scale(0.55);
        }
      }
    }
    
    .button.unlocked:not(.active):not(.completed) {
      .titleWrap:hover {
        .imgWrap {
          bottom: 60px;
          transform: translate(-50%, 0) scale(0.55);
        }
      }
    }
  }
  
  .menuArrow {
    bottom: 55px;
  }
}

@media (max-width:$sm + 100) {
  #main-nav {
    .button {
      $visibleBtns: 2;
      width: (100% * 1) / $visibleBtns;
      
      @for $i from 1 through $visibleBtns {
        $num: $i - 1;
        
        &[data-pos=pos#{$i}] {
          left: 100% * ($num / $visibleBtns);
          @include navFlex;
        }
      }
      
      &[data-pos=offL] {
        left: - (100% * (1 / $visibleBtns));
        @include navFlex;
      }
      
      &.active {
        height: calc(((100vw - 265px) / #{$visibleBtns}) - 27px);
        
        .section-title {
          font-size: 1.95vw;
        }
      }
    }
  }
}

@media (max-width:$sm) {
  .menuArrow {
    display: none;
  }
  
  #main-nav {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include zeroMargPad;
    list-style: none;
    
    .button {
      background: none !important;
      justify-content: center;
      margin: 15px;
      position: relative;
      left: auto !important;
      width: 100% !important;
      max-width: 500px;
      cursor: pointer;
      text-align: center;
      
      &.active {
        height: auto;
        border: 3px solid;
        border-image: $horizGradient;
        border-image-slice: 1;
        padding: 10px;
        background: rgba($basic, .5) !important;
        
        &:before {
          // content: '';
          // @include absolute;
          // @include fillSpace;
          
        }
        
        .titleWrap {}
      }
      
      &.completed {
        flex-direction: column;
        
        &:before {
          content: 'a';
          font-family: $iconFont;
          display: block;
          text-align: center;
          padding-bottom: 0;
          color: $primary;
          font-size: 1.25em;
        }
      }
      
      .module {
        &:empty {
          display: none !important;
        }
      }
      
      .imgWrap {
        display: none;
      }
      
      .titleWrap {
        .section-title {
          color: $light;
          text-align: center;
          font-weight: 400;
          text-transform: uppercase;
          font-size: 1.5em !important;
          
          .module {
            display: block;
            font-size: 1.35rem;
            color: $primary;
            
            
            &:before {
              content: '('
            }
            
            &:after {
              content: ')'
            }
            
          }
        }
      }
      
      .available {
        display: none;
      }
      
      .notAvailable {
        display: inline-block;
        background: $primary;
        color: $secondary;
        margin: 4px auto;
        padding: 5px 10px;
        font-size: 1em;
      }
      
      .sectionNum, .abbr-title {
        display: none;
      }
    }
  }
}
