#searchModeWrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  display: none;
  height: 100%;
  width: 100%;
  background: rgba($basic, 0.85);
  
  .searchMode & {
    display: block;
    
    .searchResultsPanel {
      right: 0;
    }
    
    .searchSlideHolder {
      opacity: 100;
    }
  }
  
  /* Area to hold slide */
  .searchSlideHolder {
    width: 70%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow: auto;
    transition: background .3s;
    
    &:before {
      content: "SLIDE PREVIEW: This is a preview. Some features and styles may not act/look exactly the way they do in the orientation.";
      position: fixed;
      width: 70%;
      background: $basic;
      color: $light;
      bottom: 0;
      left: 0;
      text-align: center;
      padding: 4px;
      z-index: 10;
      font-size: .9em;
      transition: opacity 1.5s;
      opacity: 0;
    }
    
    &.viewingSlide {
      background: $light;
      
      &:before {
        opacity: 1;
      }
    }
    
    .slide {
      height: 100%;
      background: $light;
      display: none;
      min-height: 300px !important;
      padding: 0 0 40px !important;
      
      .introText {
        margin-bottom: 20px;
      }
      
      .learnMoreWrapper {
        display: none !important;
      }
      
      .tips {
        transform: translateY(0);
        opacity: 1;
      }
      
      .imgWrap {
        transform: translateY(0);
        opacity: 1;
      }
      
      .slideTitle {
        font-family: $primaryFont;
        display: block;
        text-shadow: none;
        font-weight: 400;
        margin-bottom: 25px;
        padding-left: 30px;
        background: $secondary;
        color: text-contrast($secondary);
        font-size: 2.5em;
        padding: 20px 30px;
      }
      
      .slideCopy {
        opacity: 1;
        transform: translateY(0);
        padding-left: 20px;
      }
      
      .content {
        padding: 0 30px 65px 30px !important;
        width: 100%;
        //max-width: 100%;
        background: $light;
      }
      
      .pagination {
        display: none;
      }
    }
  }
  
  /* Area that holds search results */
  .searchResultsPanel {
    width: 30%;
    position: absolute;
    right: -30%;
    top: 0;
    background: $light;
    height: 100%;
    overflow: auto;
    @include boxShadow();
    animation: searchResultsPanelIn 300ms;
    
    @keyframes searchResultsPanelIn {
      from {
        right: -30%;
      }
      
      to {
        right: 0;
      }
    }
  }
  
  .searchBtn {
    position: fixed;
    top: 25px;
    right: 30%;
    z-index: 20;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    font-size: 2.2em;
    text-align: center;
    cursor: pointer;
    @include transition();
    position: fixed;
    z-index: 250;
    top: 3px;
    background: $primary;
    color: text-contrast($primary);
    right: calc(30% + 5px);
    border: 2px solid $light;
    transition: .3s all;
    
    .icon {
      color: text-contrast($primary);
    }
    
    @include hover {
      border-color: $primary !important;
      border-image: none;
      background: $basic !important;
      
      .icon {
        color: $primary !important;
      }
    }
  }
}

.ezsearch-summary {
  color: $basic;
  padding: 0 25px;
  font-size: 1.1em;
  margin-bottom: 5px;
  
  strong {
    font-family: $primaryFont;
    font-weight: 700;
  }
  
  p {
    @include zeroMargPad();
  }
}

.ezsearch-result-count {
  color: $basic;
  font-style: italic;
  font-size: 0.85em;
  padding: 0 25px;
  
  p {
    @include zeroMargPad();
  }
  
  a {
    @include hover {
      color: $link;
      text-decoration: underline;
    }
  }
}

.ezsearch-results {
  padding-top: 20px;
}

.ezsearch-result {
  margin-bottom: 0;
  padding: 18px 25px;
  position: relative;
  overflow: hidden;
  min-height: 145px;
  border-bottom: 2px solid $grey;
  transition: .2s all;
  
  .locked {
    
    .preview,
    .slideTitle,
    .topic {
      opacity: 0.5;
    }
    
    position: relative;
    
    @include hover {
      cursor: not-allowed;
      background: transparent;
    }
    
    .lockedMsg {
      display: none;
      @include absolute;
      width: 100%;
      top: 25px;
      background: $secondary;
      color: text-contrast($secondary);
      font-weight: 700;
      padding: 15px;
      text-align: center;
      font-size: 1.1em;
    }
  }
  
  .resultContent {
    position: relative;
    left: 0;
    @include transition($speed: 0.3s);
  }
  
  &.clicked {
    .resultContent {
      left: -90px;
    }
    
    .viewOptions {
      right: 0;
    }
  }
  
  @include hover {
    cursor: pointer;
    background: lighten($grey, 10%);
  }
  
  &.viewing {
    background: $secondary;
    
    a {
      color: text-contrast($secondary);
    }
    
    .preview {
      color: text-contrast($secondary);
    }
    
    .topic {
      background: transparent;
      color: text-contrast($secondary);
    }
  }
  
  .slideTitle {
    font-weight: 700;
    text-transform: uppercase;
    color: $dark;
    font-size: 1.35em;
    padding: 0 0 7px;
    display: block;
    text-shadow: none;

    .Advisor &, .Guest & {
      color: $basic;
      font-weight: normal;
    }
  }
  
  a {
    color: $basic;
    display: inline-block;
    padding: 0;
    text-decoration: none;
    
    @include hover {
      color: $basic;
      background: none;
      text-decoration: none;
      border-radius: 0;
    }
  }
  
  .preview {
    color: $basic;
    @include zeroMargPad();
    font-size: 0.95em;
    margin-bottom: 10px;
  }
  
  .topic {
    font-family: $primaryFont;
    color: $basic;
    font-size: 0.8em;
    text-transform: uppercase;
  }
}

.viewOptions {
  width: 60px;
  height: 100%;
  margin: 10px auto;
  position: absolute;
  @include transition();
  right: -60px;
  top: -10px;
  
  .view {
    text-align: center;
    height: 48.5%;
    background: $secondary;
    padding-top: 20px;
    @include borderBox;
    margin-bottom: 3%;
    
    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
      height: 49%;
    }
    
    @include hover {
      background: $primary;
      cursor: pointer;
      color: $basic;
      
      p {
        font-size: 0.85em;
        color: $basic;
      }
      
      .icon {
        font-size: 0;
        margin-top: -65px;
        color: $basic;
      }
    }
    
    p {
      @include zeroMargPad;
      @include transition();
      font-family: $primaryFont;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0;
      margin-top: -20px;
      color: $light;
    }
    
    .icon {
      @include transition();
      color: $light;
      font-size: 2em;
      line-height: 1em;
    }
  }
}

.ezsearch-pager {
  text-align: center;
  background: $basic;
  padding: 5px;
  color: $light;
  
  p {
    margin: 5px 0;
  }
  
  span {
    font-weight: bold;
  }
  
  a {
    color: $light;
    font-weight: normal;
    
    @include hover {
      text-decoration: underline !important;
    }
  }
}

.ezsearch-no-results {
  background: $secondary;
  color: text-contrast($secondary);
  font-weight: 400;
  font-family: $primaryFont;
  padding: 10px 20px;
  margin: 0 25px;
  text-align: center;
  
  p {
    @include zeroMargPad;
  }
  
  strong {
    text-decoration: underline;
    font-size: 1.1em;
    font-style: italic;
  }
}

.quickViewing {
  #searchModeWrap .searchSlideHolder {
    background: $light;
  }
}

.lockedBtn {
  display: none;
}

.inSection {
  .searchBtn {
    right: -50px;
  }
  
  .alertBarContainer {
    display: none;
  }
}

.searchArea {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  margin: 0 auto 20px;
  padding: 20px 5% 20px 5%;
  background-color: $primary;
}

.ezsearch-form {
  display: flex;
  align-items: stretch;
  
  .searchField {
    position: relative;
    display: block;
    width: 100%;
    min-width: 0;
    margin: 0;
    border: 1px solid $basic;
    padding: 16px 10px 16px;
    border-radius: 0;
    white-space: normal;
    line-height: 1;
    background: $light;
    color: $basic;
    font-size: 1.15em;
    text-align: left;
    transition: border-color 200ms;
    appearance: none;
    
    &:focus {
      border-color: $primary;
    }
  }
  
  .btn {
    @include button;
    padding: 5px 10px;
    background: transparent;
    color: $basic;
    @include iconFont;
    font-size: 1.5em;
    margin-left: 8px;
    border: 0;
    flex: 1 0 auto;
    transition: .3s all;
    
    @include hover {
      background: $basic;
      color: $light;
    }
  }
}

.summaryWrap {
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.resultsBtn {
  background: $secondary;
  color: text-contrast($secondary);
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 3px 10px 3px 35px;
  text-transform: uppercase;
  font-family: $primaryFont;
  font-weight: 700;
  font-size: 0.9em;
  
  .icon {
    margin-right: 5px;
    font-size: 1.5em;
    position: absolute;
    bottom: -5px;
    left: 7px;
  }
}

@media (max-width: $md) {
  #searchModeWrap {
    .searchSlideHolder {
      width: 0;
      
      &.viewingSlide {
        &:before {
          display: none;
        }
      }
    }
    
    .searchResultsPanel {
      width: 100%;
    }
    
    .searchBtn {
      right: auto;
      left: 8px;
      top: 20px;
      height: 55px;
      width: 55px;
      font-size: 1.6em;
      border: 0;
    }
  }
  
  .searchArea {
    padding-left: 70px;
    padding-right: 20px;
  }
  
  .ezsearch-form {
    .searchField {
      height: 55px;
    }
    
    .btn {
      height: 55px;
    }
  }
}

@media (max-width: $xs) {
  .searchArea {
    width: 100%;
  }
}
