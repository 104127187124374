#inner-content {
  @include clearfix;
  margin: 0;
  position: relative;
  display: none;
  height: auto !important;
  width: calc(100vw - 267px);
  
  &.bgOn {
    opacity: 1;
  }
  
  &:before {
    content: '';
    position: fixed;
    width: calc(100vw - 267px);
    height: 100vh;
    background: $light;
    opacity: 1;
  }
  
  .slideHolder {
    @include absolute;
    @include clearfix;
    top: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 1;
    overflow: visible;
    left: auto;
    display: none;
    padding: 0 !important;
    background-color: $light;
    
    &:before {
      content: "";
      background: url(/images/grey-gradient.jpg) repeat-x 0 100%;
      background-position: 0 68px;
      position: fixed;
      bottom: 0;
      left: 0;
      width: calc(100% - 350px);
      height: 590px;
      opacity: 0;
    }
    
    &:before {
      content: "";
      background: linear-gradient(0deg, #000 20%, transparent 99%);
      position: fixed;
      bottom: 0;
      left: 0;
      width: calc(100% - 250px);
      height: 250px;
      opacity: 1;
      z-index: -1;
    }
    
    .fade {
      position: fixed;
      bottom: 0;
      left: 0;
      width: calc(100% - 250px);
      height: 250px;
      background: linear-gradient(180deg, white 10%, transparent 200%);
      opacity: 1;
      z-index: -1;
      //display: none;
    }
    
    .pattern {
      position: fixed;
      bottom: 0;
      left: 0;
      width: calc(100% - 350px);
      height: 50%;
      //background: url(/images/watermark-circles.jpg) no-repeat;
      //background: url(/images/watermark-watercolor.jpg) no-repeat ;
      //background: url(/images/watermark-wave-grey.png) no-repeat;
      background: url(/images/tiny-squares.png);
      opacity: .20;
      z-index: -1;
      display: none;
    }
    
    // .fade {
    //   position: fixed;
    //   bottom: 0;
    //   left: 0;
    //   width: calc(100% - 350px);
    //   height: 30%;
    //   background: linear-gradient(180deg, white 10%, transparent 200%);
    //   opacity: 1;
    //   z-index: -1;
    //   //display: none;
    // }
    
    .slideTitle {
      @include headerFont($weight: 600);
      color: $basic;
      padding: 10px 25px 0 0;
      margin-left: 30px;
      font-size: 2.85em;
      display: block;
    }
    
    .pagination {
      display: block;
      background: $light;
      margin: 0 0 20px 30px;
      border-bottom: 4px solid $primary;
      font-size: 1.25em;
      padding-bottom: 5px;
      
    }
  }
}

.slide {
  height: auto !important;
  margin: 0 auto;
  min-height: 100%;
  padding: 20px 75px 20px 75px !important;
  
  &.checkSlide, &.learnMore {
    background-color: $light;
  }
  
  &.noImg {
    .slideFlexWrap {
      flex-direction: column;
      max-width: 1050px;
      margin: 0 auto;
    }
    
    .slideCopy {
      max-width: 960px !important;
      width: 100% !important;
      margin: 0 auto;
      float: none;
    }
    
    .introText {
      text-align: center;
      max-width: 800px;
      margin: 0 auto;
    }
    
    .slideSide {
      max-width: 960px !important;
      width: 100% !important;
      margin: 0 auto;
      float: none;
    }
    
    .imgWrap {
      display: none;
    }
    
    .tips.smTip {
      display: block !important;
    }
  }
  
  .textCopy {
    font-size: 1.125em;
    line-height: 1.5em;
  }
  
  .slideCopy {
    transform: translateY(-25px);
    opacity: 0;
    transition: opacity .6s, transform .75s;
    
    .current & {
      transform: translateY(0);
      opacity: 1;
      //transition-delay: .5s;
    }
  }
  
  .introText {
    padding: 20px 25px;
    margin-left: 30px;
    margin-top: 0;
    font-size: 1.4em;
    line-height: 1.35em;
    font-family: $primaryFont;
    background: lighten($grey, 15%);
    position: relative;
    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: -10px;
    //   left: -35px;
    //   height: calc(100% + 100px);
    //   width: 8px;
    //   background: $primary;
    // }
    
    p:last-child {
      margin-bottom: 0;
    }
  }
  
  .content {
    padding: 25px 0 130px 0 !important;
    font-size: 1.1em;
    line-height: 1.6em;
    color: $basic;
  }
  
  
  .slideSide {
    
    img {
      @include rspImage;
      
      &.transparent {
        background: transparent !important;
      }
    }
  }
  
  
  .imgRight,
  .imgRight img {
    float: right;
    margin: 0 0 30px 30px !important;
    padding: 10px;
    @include rspImage;
    @include rounded(6px);
    @include transparentBG($color: $light, $opacity:0.75);
    
    @media all and (max-width: $xs) {
      float: none !important;
      margin: 0 auto 10px auto !important;
    }
  }
  
  .imgLeft,
  .imgLeft img {
    float: right;
    margin: 0 30px 30px 0 !important;
    @include rspImage;
    
    @media all and (max-width: $xs) {
      float: none !important;
      margin: 0 auto 10px auto !important;
    }
  }
  
  .imgWrap {
    width: 100%;
    margin: 0 auto;
    @include transition(.5s);
    transition: height .5s, opacity .6s, transform .75s;
    position: relative;
    text-align: center;
    opacity: 0;
    //overflow: hidden;
    transform: translateY(0);
    opacity: 1;
    
    .next &, .previous & {
      //transform: translateY(-75px);
    }
    
    .current & {
      transform: translateY(0);
      opacity: 1;
    }
    
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 70vh;
      display: inline-block;
    }
    
    .imgHolder {
      @include absolute;
      //width: 100%;
      display: none;
      overflow: hidden;
      transition: 20s transform;
      border: 6px solid;
      background: $light;
      border-image-source: $horizGradient;
      border-image-slice: 1;
      
      .ie11 & {
        width: 100%;
      }
      
      &.zoomOut {
        &.current {
          transform: scale(1.1);
        }
        
        transform: scale(1);
      }
      
      &.zoomIn {
        &.current {
          transform: scale(1);
        }
        
        transform: scale(1.1);
      }
      
      &:before {
        // content: '';
        // @include fillSpace;
        // width: 50%;
        // @include absolute;
        // background: linear-gradient(90deg, $basic 10%, transparent 99%);
        // opacity: .5;
      }
      
      &:after {
        // content: '';
        // @include fillSpace;
        // height: 50%;
        // @include absolute;
        // top: auto;
        // bottom: 0;
        // background: linear-gradient(0deg, $basic 10%, transparent 99%);
        // opacity: .65;
      }
      
      &:first-of-type {
        display: block;
      }
      
      &:only-child {
        display: inline-block;
        position: relative;
      }
      
      .caption {
        position: absolute;
        bottom: 0;
        padding: 10px;
        @include transparentBG($basic, .75);
        color: $light;
        display: block;
        width: 100%;
        left: 0;
        right: 0;
        margin: auto;
        max-height: 100px;
        overflow: hidden;
        overflow-y: auto;
        @include scrollbar();
        font-size: 1em;
        
        p {
          font-size: 1em;
          margin-bottom: 0;
        }
      }
    }
    
    h2 {
      font-size: 1.25em;
      margin-top: 10px;
    }
  }
  
  a {
    //padding: 1px 5px;
    font-weight: normal;
    color: $link;
    text-decoration: underline;
    display: inline-block;
    //padding: 0 2px;
    
    @include hover {
      color: text-contrast($primaryHover);
      background: $primaryHover;
      text-decoration: none;
      @include rounded(4px);
    }
    
    &[href*='.pdf'] {
      &:before {
        @include iconFont;
        content: "p";
        font-size: .7em;
        padding-right: 1px;
      }
    }
  }
  
  strong {
    font-weight: bold;
  }
}

//PRINTER FRIENDLY VIEW STYLING
@media print {
  a[href]:after {
    display: block;
    clear: both;
  }
  
  .closeBtn {
    display: none !important;
    content: none !important;
  }
}

@media (max-width: $lg) {
  .slide .imgWrap {
    max-width: 600px;
  }
  
  .slide {
    padding: 20px 25px 20px 25px !important;
    
    .introText {
      margin-left: 10px;
    }
    
    .content {
      padding: 25px 0 130px 10px !important;
    }
  }
  
  #inner-content {
    width: calc(100vw - 217px);
    
    .mobile & {
      @media (min-width:$sm + 101) {
        width: calc(100vw - 257px);
      }
    }
    
    &:before {
      width: calc(100vw - 217px);
      
      .mobile & {
        @media (min-width:$sm + 101) {
          width: calc(100vw - 257px);
        }
      }
    }
    
    .slideHolder {
      &:before {
        display: none;
      }
      
      .fade {
        display: none;
      }
      
      .slideTitle {
        padding: 10px 0 0 0;
        margin-left: 8px;
      }
      
      .pagination {
        margin-left: 10px;
      }
    }
  }
}

@media all and (max-width: $md) {
  #inner-content .slideHolder .slideTitle {
    //padding: 20px 0;
    font-size: 2.5em;
    //line-height: 1.1em;
    //margin-left: 0;
  }
  
  .slide {
    padding-top: 0 !important;
    padding-bottom: 75px !important;
    
    .introText {
      margin-left: 0;
    }
    
    .content {
      //padding: 35px !important;
    }
    
    .imgRight img {
      float: none !important;
      margin: 0 auto 10px auto !important;
    }
    
    .imgLeft,
    .imgLeft img {
      float: none !important;
      margin: 0 auto 10px auto !important;
    }
    
    .imgWrap {
      max-width: none;
      
      .imgHolder {
        border: 3px solid;
        border-image-source: $horizGradient;
        border-image-slice: 1;
      }
    }
    
    .imgWrapper {
      padding-right: 0;
      margin: 5px 0 0;
    }
  }
}

@media(max-width: $sm + 100) {
  #inner-content {
    margin-top: 100px;
    width: 100%;
    
    &:before {
      width: 100%;
    }
  }
  
  #inner-content .textContainer {
    width: 100%;
  }
  
  .content {
    padding: 25px 35px !important;
  }
  
  .slide {
    .videoCopy {
      margin-top: 20px;
    }
    
    .imgWrap {
      width: 100%;
    }
  }
  
  
  .slideText h2 {
    font-size: 2em;
  }
}

@media (max-width: $xs) {
  #inner-content {
    //margin: 5px 0 !important;
    
    .slideHolder .slideTitle {
      font-size: 1.85em;
      text-align: center;
      margin-left: 0;
      line-height: 1.1em;
      margin-bottom: 5px;
    }
    
    .slideHolder .pagination {
      text-align: center;
      margin-left: 0;
      //margin-bottom: 15px;
      line-height: 1.25em;
    }
  }
  
  .content {
    padding: 25px 10px 90px;
  }
  
  .slide {
    width: 100%;
    
    .introText {}
    
    .content {
      padding: 20px 0 0 0 !important;
      @include break;
    }
  }
}
