@-ms-viewport {
  width: auto !important;
}

html {
  /* Shows sidebar at all times */
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  @include zeroMargPad;
  @include bodyCopy;
  background: $light;
}

body:before {
  content: "" !important;
}

#wrapper {
  position: relative;
}

#mainContent {
  position: relative;
  height: auto;
  padding: 0;
}

img {
  border: 0;
}

a {
  //padding: 1px 5px;
  font-weight: normal;
  color: $link;
  text-decoration: underline;
  display: inline-block;
  
  @include hover {
    color: text-contrast($primaryHover);
    background: $primaryHover;
    text-decoration: none;
    @include rounded(4px);
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $primaryFont;
  margin: 0;
}

p {
  margin: 0 0 15px;
  word-wrap: break-word;
  hyphens: none;
}

span.available {
  display: none;
}

.printFrame {
  display: none;
}

.adaLink:not(.adaMenuLink) {
  @include button;
  border: 1px solid $primary;
  position: fixed;
  top: 295px;
  left: 91px;
  z-index: 10;
  @include transition;
  
  .inSection &, .alertMode &, .faqMode &, .searchMode & {
    display: none;
  }
  
  .returning & {
    display: none !important;
  }
  
  .helpTour & {
    z-index: 9999;
  }
  
  .helpMode & {
    z-index: 9999;
    top: auto;
    top: 20px;
    left: 20px;
  }
  
  .loading & {
    z-index: 9999999999999 !important;
  }
  
  @media (max-height:750px) {
    top: 218px;
    left: 51px;
  }
  
  @media (max-height:400px) {
    top: 136px;
    left: 28px;
  }
  
  @media (max-width: $sm + 100) {
    top: 136px;
    left: 28px;

    @media (min-height:751px) {
      top: 160px;
    }
  }
  
  @media (max-width: $sm) {
    .helpMode & {
      bottom: 0;
      top: auto;
      left: 0;
    }
    
    width: 100%;
    top: auto;
    bottom:0;
    left: 0;
    border: 0;
    border-top: 2px solid $primary;
    font-size: 1em;
    text-align: center;
    display: block;
  }
  
  @media all and (max-width: $xs) {
    left: 0 !important;
    width: 100%;
    font-size: 1em;
    padding: 8px 10px;
    text-align: center;
    
    .helpTour & {
      z-index: -1 !important;
    }
  }
}

.noOverflow {
  overflow-y: hidden !important;
}

#hiddenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vw;
  opacity: 0;
  pointer-events: none;
  background: rgba($basic, 0.75);
  transition: opacity 500ms;
  
  .utilityOpen & {
    pointer-events: auto;
    z-index: 12;
    opacity: 1;
  }
  
  .searchMode & {
    pointer-events: auto;
    opacity: 1;
  }
  
  .swipeMode & {
    pointer-events: auto;
    opacity: 1;
    z-index: 12;
  }
}

//*****UNIVERSAL*****//
.container-fluid {
  padding: 0;
}

.row {
  margin: 0 !important;
}
