.selfDirList {
    list-style: none;
    clear: both;
    @include zeroMargPad;
    @include clearfix;
    margin-left: 0 !important;

    .selfDirItem {
        padding: 5px 0;
        margin-left: 0;
        margin-bottom: 0;

        a {
            font-size: 1.35em;
            color: $primary;
            display: inline-block;
            text-decoration: none;
            padding: 5px 20px;
            @include transition;
            padding-left: 40px;
            position: relative;
            background: $primary;
            color: text-contrast($primary) !important;
            @include rounded(5px);

            @include hover {
                background: $primaryHover;
                color: text-contrast($primaryHover)
            }

            &:after {
                @include transition;
                @include iconFont;
                content: 'm';
                float: right;
                position: absolute;
                left: 10px;
                top: 8px;
                font-size: .9em;
            }
        }
    }
}

.selfDirTable {
    display: block;

    tbody {
        display: block;
    }

    tr {
        display: block;
    }

    .selfDirCell {
        display: block;
    }

    .selfDirBtn {
        @include button;
    }

    a {
        display: block;
        color: $light;
    }
}
