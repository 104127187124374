#dialog_layer {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: scroll;
    background: rgba(0, 0, 0, 0.6);
    display: none;
    z-index: 500;

    &.showing {
        display: block;
    }

    [role=alert],
    [role=alertdialog],
    [role=dialog] {
        &.hidden {
            display: none;
        }
    }

    .default_dialog {
        display: block;
        max-width: 500px;
        border-color: $light;
        background-color: $secondary;
        color: $light;
        width: 100%;
        padding: 15px 15px 5px;
        border: 4px $light solid;
        @include horzCenter;
        top: 25%;
        position: absolute;

        h2 {
            max-width: 400px;
            margin: 0 auto 16px;
            padding: 0;
            text-align: center;
            line-height: 1.1em;
            font-size: 1.6em;
        }

        p {
            line-height: 1.4em;
            font-family: $basicFont;
            font-size: 1.2em;
            line-height: 1.4em;
        }

        li {
            font-size: 1.2em;
        }

        button {
            border: 0;
        }

        .dialog_form_actions {
            text-align: right;
            padding: 0 20px 20px;
        }
    }


    .dialog_form_actions {
        button {
            @include adaBtns;
            cursor: pointer;
            margin: 0 5px;
            transition: none;

            &:focus {
                border: 3px solid $light;
                outline: 2px solid $primaryHover;
                background: $primary;
                color: text-contrast($primary);
            }

            @include hover {
                background: $light;
                color: $primary;
            }
        }
    }
}
