$tabImg: 100px;
$tabHolder: $tabImg+50px;

.revealSlide {
    /*********TABS STYLES*****************/
    &.Tabs {
        .revealCopy {
            position: relative;
        }

        &.noImg {
            .revealContent {
                .copyWrap {
                    // width: 60%;
                    max-width: 750px;
                    width: 100%;
                }

                .imgHolder {
                    width: 80%;
                    margin: 0 auto;

                    img {
                        width: 100%;
                    }
                }
            }
        }

        .revealContentWrap {
            display: none;
        }

        .slideCopyHolder {
            position: relative;
        }

        .mainSlideContent {
            @include absolute;
            @include fillSpace;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            &.hide {
                display: none;
            }
        }

        .tabsContentContainer {
            @include fillSpace;
            @include absolute;
            display: none;
            flex-direction: column;
            justify-content: flex-start;

            &.show {
                display: flex;
            }
        }

        .revealContent {
            .topicTitle {
                background: $primary;
                color: $light;
                font-size: 1.65em;
                padding: 12px 10px;
                margin-bottom: 15px;
            }
        }

        .revealBtnWrap {
            &.active {
                .topicImageHolder {
                    background-color: $secondary;
                }
            }
        }

        .revealBtn {
            text-align: center;
            position: relative;

            .topicImageHolder {
                @include rounded(50%);
                overflow: hidden;
                position: relative;
                margin: 0 auto;

                .topicImage {
                    @include absolute;
                    @include fillSpace;
                    @include backgroundCover;
                }

                img {
                    display: none;
                }
            }
            //topic image holder
            .titleWrap {
                h2 {
                    @include zeroMargPad;
                    font-size: 1.1em;
                    line-height: 1.15em;
                    color: $light;
                    text-transform: none;
                    font-family: $primaryFont;
                    font-weight: 700;
                }
            }
            //titlewrap
        }
        //tabs reveal buttons
        .ImageAndTitle {
            .revealItemHolder {
                html:not(.mobile) &, html:not(.android) & {
                    @include hover {
                        .topicImageHolder {
                            background-color: $primaryHover !important;
                        }

                        .topicImage {
                            opacity: .25;
                            filter: grayscale(.5);
                        }

                        .titleWrap {
                            opacity: 1;
                            margin-top: 0;
                        }
                    }
                }
            }

            .titleWrap {
                @include vertHorzCenter;
                position: absolute;
                margin-top: 20px;
                opacity: 0;
                @include transition($speed: .35s);
            }
        }

        .revealContent {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .imgHolder {
                width: 80%;
                margin: 20px auto 0;

                img {
                    width: 100%;
                }
            }
        }


        $tabImg: 125px;
        $tabHolder: $tabImg+50px;

        &.noImg {
            .slideCopy {
                padding-left: $tabHolder;
            }
        }

        .slideCopy {
            // padding-left: $tabImg - 10px;
            padding-left: $tabHolder;
        }

        .content {
            &[data-format="TitleOnly"] {
                padding-left: 0;

                .slideCopy {
                    margin-left: 200px;
                    padding-left: 0;
                }

                .tabsContentContainer.show {
                    border-left: 4px solid $primary;
                    box-shadow: -4px 0 5px rgba(0, 0, 0, 0.4);
                }
            }
        }

        .revealItemHolder {
            margin-bottom: 8px;

            &.clicked {
                background: transparent;
                border-radius: 15px 0 0 15px;

                .topicImageHolder {
                    border-radius: 0;
                    // width: 160px;
                    width: 100%;
                    //margin-left: -5px;
                    box-shadow: none;
                    width: 100%;
                    border-radius: 15px 0 0 15px;
                    margin-left: 0;

                    .topicImage {
                        opacity: .35;
                    }
                }
            }
        }

        .slideCopyHolder {
            padding: 25px;
            height: calc(100vh - 175px);
            overflow: hidden;
            overflow-x: hidden;
            overflow-y: auto;
            @include scrollbar($color: $secondary, $width: 8px);

            .copy {
                padding: 35px 25px 25px;
            }
        }

        .revealContentHolder {
            width: $tabHolder;
            max-height: calc(100vh - 175px);
            //max-height: 300px;
            @include absolute;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            overflow-x: hidden;
            overflow-y: auto;
            @include scrollbar($color: $secondary, $width: 8px);
        }

        .topicImageHolder {
            width: $tabImg;
            height: $tabImg;
            @include boxShadow();
        }

        .ImageOnly {
            .revealBtnWrap {
                padding: 10px 0;
                margin: 2px 0;
            }
        }

        [data-format="ImageAndTitle"] {
            .tabsContentContainer.show {
                border-left: 8px solid $primary;
                //box-shadow: 0 4px 5px 5px rgba(0,0,0,0.4);
            }
        }

        .TitleOnly {
            min-width: 175px;
            left: 25px;
            left: -175px;

            &.selected {
                .revealBtnWrap {
                    border-radius: 10px 0 0 10px;
                    box-shadow: -4px -1px 5px rgba(0, 0, 0, 0.4) inset;
                }
            }

            .revealBtnWrap {
                background: $secondary;
                padding: 22px 15px;
                margin-bottom: 2px;
                border-radius: 10px;

                &.active {
                    background: $primary;

                    h2 {
                        color: $light;
                        font-weight: 700;
                    }

                    box-shadow: none !important;
                }

                .revealBtn {
                    text-align: left;
                }

                h2 {
                    color: $light;
                    font-weight: 400;
                    font-size: 1.3em;
                    text-shadow: none;
                }
            }
        }

        @media (max-width: $lg) {
            $tabImg: 100px;
            $tabHolder: $tabImg + 25px;

            .slideCopy {
                padding-left: 65px;
            }

            .slideCopyHolder {
                .copy {
                    padding: 20px 15px 15px;
                }
            }

            .revealContentHolder {
                width: $tabHolder
            }

            .topicImageHolder {
                width: $tabImg;
                height: $tabImg;
            }
        }

        @media (max-width: $xs) {
            $tabImg: 75px;
            $tabHolder: $tabImg + 10px;

            .slideCopy {
                padding-left: $tabHolder;
            }

            .slideCopyHolder {
                padding: 0;

                .copy {
                    padding: 10px 5px 15px 10px;
                }
            }

            .revealContentHolder {
                width: $tabHolder
            }

            .topicImageHolder {
                width: $tabImg;
                height: $tabImg;
                margin-left: 3px;

                .titleWrap {
                    display: none !important;
                    visibility: hidden;
                }
            }
        }

        @media (max-width: $xxs) {
            $tabImg: 65px;
            $tabHolder: $tabImg + 10px;
            padding-left: 0;

            .slideTitleHolder {
                padding-left: 15px;
            }

            .slideCopy {
                padding-left: $tabHolder;
            }

            .slideCopyHolder {
                padding: 0;

                .copy {
                    padding: 10px 0 15px 10px;
                }
            }

            .revealContentHolder {
                width: $tabHolder
            }

            .topicImageHolder {
                width: $tabImg;
                height: $tabImg;
                margin-left: 3px;

                .titleWrap {
                    display: none !important;
                }
            }
        }
        // &.Show_Below{
        //   $tabImg: 165px;
        //   $tabHolder: $tabImg + 50px;
        //   &.noImg{
        //     .slideCopy{
        //       position: relative;
        //     }
        //   }
        //   .slideCopy{
        //     padding: 0;
        //     padding-bottom: $tabImg;
        //     position: relative;
        //     .slideCopyHolder{
        //     //  @include boxShadow();
        //     }
        //   }
        //   .content{
        //       &[data-format="TitleOnly"]{
        //         .slideCopy{
        //         padding-left: 0;
        //         padding-bottom: 0;
        //       //  box-shadow: -4px -2px 2px rgba(0,0,0,0.3);
        //         //border-left: 5px solid $secondary;
        //       }
        //       .tabsContentContainer.show{
        //         border-bottom: 4px solid $accent;
        //         box-shadow: 0 4px 5px 5px rgba(0,0,0,0.4);
        //       }
        //     }
        //   }
        //   .slideCopyHolder{
        //     padding: 25px;
        //     height: calc(100vh - 400px);
        //     overflow: hidden;
        //     overflow-x: hidden;
        //     overflow-y: auto;
        //     @include scrollbar($color: $accent);
        //     margin-bottom: 20px;
        //   //  @include boxShadow();
        //     .copy{
        //       padding: 30px;
        //     //  border-bottom: 2px solid $accent;
        //     }
        //   }
        //   .mainSlideContent{
        //     justify-content: center;
        //     padding: 50px;
        //     box-shadow: 0 -2px 9px rgba(0,0,0,0.3);
        //     .inner{
        //       max-width: 960px;
        //       width: 100%;
        //       margin: 0 auto;
        //     //  @include vertCenter;
        //     }
        //   }
        //   .revealContentHolder{
        //     max-width: 1150px;
        //     width: 100%;
        //   padding-bottom: 10px;
        //     //height: $tabHolder;
        //     @include horzCenter();
        //     position: absolute;
        //     bottom: 0;
        //     display: flex;
        //     flex-direction: row;
        //     justify-content: center;
        //     overflow-x: hidden;
        //     overflow-x: auto;
        //     @include scrollbar($color: $accent);
        //     z-index: 20;
        //     &.ImageOnly{
        //     }
        //   }
        //   .topicImageHolder{
        //     width: $tabImg;
        //     height: $tabImg;
        //   }
        //   .revealBtnWrap{
        //     padding: 0 10px;
        //     margin: 0 2px;
        //     &.clicked{
        //       position: relative;
        //       top: -15px;
        //     }
        //   }
        //   .ImageAndTitle{
        //     &.revealContentHolder{
        //       border-top: 5px solid $accent;
        //       padding-bottom: 0;
        //     }
        //     .revealItemHolder{
        //       margin-top: 10px;
        //       &.clicked{
        //         margin-top: 0;
        //         background: $accent;
        //         border-radius:0 0 15px 15px;
        //         .revealBtnWrap{
        //           padding: 0;
        //         }
        //         .topicImageHolder{
        //           border-radius:0;
        //           box-shadow: none;
        //           &.isIcon{
        //             img{
        //               width: 80%;
        //             }
        //           }
        //           &.isPhoto{
        //             //width: 100%;
        //             border-radius:0 0 15px 15px;
        //             margin-left: 0;
        //             .topicImage{
        //               opacity: .5;
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        //   .TitleOnly{
        //     width: 100%;
        //     bottom: 3px;
        //     //left: 0;
        //     &.selected{
        //       .revealBtnWrap{
        //         border-radius: 0 0 10px 10px;
        //         box-shadow: 1px 4px 5px rgba(0,0,0,0.4) inset;
        //       }
        //     }
        //     .revealBtnWrap{
        //       background: $secondary;
        //       padding: 10px 15px;
        //       margin-bottom: 2px;
        //       border-radius: 10px;
        //       &.active{
        //         background: $accent;
        //         h2{
        //           color: $primary;
        //           font-weight: 700;
        //         }
        //         box-shadow: none !important;
        //       }
        //       .revealBtn{
        //         text-align: left;
        //       }
        //       h2{
        //         color: $light;
        //         font-weight: 400;
        //         font-size: 1.6em;
        //       }
        //     }
        //   }
        // }//End Tab Show Below
        @media (max-width: $lg) {
            .revealContent {
                .imgHolder {
                    width: 96%;
                }

                .topicTitle {
                    font-size: 1.7em;
                }
            }
        }

        @media (max-width: $md) {
            .revealContent {
                .imgHolder {
                    max-width: 500px;
                    width: 95%;
                }
            }
        }

        @media (max-width: $sm) {
            .slideSide {
                display: none;
            }
        }

        @media (max-width: $xs) {
            .slideSide {
                display: none;
            }

            .revealContent {
                .topicTitle {
                    font-size: 1.4em;
                }
            }
        }
    }
    //Tabs
}
