.loginPage {
  
  /* Form Styles */
  form {
    font-size: 1em;
  }
  
  input:not([type=checkbox]):not([type=radio]),
  select,
  textarea {
    position: relative;
    display: block;
    width: 100%;
    height: 58px;
    margin: 0;
    border: 2px solid $grey;
    height: 57px;
    padding: 27px 10px 10px;
    border-radius: 0;
    white-space: normal;
    line-height: 1;
    background: $light;
    color: $basic;
    font-size: 1.15em;
    text-align: left;
    transition: border-color 200ms;
    appearance: none;
    
    &:focus {
      border-color: $primary;
    }
  }
  
  select {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWF4WU1pZCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOiM0NDQ7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5hcnJvd3M8L3RpdGxlPjxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjQuOTUiIGhlaWdodD0iMTAiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMS40MSA0LjY3IDIuNDggMy4xOCAzLjU0IDQuNjcgMS40MSA0LjY3Ii8+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjMuNTQgNS4zMyAyLjQ4IDYuODIgMS40MSA1LjMzIDMuNTQgNS4zMyIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: 25px 50px;
    padding-top: 26px;
    
    &::-ms-expand {
      display: none;
    }
  }
  
  #PhoneNumber {
    padding-left: 45px;
  }
  
  button {
    @include button;
    font-size: 1.3em;
  }
  
  label:not(.checkboxLabel) {
    position: absolute;
    top: 8px;
    left: 11px;
    z-index: 2;
    display: block;
    margin: 0 0 5px;
    border: 0;
    padding: 0;
    line-height: 1;
    white-space: normal;
    font-size: 1em;
    font-family: $primaryFont;
    color: $dark;
    pointer-events: none;
    text-transform: uppercase;
    transition: all 300ms;
  }
  
  .fieldGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-width: 0 0 1px 0;
    border-color: rgba($dark, 0.5);
    border-style: solid;
    padding-bottom: 20px;
    margin-bottom: 25px;
    width: 100%;
    
    .groupTitle {
      width: 100%;
      font-family: $primaryFont;
      font-weight: 700;
      color: $basic;
      font-size: 1.3em;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    
    .fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
    }
    
    .fieldWrapper {
      width: calc(50% - 10px);
      margin: 0 0 20px;
    }
    
    select[multiple] {
      height: 100%;
      background-image: none;
    }
  }
  
  .fieldWrapper {
    position: relative;
    margin: 0 auto 20px;
    
    &:focus-within {
      label {
        color: $basic;
      }
    }
  }
  
  .buttonHolder {
    text-align: center;
  }
  
  /* Validation Errors */
  .error,
  .loginError {
    color: lighten($wrong, 20%);
    font-weight: bold;
    font-weight: 600;
    font-size: 0.85em;
    display: block;
    padding: 5px 5px 5px 50px;
    background: black;
    color: white;
    text-align: left;
    margin-top: -5px;
    position: relative;
    
    &:before {
      font-family: $iconFont;
      content: 'u';
      @include absolute;
      background: lighten($wrong, 20%);
      color: $light;
      padding: 5px 15px;
    }
    
    &.validation-summary-errors {
      background: transparent;
      padding: 0;
      margin-top: 0;
      
      &:before {
        display: none;
      }
      
      ul {
        margin: 0 0 10px;
        padding: 0 15px;
      }
      
      li {
        list-style: none;
        padding: 5px 5px 5px 50px;
        background: black;
        color: white;
        text-align: left;
        position: relative;
        
        &:before {
          font-family: $iconFont;
          content: 'u';
          @include absolute;
          background: lighten($wrong, 20%);
          color: $light;
          padding: 5px 15px;
        }
      }
    }
  }
  
  .field-validation-error {
    list-style: none;
    padding: 5px 5px 5px 50px;
    background: black;
    color: white;
    text-align: left;
    position: relative;
    font-size: 0.85em;
    display: block;
    margin-top: 2px;
    //height: 25px;
    height: auto;
    
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      content: 'u';
      font-family: $iconFont;
      font-size: 16px;
      background: lighten($wrong, 20%);
      color: $light;
      padding: 2px 13px;
      height: 100%;
    }
  }
  
  header {
    @include resetPos;
    z-index: 1;
    
    .logoWrap {
      padding-right: 15px;
    }
  }
  
  footer {
    &.ADGBranding {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $basic;
      z-index: 500;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 15px;
      
      .adgLogoWrap {
        a {
          display: block;
        }
        
        .adgLogo {
          height: 30px;
          width: auto;
        }
      }
    }
  }
}

#membership {
  margin-top: 25px;
  top: 50%;
  position: fixed;
  left: 50%;
  //transform: translate(-50%, -50%);
  @include resetPos;
  
  .ie11 & {
    z-index: 1;
  }
  
  .titleArea {
    background: rgba($basic, .65);
    color: $light;
    max-width: 850px;
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 15px 25px;
    border-bottom: 6px solid $primary;
    margin-bottom: 10px;
    
    p {
      @include zeroMargPad;
      font-size: 1.1em;
    }
    
    a {
      color: $link;
    }
    
    h1 {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.9em;
      line-height: 1.3em;
      display: block;
    }
  }
}

.formWrap {
  position: relative;
  max-width: 850px;
  width: 100%;
  padding: 25px 0 35px;
  background: $light;
  margin: 0 auto 60px auto;
  
  .formArea {
    padding: 0 25px;
    
    .landingPage & {
      padding: 20px 10px;
      @include transparentBG($light, .9);
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      background: transparent;
      
      a {
        @include button;
        width: 45%;
        padding: 20px;
        outline: 0;
        justify-content: center;
        font-size: 1.5em;
        border: 4px solid;
        border-image: $horizGradient;
        border-image-slice: 1;
        transition: .3s all;
        
        @include hover {
          @include useGPU;
          transform: scale(1.05);
          background: $basic;
          color: $primary;
        }
        
        &:focus {
          @include useGPU;
          transform: scale(1.05);
          background: $basic;
          color: $primary;
        }
        
        &.studentLoginBtn {
          margin-right: 10px;
        }
      }
    }
  }
  
  h1 {
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 1.8em;
    text-transform: uppercase;
    padding-bottom: 20px;
    text-align: center;
    color: $primary;
  }
  
  p {
    font-family: $basicFont;
    font-weight: 600;
    font-size: 1em;
    text-align: center;
  }
  
  .loginNav {
    margin-top: 25px;
    text-align: center;
    
    a {
      @include button;
      font-size: 0.85em;
    }
  }
}

.accountProfiler {
  padding: 0 0 20px 0!important;
  text-align: center;
  
  h1 {
    width: 100%;
    background: $primary;
    color: text-contrast($primary);
    padding: 0 !important;
  }
  
  .formInner {
    margin: 10px auto 30px;
  }
  .instruction{
    h2{
      padding-top: 20px;
    }
  }
  .validation-summary-errors{
    ul{
      list-style: none;
      margin: 0;
    padding: 0;
      li{
        background:$wrong;
        padding: 5px 10px;
        color:$light;
        text-align: center;
        font-weight: bold;
        font-size: 1.125em;
      }
    }
  }
}

.formWrap.password {
  .fieldWrapper {
    max-width: 500px;
  }
}

.formWrap.maintenance {
  .formArea {
    background: none;
    padding: 0;
    
    p {
      font-size: 1.5em;
    }
  }
}

.formWrap.login {
  display: flex;
  align-items: stretch;
  padding: 0;
  background: transparent;
  
  h1 {
    padding-bottom: 10px;
  }
  
  .fieldWrapper {
    width: 80%;
    padding: 0;
  }
  
  .returningStudentWrapper {
    width: 52%;
    padding: 30px 40px;
    background-color: rgba($basic, 0.65);
    color: $primary;
    border-left: 1px solid $grey;
    
    form {
      margin-top: 15px;
    }
    
    .loginNav {
      text-align: center;
    }
  }
  
  .newStudentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 48%;
    background: $light;
    color: $basic;
    text-align: center;
    
    h1 {
      margin-top: -80px;
      color: $basic;
    }
    
    .account {
      margin-top: 20px;
    }
    
    .account a {
      @include button;
      margin-top: 10px;
      font-size: 1.2em;
      vertical-align: middle;
      
      &:before {
        content: 'k';
        font-family: $iconFont;
        text-align: left;
        font-size: 24px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
}

.logError {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  background: $light;
  text-align: center;
  padding: 15px;
  
  h1 {
    text-transform: uppercase;
    color: $primary;
  }
  
  .error {
    color: $basic;
    font-size: 1.2em;
  }
}

@media (max-width: $sm) {
  .loginPage {
    header {
      margin-bottom: 0;
      background: rgba($basic, .65);
      padding-left: 0;
      border-left: 0;
      
      &:before {
        display: none;
      }
      
      .branding {
        align-items: center;
      }
    }
    
    .titleArea {
      border-bottom: 5px solid;
      border-image: $horizGradient;
      border-image-slice: 1;
      
      .safari & {
        position: relative;
        border: 0 !important;
        
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background: $horizGradient;
        }
      }
    }
    
    .fieldGroup {
      padding-bottom: 15px;
      
      .fieldWrapper {
        margin-bottom: 10px;
      }
    }
    
    footer.ADGBranding .adgLogoWrap {
      margin: auto;
    }
  }
  
  #membership {
    margin-top: 0;
    @include resetPos;
    transform: translate(0, 0);
  }
  
  .formArea {
    padding: 0;
  }
  
  .formWrap {
    width: 95%;
    margin-top: 25px;
    
    &.maintenance {
      margin-top: 30px;
    }
  }
  
  .formWrap.login {
    display: block;
    
    .newStudentWrapper {
      width: 100%;
      
      h1 {
        margin-top: 0;
        padding-top: 20px;
      }
      
      .account {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
    
    .returningStudentWrapper {
      width: 100%;
      padding: 25px;
      border-top: 1px solid $grey;
      border-left: 0;
      
      .fieldWrapper {
        width: 80%;
      }
    }
  }
}

@media (max-width: $xs) {
  .formWrap {
    margin-top: 20px;
    
    &.maintenance {
      margin-top: 20px;
      
      .formArea {
        p {
          display: block;
        }
      }
    }
    
    h1 {
      font-size: 1.5em;
    }
  }
  
  .formArea {
    
    .fieldWrapper {
      width: 100% !important;
    }
  }
  
  .loginNav {
    text-align: center;
  }
  
  .landingPage .formWrap .formArea {
    flex-wrap: wrap;
    
    a {
      width: 100%;
      margin: 5px auto;
      
      &.studentLoginBtn {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: $xxs) {
  #membership {
    .titleArea {
      margin-bottom: 0;
      
      h1 {
        font-size: 1.6em;
        line-height: 1.2em;
      }
    }
    
    .formWrap {
      margin: 0 0 60px 0;
      width: 100%;
    }
  }
  
  .returningStudentWrapper {
    padding: 15px;
    
    .fieldWrapper {
      width: 95%;
    }
  }
}
