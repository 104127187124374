header {
  position: fixed;
  top: 45px;
  left: 50px;
  overflow: hidden;
  transition: all 300ms;
  padding: 0 0 55px 40px;
  
  &:before {
    content: '';
    width: 5px;
    height: 100%;
    background: $vertGradient;
    @include absolute;
  }
  
  .helpMode & {
    margin-top: 0;
  }
  
  .alertMode & {
    opacity: 0;
  }
  
  .branding {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
  
  .logoWrap {
    display: block;
    padding-bottom: 35px;
    
    .logo {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
  
  .titleWrapper {
    display: flex;
    
    .gooseWelcome {
      //   display: none !important;
    }
    
    h1 {
      height: 100%;
      margin: 0;
      text-align: left;
      font-weight: 300;
      font-size: 2.125em;
      line-height: 1em;
      color: $primary;
      
      .orientationComplete & {
        display: none;
        
        &.completedMessage {
          display: block;
        }
      }
      
      &.completedMessage {
        display: none;
      }
    }
    
    .student, .message, .orientationTitle {
      display: block;
      color: $light;
      padding-bottom: 8px;
    }
    
    .student {
      color: $primary;
      font-weight: 600;
      font-size: 1.125em;
    }
  }
}

.inSection {
  header {
    opacity: 0;
  }
  
  .insideHeader {
    right: 0;
  }
}

.insideHeader {
  position: fixed;
  top: 0;
  right: -400px;
  width: 350px;
  width: 250px;
  height: 100%;
  // background: lighten($grey, 10%);
  transition: .5s all;
  z-index: 100;
  //@include boxShadow;
  backdrop-filter: blur(5px);
  
  &:before {
    content: '';
    position: absolute;
    top: 280px;
    left: 0;
    width: 100%;
    height: calc(100% - 200px);
    //background: $light;
    //background: lighten($grey, 15%);
    background: $basic;
    opacity: .75;
    
    .ie11 & {
      opacity: .85;
    }
  }
  
  .branding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    background: rgba($basic, .65);
    width: 100%;
    height: 280px;
    border: 10px solid $primary;
    // border-image-source: $horizGradient;
    // border-image-slice: 1;
    color: $light;
    padding: 20px 20px;
    text-align: right;
    
    .ie11 & {
      background: rgba($basic, .85);
    }
    
    .logoWrap {
      padding-bottom: 20px;
      width: 100%;
      cursor: pointer;
      
      img {
        width: 110px;
      }
    }
    
    .titleWrapper {
      text-align: right;
      
      .ie11 & {
        width: 100%;
      }
      
      h1 {
        font-weight: 400;
        font-size: 1.25em;
      }
      
      .student {
        display: block;
      }
      
      .sectionTitle {
        display: block;
        color: $primary;
        text-transform: uppercase;
        font-size: 1.125em;
        
        .module {
          display: block;
          text-align: right;
          font-size: 1.25rem;
          color: $primary;
          line-height: 1.45em;
          
          .PreviewMode & {
            display: none;
          }
          
          &:before {
            content: '(';
          }
          
          &:after {
            content: ')';
          }
          
          &:empty {
            display: none !important;
          }
        }
      }
    }
  }
}

@media (max-height:750px) {
  header {
    top: 15px;
    left: 20px;
    padding: 5px 0 50px 30px;
    
    .logoWrap {
      padding-bottom: 30px;
      width: 110px;
    }
    
    .titleWrapper {
      h1 {
        font-size: 1.5em;
      }
    }
  }
}

@media (max-width: 1600px) {
  header {
    margin-top: 0;
  }
}

@media (max-width:$lg) {
  .insideHeader {
    width: 200px;
    
    &:before {
      top: 180px;
      height: calc(100% - 180px);
    }
    
    .branding {
      height: 180px;
      border: 6px solid $primary;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    
    .titleWrapper {
      h1 {
        display: none;
      }
    }
  }
}


@media (max-width: $sm + 100) {
  header {
    @include resetPos;
    padding: 20px 0 0px 30px;
    
    @media (min-width:$sm + 1) {
      padding-bottom: 50px;
    }
    
    .inSection & {
      display: none;
    }
    
    .branding {}
    
    .logoWrap {
      padding-bottom: 13px;
    }
    
    .titleWrapper {
      display: block;
      
      h1 {
        font-size: 1.5em;
      }
      
      .message, .student, .orientationTitle {
        display: inline-block;
      }
      
      .student {
        display: none;
      }
    }
  }
  
  .insideHeader {
    // @include resetPos;
    height: 100px;
    width: 100%;
    display: none;
    
    .inSection & {
      display: block;
    }
    
    .branding {
      border: 0;
      border-bottom: 8px solid;
      border-image: $horizGradient;
      border-image-slice: 1;
      height: 100%;
      
      .logoWrap {
        height: 70px;
        width: auto;
        padding-bottom: 0;
        
        img {
          height: 100%;
          width: auto;
        }
      }
    }
  }
}

@media (max-width: $xs) {
  header {
    
    
    .branding {}
    
    .logoWrap {}
  }
}
