/*********MODAL STYLES*****************/
.revealSlide.Modal {
  .content {
    &.disabled {
      .slideCopy {
        opacity: .35;
      }
      
      .slideSide {
        opacity: .35;
      }
    }
  }
  
  &.noImg {
    .revealCopy {
      max-width: 1050px;
      display: block;
      width: 100%;
      margin: 0 auto;
      padding-left: 0;
      padding-bottom: 20px;
    }
    
    .slideCopyHolder {
      max-width: 960px;
      width: 100%;
      padding: 0 15px;
      margin: 0 auto 40px;
      
      .smTip {
        display: none;
        opacity: 0;
      }
    }
    
    .slideSide {
      display: block;
      margin: 0 auto;
      
      .imgWrap {
        display: none;
      }
      
      .tips {
        opacity: 1;
      }
    }
  }
  
  .revealContentHolder {
    width: 100%;
    padding-left: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .TitleOnly {
    display: flex;
    flex-wrap: wrap;
    
    @media (max-width: $xs) {
      flex-direction: column;
    }
    
    .revealItemHolder {
      background: $primary;
      margin: 10px .5% 0 .5%;
      width: calc(98% * (1/3) - 10px - 1px);
      flex-grow: 1;
      height: 280px;
      position: relative;
      
      @include hover {
        background: $primaryHover;
      }
      
      .inner, .revealBtn {
        height: 100%;
      }
      
      @media(max-width: $xs) {
        height: auto !important;
        width: calc(100% - 10px);
        padding: 5px 0;
        margin-left: 0;
        flex-grow: 0;
      }
    }
    
    .revealBtnWrap {
      height: 100%;
      
      .revealBtn {
        padding: 5px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    .titleWrap {
      color: $basic;
      text-align: center;
      
      &:before {
        @include iconFont;
        content: "\69";
        font-size: 1.5em;
      }
      
      h2 {
        font-size: 1.2em;
        line-height: 1.6em;
        margin: 0;
        color: $basic;
      }
    }
  }
  
  .ImageOnly {
    .revealItemHolder {
      margin: 10px .5% 0 .5%;
      width: calc(98% * (1/3) - 10px - 1px);
      flex-grow: 1;
      height: 280px;
      position: relative;
    }
    
    .revealBtnWrap {
      @include absolute;
      @include fillSpace;
      transition: box-shadow .2s, margin-top .2s, transform .2s;
      
      @include hover() {
        box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
        margin-top: -4px;
        
        .topicImage {
          filter: blur(4px);
          opacity: .85;
        }
        
        .titleWrap {
          transform: rotateY(0) rotateZ(0);
          opacity: 1;
          
          h2 {
            margin-top: 15px;
            opacity: 1;
            text-align: center;
          }
        }
      }
      
      &.clicked {
        transform: scale(0) rotateY(60deg);
      }
    }
    
    .revealBtn {
      @include absolute;
      @include fillSpace;
      overflow: hidden;
      
      .topicImage {
        @include absolute;
        @include fillSpace;
        @include backgroundCover;
        transition: filter .2s, opacity .2s;
        
        img {
          display: none;
        }
      }
    }
    
    .titleWrap {
      background: rgba($primary, .8);
      top: 20px;
      position: absolute;
      z-index: 2;
      left: 20px;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      padding: 15px 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity .2s, transform .2s;
      transform: rotateY(50deg) rotateZ(20deg);
      opacity: 0;
      text-align: center;
      
      &:before {
        @include pseudoContent;
        border: 1px solid $primary;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        position: absolute;
        left: -10px;
        top: -10px;
      }
      
      h2 {
        color: text-contrast($primary);
        @include zeroMargPad;
        font-size: 1em;
        font-weight: 600;
        position: relative;
        margin-top: 40px;
        opacity: 0;
        text-align: center;
        transition: opacity .2s .15s, margin-top .4s .15s;
        
        &:before {
          @include iconFont;
          content: "\69";
          font-size: 1.4em;
          @include horzCenter;
          position: absolute;
          top: -45px;
        }
      }
    }
    
    @media(max-width: $xs) {
      .revealItemHolder {
        height: 100px !important;
        width: calc(100% - 10px);
        margin-left: 0;
        flex-grow: 0;
      }
      
      &.noImg {
        .slideCopyHolder {
          margin-bottom: 20px;
        }
      }
      
      .titleWrap {
        h2 {
          padding-top: 0;
          
          &:before {
            top: 0;
            left: auto;
            position: static;
            margin-right: 5px;
            font-size: 1em;
          }
        }
      }
    }
    
    @media (max-width: $xxs) {
      .titleWrap {
        h2 {
          font-size: 1.15em;
        }
      }
      
      &.noImg {
        .slideCopyHolder {
          padding: 0;
        }
      }
    }
  }
  
  .ImageAndTitle {
    .revealItemHolder {
      margin: 10px .5% 0 .5%;
      width: calc(98% * (1/3) - 10px - 1px);
      flex-grow: 1;
      height: 280px;
      position: relative;
    }
    
    .revealBtnWrap {
      @include absolute;
      @include fillSpace;
      transition: box-shadow .2s, margin-top .2s, transform .2s;
      
      @include hover() {
        box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
        margin-top: -4px;
        
        .topicImage {
          filter: blur(4px);
          opacity: .85;
        }
        
        .titleWrap {
          transform: rotateY(0) rotateZ(0);
          opacity: 1;
          
          h2 {
            margin-top: 15px;
            opacity: 1;
            text-align: center;
          }
        }
      }
      
      &.clicked {
        transform: scale(0) rotateY(60deg);
      }
    }
    
    .revealBtn {
      @include absolute;
      @include fillSpace;
      overflow: hidden;
      
      .topicImage {
        @include absolute;
        @include fillSpace;
        @include backgroundCover;
        transition: filter .2s, opacity .2s;
        
        img {
          display: none;
        }
      }
    }
    
    .titleWrap {
      background: rgba($basic, .8);
      top: 20px;
      position: absolute;
      z-index: 2;
      left: 20px;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      padding: 15px 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity .2s, transform .2s;
      transform: rotateY(0) rotateZ(0);
      opacity: 1;
      text-align: center;
      
      &:before {
        @include pseudoContent;
        border: 1px solid $primary;
        border: 0;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        position: absolute;
        left: -10px;
        top: -10px;
      }
      
      h2 {
        color: $light;
        @include zeroMargPad;
        padding: 0 20px;
        font-size: 1.25em;
        font-weight: 600;
        position: relative;
        margin-top: 40px;
        opacity: 1;
        text-align: center;
        transition: opacity .2s .15s, margin-top .4s .15s;
        
        &:before {
          @include iconFont;
          content: "\69";
          font-size: 1.4em;
          @include horzCenter;
          position: absolute;
          top: -45px;
        }
        
        @media (max-width: $xs) {
          margin-top: 0;
        }
      }
    }
    
    @media(max-width: $xs) {
      .revealItemHolder {
        height: 100px !important;
        width: calc(100% - 10px);
        margin-left: 0;
        flex-grow: 0;
      }
      
      &.noImg {
        .slideCopyHolder {
          margin-bottom: 20px;
        }
      }
      
      .titleWrap {
        h2 {
          padding-top: 0;
          
          &:before {
            top: 0;
            left: auto;
            position: static;
            margin-right: 5px;
            font-size: 1em;
          }
        }
      }
    }
    
    @media (max-width: $xxs) {
      .titleWrap {
        h2 {
          font-size: 1.15em;
        }
      }
      
      &.noImg {
        .slideCopyHolder {
          padding: 0;
        }
      }
    }
  }
  
  .revealContentWrap {
    display: none;
  }
}

/*****MODAL HOLDER STYLES*****/
.modalHolder {
  @include fixed;
  @include fillSpace;
  
  @media (min-width:$sm + 101) {
    width: calc(100% - 200px);
  }
  
  &.show {
    z-index: 100;
  }
  
  .modalBox {
    max-width: 650px;
    width: 100%;
    @include vertHorzCenter;
    position: absolute;
    margin-top: -40px;
    transition: margin-top .2s, opacity .15s;
    opacity: 0;
    padding: 10px;
    background: $light;
    box-shadow: 0 6px 8px 2px rgba(0, 0, 0, 0.3);
    
    &:before {
      @include absolute;
      top: 10px;
      left: 10px;
      @include pseudoContent;
      background: rgba($basic, .5);
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      z-index: 1;
    }
    
    .inner {
      transform: scale(.3);
      transition: transform .2s;
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: auto;
      @include scrollbar($color: $primary);
      max-height: 400px;
      position: relative;
      z-index: 2;
      
      .copyWrap {
        position: relative;
        z-index: 2;
        background: rgba($secondary, .92);
        color: text-contrast($secondary);
        padding: 15px 35px;
        margin: 10px;
        
        p {}
        
        strong {}
      }
      
      .topicTitle {
        color: text-contrast($secondary);
        text-align: center;
        font-size: 1.5em;
        border-bottom: 2px solid text-contrast($secondary);
        padding-bottom: 10px;
        margin-bottom: 15px;
      }
    }
    
    .topicImage {
      @include absolute;
      @include fillSpace;
      @include backgroundCover;
      opacity: .8;
      
      img {
        display: none;
      }
    }
    
    .closeModal {
      text-align: center;
      position: relative;
      z-index: 2;
      
      .modalBtn {
        display: inline-block;
        margin: 0 10px 10px;
        padding: 7px 25px;
        background: $primary;
        border: 2px solid $primary;
        font-weight: 700;
        color: $basic;
        text-transform: uppercase;
        cursor: pointer;
        transition: .2s all;
        
        @include hover {
          background: $basic;
          color: $primary;
        }
      }
    }
  }
  
  &.show {
    .modalBox {
      margin-top: 0;
      opacity: 1;
      
      .inner {
        transform: scale(1);
      }
    }
  }
  
  @media(max-width: $xs) {
    .modalBox {
      width: 94%;
      top: 250px;
    }
  }
  
  @media(max-width: $xxs) {
    .modalBox {
      width: calc(100% - 5px);
      transform: translate(0, 0);
      left: 0;
      
      &:before {
        height: calc(100% - 10px);
        width: calc(100% - 10px);
        top: 5px;
        left: 5px;
      }
      
      .inner {
        .copyWrap {
          margin: 5px;
          padding: 10px 20px;
          
          p {
            font-size: .95em;
            line-height: 1.5em;
          }
        }
        
        .topicTitle {
          margin-top: 10px;
          font-size: 1.25em;
        }
      }
    }
  }
}
