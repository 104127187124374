.arrowNav {
  @include clearfix;
  @include borderBox;
  margin-top: 0;
  position: fixed;
  width: 275px;
  right: -26px;
  top: calc(50% + 45px);
  z-index: 125;
  opacity: 1;
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  
  &:before {
    content: '';
    position: absolute;
    right: 42px;
    top: 50%;
    height: 5px;
    background: $horizGradient;
    width: 220px;
    transform: translate(0, -50%);
    z-index: 1;
  }
  
  .arrow {
    cursor: pointer;
    @include transition;
    text-align: center;
    padding-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: .3s all;
    
    &.disabled {
      color: $dark;
      
      .icon, .title {
        color: $dark;
      }
      
      @include hover {
        cursor: not-allowed;
      }
    }
    
    .title {
      display: inline-block;
      position: absolute;
      font-size: 1.5em;
      text-transform: uppercase;
      transition: .3s all;
      color: $light;
    }
    
    .icon {
      font-size: 2.5em;
      color: $light;
      transition: .3s all;
      
      &:before {
        padding-top: 15px;
        display: inline-block;
      }
    }
    
    @include hover {}
  }
  
  #arrowLeft {
    left: -151px;
    
    @include hover {
      .icon {
        transform: translate(-10px, 0);
        [lang="vi"] &{
          transform: translate(-15px, -2px);
        }
        color: $primary;
      }
      
      .title {
        color: $primary;
      }
    }
    
    .title {
      padding-left: 74px;
      padding-top: 13px;
      
      .ie11 & {
        padding-left: 0;
        padding-top: 0;
        margin-top: -8px;
      }
    }
    .icon{
      [lang="vi"] &{
        transform: translate(-5px, -2px);
      }
    }
  }
  
  #arrowRight {
    right: 75px;
    [lang="vi"] &{
      right: 88px;
    }
    @include hover {
      .icon {
        transform: rotate(180deg) translate(-10px, 0);
        color: $primary;
        [lang="vi"] &{
          transform:rotate(180deg) translate(-58px, -1px);
        }
      }
      .title {
        color: $primary;
      }
    }
    
    .icon {
      transform: rotate(180deg);
      display: block;
      padding-left: 30px;
      [lang="vi"] &{
        transform:rotate(180deg) translate(-48px, -1px);
      }
    }
    
    .title {
      left: -44px;
      top: 7px;
    }
  }
}

@media (max-width:$lg) {
  .arrowNav {
    width: 210px;
    top: 50%;
    right: -10px;
    margin-top: -10px;
    
    &:before {
      width: 140px;
      right: 42px;
    }
    
    #arrowRight {
      right: 37px;
    }
    
    #arrowLeft {
      left: -116px;
    }
  }
}

@media (max-width: $sm + 100) {
  .arrowNav {
    top: 30px;
    right: 0;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    
    &:after, &:before {
      display: none;
    }
    
    .arrow {
      &.disabled {
        opacity: .25;
      }
    }
    
    #arrowLeft {
      left: 25px;
      right: auto;
      top: -16px;
      
      .title {
        padding-top: 17px;
      }
    }
    
    #arrowRight {
      right: auto;
      left: auto;
      top: 0;
    }
  }
}

@media (max-width:$xs) {
  .arrowNav {
    .arrow {
      .title {
        display: none;
      }
    }
  }
}
