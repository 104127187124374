/**ADG UNIVERSAL VARIABLES**/
//DYNAMIC COLORS
$primary: #fcb525;
$secondary: #000000;
$alt: $primary;
$accent: $primary;
$contrast: $secondary;
$hover: $primary;

//GRADIENT COLORS
$monarch: #EF7622;
$rocket: #D9272E;
$vertGradient: linear-gradient(0deg, $monarch 10%, $rocket 49%);
$horizGradient: linear-gradient(-90deg, $monarch 10%, $rocket 49%);

//CONSTANT COLORS
$basic: #000;
$light: #fff;
$dark: lighten(#707070, 20%);
$grey: #d0d0d0;

/* Default link color for ADA compatibility */
$link: $basic;
$bg: #EBEBEC;
$wrong: $rocket;
$correct: #009D4E;



/* Hover colors can be a 3rd and/or 4th color if the school has them (if not, you can use the lighten and/or darken sass functions) */
$primaryHover: lighten($primary, 10%);
$secondaryHover: lighten($secondary, 10%);
/* ADA hover needs to be a color that contrasts with the primary color but also is dark enough to see white against. */
$ADAhover: $basic;


//FONTS
$primaryFont: 'Montserrat', sans-serif;
$secondaryFont: 'Nunito Sans', sans-serif;
$basicFont: 'Nunito Sans', Geneva, sans-serif;
$iconFont: 'houston-cc-makeover';


//WIDTHS
$tiny: 400px;
$xxs: 480px;
$xs: 600px;
$sm: 800px;
$md: 1050px;
$lg: 1280px;
$xl: 1500px;
$xxl: 1575px;

//ELEMENTS
$maxWidth: 1600px;
$toolBarWidth: 450px;
$headerHeight: 110px;
$footerHeight: 45px;
$innerFoot: 60px;
$innerHead: 90px;
$topBarHeight: 80px;
$progress: 20px;
$infoSize: 50px;
$smHeader: 60px;
$cursor: pointer;
/* Based on the logo, adjust this max width for ADA responsive */
$logoADAMax: 275px;
