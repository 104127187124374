.slide.quizSlide {
  
  .videoWrapper {
    //margin:0;
  }
  
  legend {
    @include legendFix();
  }
}

.quizCopy {}

.quizButtons {
  @include zeroMargPad;
  list-style: none;
  margin-top: 15px;
  width: auto;
  @include headerFont($weight:600);
  
  li {
    display: flex;
    align-items: center;
    background: $primary;
    @include clearfix;
    padding: 18px 13px;
    cursor: $cursor;
    color: $basic;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 0 10px 10px 10px;
    transition: .3s all;
    
    @include hover {
      background: $basic;
      margin-left: -10px;
      margin-right: -10px;
      color: $light;
      
      .letter {
        color: $light !important;
      }
    }
  }
  
  p {
    flex-grow: 1;
    margin: 0;
    font-size: 1em;
    height: 100%;
    box-sizing: border-box;
    text-align: right;
  }
  
  span.letter {
    font-family: $primaryFont;
    font-weight: 400;
    padding: 0 20px 0 15px;
    font-size: 2em;
    box-sizing: border-box;
    text-align: center;
    color: $basic;
  }
  
  .picked {
    background: $wrong;
    color: $light;
    border-radius: 10px 10px 10px 0;
    
    @include hover {
      background: $wrong;
      color: $light;
      
      span.letter {
        color: $light !important;
      }
    }
    
    span.letter {
      background: $wrong;
      color: $light;
    }
  }
  
  li.correct.picked {
    color: $light;
    background: $correct;
    
    @include hover {
      background: $correct;
      color: $light;
      
      span.letter {
        color: $light !important;
      }
    }
    
    span.letter {
      background: $correct;
    }
  }
}

.answersWrapper {
  @include clearfix;
  color: $basic;
  font-size: 1.35em;
  padding: 20px 35px;
}

.quizContent {
  color: $light;
  padding: 0;
  
  p {
    color: $basic;
    width: auto;
    font-size: 1.1em;
    position: relative;
    padding-bottom: 10px;
    
    &.resp {
      display: none;
      font-size: 1em;
      padding: 5px 10px;
      color: $light;
      
      &:before {
        @include iconFont;
        color: $light;
        margin-right: 4px;
      }
    }
    
    &.answerWrong {
      background-color: $wrong;
      
      &:before {
        content: 'b';
      }
    }
    
    &.answerCorrect {
      background-color: $correct;
      
      &:before {
        content: 'a';
      }
    }
  }
}

@media (max-width: $lg) {
  .slide.quizSlide {
    .quizCopy {
      // width: 100%;
      // padding: 0 0 45px;
      // float: none;
    }
  }
}

@media (max-width: $xs) {
  .answersWrapper {
    padding: 20px 10px;
    margin-bottom: 75px;
  }
}
