#helpBG {
  display: none;
  background: rgba($basic, 0.65);
  backdrop-filter: blur(10px);
  @include fixed;
  @include fillSpace;
  z-index: 400;
}

#helpContent {
  z-index: 401;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  @include fillSpace;
  
  .helpArrowNav {
    display: block;
  }
}

#helpCarousel {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 0 95px;
  background: $light;
  color: $basic;
  top: -400px;
  @include transition($speed: 1s);
  border: 0 !important;
  
  //border-top: 45px solid $basic;
  &.showing {
    top: 0;
  }
  
  .logoWrap {
    background: $basic;
    text-align: center;
    padding-top: 5px;
  }
  
  .carousel-inner {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
  }
  
  .item {
    width: 100%;
    margin: 0 auto;
    padding: 0 100px 45px;
    margin-top: 30px;
    
    .icon {
      vertical-align: middle;
      margin-right: 5px;
      speak: never;
    }
  }
  
  .titleWrap {
    background: $secondary;
    padding: 12px 0;
    text-align: center;
    position: relative;
    
    &:after {
      content: '';
      width: 100%;
      height: 5px;
      background: $horizGradient;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  
  h1 {
    text-transform: uppercase;
    color: text-contrast($secondary);
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 1.7em;
    font-size: 1.2em;
  }
  
  h3 {
    @include headerFont;
    font-weight: 600;
    font-size: 1.1em;
    color: $secondary;
    margin: 15px 0 5px;
  }
  
  .helpSlideTitle {
    font-weight: 400;
    font-size: 1.8em;
    color: $basic;
    margin: 15px 0 5px;
  }
  
  .copy {
    font-family: $basicFont;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.45em;
    color: $basic;
    
    ul {
      li {
        padding-bottom: 8px;
      }
    }
    
    p {
      @include zeroMargPad;
      padding-bottom: 10px;
    }
    
    img {
      max-width: 100%;
      height: auto;
      @include boxShadow();
    }
    
    h2 {
      font-size: 1.8em;
    }
  }
  
  .helpArrowNav {
    position: absolute;
    left: 50%;
    width: 250px;
    bottom: 31px;
    z-index: 12;
    transform: translate(-50%, 0);
    
    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary;
      top: auto;
      position: absolute;
      width: 40px;
      height: 40px;
      text-decoration: none !important;
      border: 2px solid $basic;
      transition: .2s all;
      
      span {
        color: text-contrast($primary);
        font-size: 1.75em;
        transition: .2s all;
      }
      
      @include hover {
        background: $basic;
        border-radius: 0;
        
        span {
          color: $primary;
        }
      }
      
      &.left {
        left: 5px;
        
        span {
          transform: rotate(180deg);
        }
      }
      
      &.right {
        right: 5px;
      }
    }
  }
  
  .carousel-indicators {
    bottom: -15px;
    left: 50%;
    margin-left: 0;
    width: 100px;
    transform: translate(-50%, 0);
    
    li {
      border: 2px solid $basic;
      border-radius: 0;
      
      &.active {
        background: $primary;
      }
    }
  }
  
  .helpBtnWrap {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    
    .lbClosebtn {
      @include button;
      font-size: 1.2em;
      border: 2px solid $primary;
      
      &.closeBtn {
        max-width: 200px;
        margin: auto;
        right: 0;
        left: 0;
      }
    }
    
    &.disabled {
      .lbClosebtn {
        background: $light;
        color: $dark;
        border: 2px solid $dark;
        
        @include hover {
          cursor: not-allowed;
        }
      }
    }
    
    @include hover {
      cursor: pointer;
    }
  }
}

.inSection {
  .helpBtn {
    display: none;
  }
}

.swipeTutorial {
  display: none;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  
  @keyframes swipeTutorial {
    from {
      right: 0%;
    }
    
    to {
      right: 40%;
    }
  }
  
  img {
    position: fixed;
    bottom: 20px;
    max-width: 260px;
    height: auto;
    animation: swipeTutorial 1200ms;
    animation-delay: 500ms;
    animation-fill-mode: both;
  }
  
  p {
    position: fixed;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    width: 90%;
    display: block;
    padding: 0 40px;
    font-size: 2em;
    text-align: center;
    color: $light;
  }
}

@media (max-width: $md) {
  #helpContent {
    top: 0;
  }
}

@media (max-width: $sm) {
  #helpContent {
    background: $light;
    height: auto;
    width: 100%;
    margin-bottom: 100px;
  }
  
  #helpCarousel {
    &.showing {
      top: 0;
    }
    
    // .helpArrowNav {
    //   position: fixed;
    //   top: auto;
    //   bottom: 0;
    //   width: 100%;
    //   height: 45px !important;
    //   z-index: 200;
    
    //   .arrow {
    //     width: 50%;
    //     height: 45px;
    //     padding-top: 0;
    //     border: 2px solid $light;
    //     border-bottom: 0;
    
    //     span {
    //       font-size: 1.8em;
    
    //       &:before {
    //         padding-top: 8px;
    //       }
    //     }
    //   }
    // }
  }
}

@media (max-width: $xs) {
  #helpContent {
    top: 0;
    position: absolute;
  }
  
  #helpCarousel {
    .titleWrap {
      // text-align: left;
      // padding-left: 10px;
    }
    
    &::before {
      display: none;
    }
    
    .helpSlideTitle {
      font-size: 1.8em;
    }
    
    .copy {
      li {
        margin-bottom: 7px;
      }
      
      ul {
        margin: 0;
      }
      
      p {
        @include zeroMargPad;
        padding-bottom: 10px;
      }
      
      img {
        max-width: 100%;
        height: auto;
        @include boxShadow();
      }
      
      h2 {
        font-size: 1.8em;
      }
    }
    
    .helpBtnWrap {
      .lbClosebtn {
        //font-size: 1.5em;
      }
    }
    
    .item {
      width: 100%;
      padding: 0 20px 45px;
    }
    
    .helpSlideTitle {
      margin-top: 5px;
      padding-top: 5px;
    }
  }
}

@media (max-width: $xxs) {
  #helpCarousel {
    padding: 0 0 80px;
    font-size: 0.9em;
    
    h1 {
      max-height: 100%;
    }
  }
}
