$alertW: 240px;
$alertI: 70px;

//Alertbar & Widget Templates
//@import '../templates/_alertBar/_rollout.scss'; //Straight rollout
//@import '../templates/_alertBar/_overlay.scss'; //Overlay
//@import '../templates/_alertBar/_round-docked.scss'; //Round Docked
//@import '../templates/_alertBar/_round-undocked.scss'; //Round Undocked
@import '_maverick-default.scss'; //No Widget

.alertMode {
  .alertBar {
    z-index: 12;
  }
  
  .item {
    margin-bottom: 0;
  }
}

.inSection {
  .alertBar {
    display: none;
  }
}

//ALERT CONTENT AREAS//
#alertContentWrap {
  display: none;
  position: fixed;
  bottom: 0;
  right: 100px;
  width: calc(100% - 180px);
  height: calc(100% - 110px);
  padding: 0;
  background: $light;
  z-index: 11;
  overflow: auto !important;
  border-radius: 0 15px 0 0;
  
  &:before {
    content: "";
    background: linear-gradient(0deg, #000 20%, transparent 99%);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 250px;
    opacity: 0;
    z-index: -1;
    transition: .3s all;
    
    .alertMode & {
      opacity: 1;
    }
  }
  
  .fade {
    position: fixed;
    bottom: 0;
    left: 80px;
    width: calc(100% - 180px);
    height: 250px;
    background: linear-gradient(180deg, white 10%, transparent 350%);
    opacity: 0;
    z-index: -1;
    transition: .3s all;
    
    .alertMode & {
      opacity: 1;
    }
  }
  
  .alertContent {
    display: none;
    padding: 0 0 75px;
    text-align: center;
  }
  
  h1 {
    @include headerFont;
    font-weight: 400;
    font-size: 2.75em;
    padding: 12px 40px 5px 40px;
    text-align: center;
    //background: $secondary;
    color: $basic;
    border-bottom: 4px solid;
    border-image-source: $horizGradient;
    border-image-slice: 1;
    display: inline-block;
    
    .safari & {
      position: relative;
      border: 0;
      
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: $horizGradient;
      }
    }
    
    span {
      margin-right: 10px;
    }
  }
  
  .closeBtn {
    display: none;
    background: $primary;
    color: text-contrast($primary);
    width: 55px;
    height: 50px;
    position: absolute;
    top: 7px;
    left: 10px;
    font-size: 1.5em;
    padding: 11px 0 0;
    text-align: center;
    border: 2px solid $light;
    transition: .3s all;
    
    @include hover {
      cursor: pointer;
      color: $primary;
      background: $basic;
    }
    
    &.show {
      display: block;
    }
  }
}

.alertInnerContent {
  display: flex;
  padding: 25px 40px;
  text-align: left;
}

.alertListCopy {
  width: 65%;
  padding-left: 25px;
}

.alertInstruction {
  width: 35%;
  background: $basic;
  color: $light;
  padding: 25px 30px 40px;
  font-size: 1.3em;
  font-weight: 400;
  
  h2 {
    @include headerFont;
    margin: 0 auto !important;
    font-weight: 400 !important;
    font-size: 1.5em !important;
    color: $light !important;
    padding: 5px 0 10px;
    display: block !important;
    //text-transform: uppercase;
  }
}

#innerChecklist {
  display: block;
  position: relative;
  background: none;
  overflow: auto !important;
  border: none;
  font-size: 14px;
  line-height: 1.42857143;
}

#checklist, #innerChecklist {
  
  .printerBtn {
    @include button;
    display: inline-block;
    margin-right: 5px;
    font-size: .9em;
    position: relative;
    padding: 10px 15px;
    cursor: pointer;
    
    span {
      margin-right: 10px;
    }
    
    &:before {
      display: none;
    }
  }
  
  .list {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  
  .clickArea {
    padding-left: 65px;
    margin-left: -65px;
    position: relative;
    z-index: 100;
    padding-right: 10px;
    cursor: pointer;
  }
  
  .checkItem {
    color: $basic;
    position: relative;
    @include clearfix;
    margin: 0 0 10px;
    padding: 10px 0 1px 65px;
    //max-width: 960px;
    width: 100%;
    transition: .3s all;
    border: 1px solid transparent;
    
    .chLink {
      margin-bottom: 20px;
    }
    
    .checkbox {
      @include pseudoContent;
      height: 18px;
      width: 18px;
      border: 2px solid $basic;
      background: transparent;
      @include absolute;
      top: 16px;
      left: 28px;
      line-height: 0.5em;
      font-size: 1.4em;
      text-align: center;
      margin-top: 0;
      
      @include hover {
        cursor: pointer;
        color: $grey;
        
        &:before {
          color: $primary;
        }
      }
      
      a {
        text-decoration: underline !important;
      }
    }
    
    .instructions {
      margin-bottom: 20px;
      font-size: 1.15em;
      line-height: 1.4em;
      
      p {
        @include zeroMargPad;
      }
      
      ul {
        @include zeroMargPad;
        margin-left: 15px;
      }
    }
    
    &.checked {
      background: lighten($grey, 10%);
      border-radius: 8px 8px 8px 0 !important;
      border: 1px solid $dark;
      
      .checkbox {
        //background: $basic;
        
        &:before {
          color: $basic;
          @include iconFont;
          position: absolute;
          content: 'a';
          font-size: 1.2em;
          top: -7px;
          left: 0;
        }
      }
    }
    
    @include hover {
      background: lighten($grey, 10%);
      border-radius: 0 8px 8px 8px;
    }
  }
  
  h3 {
    cursor: pointer;
    position: relative;
    margin-bottom: 4px;
    @include headerFont;
    color: $basic;
    font-size: 1.5em;
    font-weight: 400;
  }
  
  .due {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.6em;
    font-family: $primaryFont;
    display: block;
    margin: 0;
    clear: both;
    color: $basic;
    padding: 0;
    letter-spacing: 0;
  }
  
  .noDate {
    display: none;
  }
  
  .chLink a {
    @include button;
    display: inline-block;
    margin-right: 5px;
    font-size: 0.9em;
    padding: 10px 15px;
    position: relative;
    padding-left: 28px;
    
    &:before {
      @include iconFont;
      content: 'd';
      margin-right: 5px;
      position: absolute;
      left: 10px;
      top: 10px;
    }
    
    &[href*='.pdf'] {
      padding-left: 40px;
      
      &:before {
        content: "p";
        font-size: 1.7em;
        top: 6px;
      }
    }
  }
}

#announcements {
  .alertListCopy {
    padding-right: 25px;
  }
  
  .announcement {
    padding: 10px 50px;
    font-size: 1.125em;
    margin-bottom: 5px;
    
    &.new {
      padding: 20px 50px;
      
      h2 {
        font-weight: 600;
        color: $basic;
        
        &:before {
          color: $basic;
        }
      }
    }
    
    h2 {
      @include headerFont;
      font-weight: 600;
      font-size: 1.25em;
      padding: 3px 20px 3px 40px;
      color: $basic;
      position: relative;
      
      &:before {
        @include iconFont;
        text-transform: none;
        color: $basic;
        content: 'e';
        margin-left: -10px;
        margin-right: 10px;
        @include absolute;
        font-weight: normal;
        font-size: 1.4em;
        left: 6px;
        top: 6px;
      }
    }
    
    p {
      @include zeroMargPad;
      padding: 5px 20px 0 40px;
      color: $basic;
      font-family: $basicFont;
      line-height: 1.7em;
      
      @media all and (max-width: $xs) {
        padding: 5px;
        line-height: 1.35em;
      }
    }
  }
}

#account {
  .accountContent {
    @include clearfix;
    margin-left: 40px;
    
    h2 {
      font-family: $secondaryFont;
      font-size: 1.5em;
      font-weight: 400;
      color: lighten($secondary, 25%);
      float: left;
      margin-right: 20px;
      
      @media all and (max-width: $xs) {
        margin: 0 20px 20px 0;
      }
      
      span {
        font-weight: 700;
        color: $basic;
      }
    }
    
    a {
      @include button;
      margin: 0;
      display: block;
      float: left;
    }
  }
}

#resources {
  .slide {
    background-color: transparent;
  }
  
  .slideTitle {
    display: none;
  }
  
  .learnMoreLinksWrap {
    @include clearfix;
  }
  
  .learnMoreLinks {
    border: 0;
  }
  
  .progNum {
    display: none;
  }
}

#wrapper.noOverflow {
  overflow: hidden;
}

@media (max-height:750px) {
  .alertBarWidget {
    .alertBar {
      padding: 25px 20px 0 0;
    }
  }
}

@media (max-width: $xl) {
  .alertBarWidget {
    .alertBar {
      //transform: translateY(-40%);
    }
  }
}

@media (max-width: $lg) {
  #alertContentWrap {
    height: 100%;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 100;
    border-radius: 0;
    
    &:before {
      opacity: 0 !important;
    }
    
    .fade {
      opacity: 0 !important;
    }
    
    #announcements {
      .alertListCopy {
        padding: 0;
      }
      
      .announcement {
        padding: 10px;
      }
    }
  }
  
  .alertInnerContent {
    display: block;
    background: white;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .alertInstruction {
    width: 100%;
  }
  
  .alertListCopy {
    width: 100%;
    margin-top: 25px;
    padding: 0;
  }
}

@media (max-width: $sm) {
  .alertBarWidget {
    .alertBar {
      top: auto;
      bottom: 33px;
      height: auto;
      display: flex;
      width: 100%;
      height: auto;
      padding-right: 0;
      padding: 10px 20px 10px 0;
      background: black;
      border-top: 2px solid $grey;
      
      .inSection & {
        display: none;
      }
      
      .top {
        width: 100%;
        padding: 0;
        justify-content: space-between;
      }
      
      .right {
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: nowrap;
        width: 120%;
        padding: 0;
      }
      
      .item {
        float: none;
        text-align: center;
      }
      
      .notify {
        padding: 0px 5px;
        font-size: .65em;
        top: 20px;
        left: 44px;
      }
    }
  }
  
  #alertContentWrap {
    height: 100%;
    border-right: 0;
    
    #checklist, #innerChecklist {
      a {
        &.checkPrint {
          display: none;
        }
      }
    }
  }
  
  .alertMode {
    .alertBar {
      bottom: -7px;
    }
  }
}

@media (max-width: $xs) {
  .alertBarWidget {
    .alertBar {
      
      .icon {
        font-size: 1.25em;
      }
    }
  }
  
  #alertContentWrap {
    
    h1 {
      font-size: 1.4em;
      //padding: 5px 5px 7px 45px;
    }
    
    .closeBtn {
      font-size: 1.5em;
      padding: 0;
      height: 30px;
      width: 30px;
      top: 4px;
      left: 5px;
      padding-top: 2px;
    }
    
    #announcements {
      .announcement {
        h2 {
          line-height: 1.2em;
          
          &:before {
            font-size: 1.125em;
            left: 16px;
            top: 4px;
          }
        }
        
        &.new {
          padding: 20px 0;
        }
      }
    }
    
    #checklist, #innerChecklist {
      h3 {
        font-size: 1.2em;
        line-height: 1.25em;
      }
      
      .checkItem {
        padding: 10px 0 10px 30px;
        
        .checkbox {
          left: 5px;
          top: 12px;
          width: 15px;
          height: 16px;
          
          &:before {
            font-size: 1em;
            top: -7px;
            left: -1px;
          }
        }
      }
    }
  }
  
  .alertInnerContent {
    padding: 15px 20px;
  }
  
  .alertInstruction {
    padding: 10px 30px;
    font-size: 1em;
    
    h2 {
      font-size: 1.5em !important;
    }
  }
  
  .slide.checkSlide {
    .alertInnerContent {
      padding: 0;
    }
    
    #innerChecklist {
      .checkItem {
        padding: 10px 0 1px 40px;
        
        .checkbox {
          left: 8px;
        }
      }
    }
  }
}
