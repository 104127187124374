.popupMessage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8888;
  background: rgba($basic, 0.65);
  backdrop-filter: blur(10px);
  
  .ie11 & {
    background: rgba($basic, 0.85);
  }
  
  .logoWrap {
    max-width: none;
    margin: -25px;
    padding: 10px;
    background: $light;
    text-align: center;
    height: 84px;
    margin-bottom: 13px;
    
    .logo {
      max-height: 100%;
      height: auto;
      margin: 0 auto;
    }
  }
  
  .message {
    width: 100%;
    position: absolute;
    left: 0;
    top: -30%;
    margin: 0;
    //@include transparentBG($secondary, 0.95);
    padding: 25px 25px 45px;
    //@include boxShadow;
    //@include transition($speed: 0.4s);
    //transform: translate(0, -50%);
  }
  
  h4 {
    @include headerFont;
    font-size: 2.8em;
    font-weight: 400;
    text-align: center;
    color: #fff;
    margin: 10px 0;
    text-transform: uppercase;
  }
  
  p {
    text-align: center;
    margin: 0 20px;
    color: #fff;
    font-size: 1.2em;
  }
  
  .buttonHolder {
    display: block;
    margin-top: 25px;
    text-align: center;
    
    .continue {
      @include button;
      font-size: 1.35em;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  
  &#timeoutMessage {
    .btnWrap {
      width: 90%;
      max-width: 600px;
      margin: 50px auto 20px;
      text-align: center;
      
      span {
        display: inline-block;
        margin-right: 15px;
        position: relative;
        background: $primary;
        padding: 10px 20px;
        color: text-contrast($primary);
        font-family: $primaryFont;
        font-size: 1.125em;
        text-align: center;
        
        @include hover {
          background: $primaryHover;
          color: text-contrast($primaryHover);
          opacity: 0.75;
        }
        
        &.stayBtn {
          background: $light;
          color: $basic;
          
          @include hover {
            opacity: 0.75;
          }
          
          em {
            &:before {
              content: '\61';
            }
          }
        }
        
        em {
          display: block;
          font-style: normal;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 1.25em;
          
          &:before {
            content: '\62';
            font-family: $iconFont;
            padding-right: 10px;
            text-transform: none;
          }
        }
      }
    }
    
    &.show {
      display: block;
      
      .message {
        top: 0;
      }
    }
  }
  
  &#menuInWarningMessage {
    &.show {
      display: block;
      
      .message {
        top: 50%;
        transform: translate(0, -50%);
      }
    }
    
    .btnWrap {
      margin-top: 15px;
      text-align: center;
      
      button {
        font-size: 1.2em;
        margin-bottom: 10px;
      }
      
      .stayHere {
        @include button;
      }
      
      .goHome {
        @include button($secondary, $secondaryHover);
      }
    }
  }
}

#printOrientation {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba($basic, 0.9);
  
  .dialog {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    display: block;
    width: 280px;
    height: 180px;
    padding: 15px;
    background: 50% url(/umbraco/assets/img/loader.gif) no-repeat;
    background-position: 50% 50%;
    background-color: $light;
    border-radius: 3px;
    transition: all 500ms;
    
    .printProgress {
      position: absolute;
      left: 15px;
      right: 15px;
      top: 15px;
      height: 5px;
      background-color: $basic;
      text-align: center;
      
      .bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 100%;
        background-color: $primary;
        transition: width 200ms;
      }
      
      .num {
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        text-align: center;
        color: $basic;
        font-family: $primaryFont;
        font-size: 0.9em;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
  
  button.cancel {
    @include button;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  iframe {
    display: none;
    width: 100%;
    height: 100%;
    border: 1px solid $dark;
    border-radius: 4px;
  }
  
  .printButtonHolder {
    display: none;
    justify-content: space-between;
    margin-bottom: 20px;
    text-align: center;
    
    button.print {
      @include button;
      font-size: 1.3em;
      margin: 0 2px;
    }
    
    button.close {
      @include button($secondary);
      font-size: 1.3em;
      margin: 0 2px;
    }
  }
}

#printOrientation.completed {
  .dialog {
    top: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 780px;
    height: 80vh;
    background: $light;
    
    .printProgress {
      display: none;
    }
    
    .printButtonHolder {
      display: flex;
    }
    
    iframe {
      display: block;
    }
    
    .cancel {
      display: none;
    }
  }
}

#staffNotice {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  color: $light;
  background: rgba($basic, 0.80);
  backdrop-filter: blur(10px);
  
  &.showing {
    display: flex;
  }
  
  .logoWrap {
    width: 360px;
    margin-bottom: 15px;
    text-align: center;
    
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
  
  .text {
    font-size: 1.2em;
    border-top: 3px solid #dddddd;
    border-bottom: 3px solid #dddddd;
    max-width: 500px;
    margin: 0 20px 70px;
    padding: 16px;
    
    p:last-child {
      margin-bottom: 0;
    }
    
    .buttonHolder {
      text-align: center;
    }
    
    button {
      @include button;
    }
  }
}

@media (max-width: $sm) {
  #printOrientation.completed {
    .dialog {
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
}


@media (max-width: $xs) {
  .popupMessage {
    h4 {
      font-size: 2em;
    }
    
    .message {
      top: 0 !important;
      padding: 5px 15px 25px;
      transform: translate(0, 0) !important;
    }
  }
  
  #printOrientation {
    .printButtonHolder {
      button {
        font-size: 1em;
      }
    }
  }
}

@media (max-width: $xxs) {
  .popupMessage#timeoutMessage {
    h4 {
      font-size: 1.8em;
    }
    
    .btnWrap {
      max-width: 300px;
      margin-top: 25px;
      
      span {
        display: block;
        margin: 5px 0;
      }
    }
  }
  
  #staffNotice {
    .logoWrap {
      width: 280px;
      margin-bottom: 15px;
    }
  }
}
