.errorPage {
  background: $basic;
  border: 2px solid $light;
  
  .basicButton {
    @include button($color:$primary);
    padding: 15px;
    margin: 0 0 20px;
    border-radius: 6px;
  }
  
  .errorWrapper {
    background-color: rgba($light, 0.8);
    padding: 35px;
  }
  
  .message {
    padding: 20px 0 0 30px !important;
  }
  
  .formArea {
    background: $basic;
    max-width: 95%;
    margin: 0 auto;
  }
  
  h2 {
    font-size: 1.5em !important;
    font-family: $primaryFont;
    //background: $primary;
    color: $light;
    padding-bottom: 5px;
  }
  
  h3 {
    color: $light;
  }
}

@media all and (max-width: $xs) {
  .message {
    text-align: center;
  }
}
