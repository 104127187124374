.videoSlide {
  
  .slideTitle {
    text-align: center;
  }
  
  .videoContainer {
    margin-bottom: 15px;
    
  }
  
  &.vidBelow {
    .content {
      max-width: 1200px;
      margin: 0 auto;
      display: block;
    }
    
    .slideSide {
      width: 100%;
      margin: 0 auto;
      padding-bottom: 25px;
      
      .tips {
        //display: none;
      }
    }
    
    .slideCopy {
      width: 100% !important;
      margin: 0 auto !important;
      float: none !important;
    }
  }
}

.videoWrapper {
  
  
  .loading {
    display: block;
    color: $grey;
    font-size: 1.2em;
    font-family: $primaryFont;
    text-align: center;
    padding-top: 40px;
    text-transform: uppercase;
  }
}

.autoplaySet {
  display: none;
}

//***4:3 RATIO VIDEOS***//
/*#videoHolder {
    position: relative;
    padding-bottom: 75%;//for 4:3 ratio videos
    padding-top: 0;
    height: 0;
    margin-bottom: 15px;

    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}*/

//***16:9 RATIO VIDEOS***//

/* .videoHolder,#videoHolder {
    position: relative;
    padding-bottom: 56%;//for 16:9 ratio videos
    padding-top: 0;
    height: 0;
    margin-bottom: 15px;
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
} */

.videoHolder, #videoHolder {
  position: relative;
  background: $dark url('/images/load-screen.jpg')no-repeat;
  background-size: cover;
  padding-bottom: 56.3%; //for 16:9 ratio videos
  padding-top: 0;
  height: 0;
  margin-bottom: 15px;
  //box-shadow: 0 0 20px rgba(0,0,0,0.6);
  
  .chrome &, .android & {
    &:before {
      display: none !important;
    }
  }
  
  &:before {
    content: '';
    background: $dark url('/images/load-screen.jpg')no-repeat;
    background-size: cover;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 9999;
  }
  
  &.hideLoad, .hideLoad & {
    background: none;
    
    &:before {
      display: none !important;
    }
  }
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

@media (max-width: $lg) {
  .videoSlide {
    .slideSide {
      width: 100%;
    }
    
    .videoWrapper {
      padding: 0;
      //max-width: 650px;
      margin: 0 auto;
    }
  }
}

@media all and (max-width: $md) {
  .videoHolder, #videoHolder {
    &:before {
      display: none;
    }
  }
}
