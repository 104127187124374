$utilityWidth: 30%;

.closeUtilityBtn {
  display: none !important;
}

.utilityBtn:not(.closeUtilityBtn) {
  position: fixed;
  color: $light;
  top: 38px;
  right: 45px;
  cursor: pointer;
  width: 45px;
  height: 45px;
  text-align: center;
  padding: 5px;
  @include transition();
  z-index: 12;
  font-size: .9em;
  border: 2px solid;
  border-image-source: $vertGradient;
  border-image-slice: 1;
  transition: .3s all;
  
  @include hover {
    border-image: none;
    background: $primary !important;
    color: $basic !important;
    
    @media (min-height:751px) and (min-width:$sm + 1) {
      .text {
        display: block;
      }
    }
  }
  
  .text {
    display: inline-block;
    padding: 5px;
    background: $primary;
    color: $basic;
    text-transform: uppercase;
    font-family: $primaryFont;
    font-weight: 600;
    font-size: 1.2em;
    position: absolute;
    right: 0;
    top: -35px;
    padding: 6px 10px 5px;
    font-size: .9em;
    width: auto;
    text-align: center;
    display: none;
    white-space: nowrap;
  }
  
  .icon {
    font-size: 2em;
    display: inline-block;
    line-height: 1.2em;
  }
  
  &.open {
    position: fixed;
    z-index: 250;
    top: 3px;
    background: $primary;
    color: text-contrast($primary);
    right: calc(30% + 5px);
    border: 2px solid $light;
    transition: .3s all;
    
    @include hover {
      border-color: $primary !important;
      background: $basic !important;
      color: $primary !important;
    }
    
    .icon {
      &:before {
        content: 'b';
      }
    }
    
    .text {
      display: none;
    }
  }
  
  @include hover {
    background: $primaryHover;
    color: text-contrast($primaryHover);
  }
  
  .inSection & {
    right: -70px;
  }
  
  .alertMode & {
    //top: 0;
  }
}

.utilityMenu {
  position: fixed;
  right: -$utilityWidth;
  width: $utilityWidth;
  top: 0;
  background: $light;
  height: 100%;
  overflow: auto;
  z-index: 231;
  @include transition();
  
  &.open {
    right: 0;
  }
  
  .menuContent {
    @include clearfix;
    border-bottom: 2px solid $grey;
    padding: 25px 0;
    
    h1 {
      font-weight: 300;
      text-transform: none;
      color: $basic;
      font-size: 2.4em;
    }
    
    &.UMinfo {
      padding: 15px;
      font-family: $primaryFont;
      border-bottom: 0;
      background: $primary;
      
      .UMtitle {
        font-weight: 300;
        text-transform: none;
        color: $basic;
        font-size: 2.4em;
      }
      
      .UMstudent {
        color: $basic;
        font-weight: 600;
      }
    }
    
    &.UMbtns {
      padding: 25px 15px;
      background: lighten($grey, 15%);
      
      .btn {
        margin-bottom: 5px !important;
      }
    }
    
    &.toc {
      margin-left: 0;
      padding-left: 0;
      background: $light;
      border-bottom: 0;
      
      h1 {
        padding-left: 15px;
        font-size: 2em;
      }
      
      .alphaToc {
        padding-left: 15px;
      }
    }
    
    .btn {
      @include button;
      display: block;
      margin: 0;
      display: inline-block;
      float: left;
      font-size: 1.1em;
      margin-right: 10px;
      text-align: center;
      cursor: pointer;
      text-decoration: none !important;
    }
    
    .certBtn {
      display: none;
      float: left;
      clear: both;
      margin-top: 10px;
      padding-left: 30px;
      background: text-contrast($primary) !important;
      border-color: $primary;
      color: $primary !important;
      position: relative;
      
      .icon-cert {
        @include absolute;
        top: 4px;
        left: 8px;
        font-size: 1.25em;
      }
      
      @include hover {
        background: $primaryHover;
        color: text-contrast($primaryHover);
        border-color: $light;
      }
    }
    
    .orientationComplete & {
      .certBtn {
        display: inline-block;
      }
    }
  }
}

//*****TOC******//
#sitemap {
  // font-size:1.25em;
  font-family: $primaryFont;
  
  ul {
    list-style: none;
    padding-left: 0;
    
    ul {
      li {
        position: relative;
        
        @include hover {
          .lockedMsg {
            display: block;
          }
          
          li {
            .lockedMsg {
              display: none;
            }
          }
        }
        
        &:active {
          .lockedMsg {
            display: block;
          }
          
          li {
            .lockedMsg {
              display: none;
            }
          }
        }
        
        &.level-4 {
          @include hover {
            .lockedMsg {
              display: none !important;
              z-index: 9999;
            }
          }
        }
      }
    }
  }
  
  .level-2 {
    @include zeroMargPad;
    
    &.available {
      .mainLevel {
        .available {
          display: none;
        }
      }
    }
    
    &.notAvailable {
      .hideUnavailableTopics & {
        display: none;
      }
      
      .mainLevel {
        .notAvailable {
          display: block;
          font-size: .85em;
          
          b {
            font-weight: normal;
          }
          
          &:before {
            content: "(";
          }
          
          &:after {
            content: ")";
          }
        }
      }
    }
    
    .tempType {
      display: none;
    }
    
    .mainLevel {
      font-size: 1.2em;
      color: $basic;
      background: $grey;
      padding: 5px 10px 5px 20px;
      cursor: default;
      font-weight: 600;
    }
    
    a {
      font-size: 1.2em;
      color: $light;
      background: $dark;
      padding: 5px 10px 5px 20px;
      cursor: default;
      font-weight: 600;
    }
  }
  
  .level-3 {
    &.selfDir {
      a {
        font-weight: bold;
      }
      
      .level-4-link {
        cursor: default !important;
        background: $primary;
        color: $basic;
        
        @include hover {
          cursor: default !important;
          background: $primary !important;
          color: text-contrast($primary) !important;
          
          &:before {
            display: none;
          }
        }
      }
    }
    
    &.last {
      a {
        border-bottom: none;
      }
    }
    
    a {
      text-transform: none;
      font-size: 1em;
      color: $basic;
      background: transparent;
      padding: 7px 10px 7px 20px;
      cursor: pointer;
      position: relative;
      text-decoration: none !important;
      border-bottom: 1px solid $grey;
      border-radius: 0;
      outline: 0;
      transition: .2s all;
      
      @include hover {
        background: $primary;
        color: $basic;
        
        &:before {
          font-family: $iconFont;
          content: 'm';
          @include absolute;
          left: 2px;
          top: 50%;
          margin-top: -10px;
        }
      }
    }
  }
  
  .level-4 {
    a {
      outline: 0;
    }
  }
  
  .level-4>a {
    display: none !important;
  }
  
  .level-5 {
    a {
      margin-left: 20px;
      padding: 10px 10px 10px 20px;
      //background:darken($light,30%);
      font-weight: normal !important;
      border: 0;
      border-bottom: 3px solid $grey !important;
      outline: 0;
    }
  }
  
  a {
    text-decoration: none !important;
    display: block;
    //@include transition;
  }
  
  .locked {
    a {
      cursor: default;
    }
    
    .level-3 {
      a {
        //@include opacity(50, .50);
        background: $dark;
        border-color: lighten($basic, 50%);
      }
    }
  }
  
  .lockedMsg {
    display: none;
    @include absolute;
    width: 100%;
    top: 0;
    background: $basic;
    color: $light;
    font-weight: 700;
    padding: 10px;
    text-align: center;
    font-size: 0.9em;
    z-index: 1;
    height: 100%;
    cursor: not-allowed;
    
    .available {
      margin-left: 5px;
    }
  }
}

@media (max-height:750px) {
  .utilityBtn:not(.closeUtilityBtn) {
    top: 18px;
    right: 15px;
  }
}

@media (max-width: $md + 100) {
  .utilityMenu {
    &.open {
      //width: 60%;
      //right: 60%;
    }
  }
}

@media (max-width: $sm) {
  .utilityBtn:not(.closeUtilityBtn) {
    position: absolute;
    top: 10px;
    right: 10px;
    
    .inSection & {
      display: none;
    }
    
    &.open {
      right: 5px;
      left: auto;
      top: 5px;
      height: 50px;
      width: 50px;
      padding-top: 2px;
      border: 0;
      background: $basic !important;
      color: $primary !important;
      
      .icon {
        padding-top: 6px;
      }
    }
  }
  
  .utilityMenu .menuContent.UMinfo {}
  
  .utilityMenu {
    &.open {
      width: 100%;
    }
  }
  
  .UMbtns {
    .adaMenuLink {
      width: initial;
      height: initial;
    }
  }
  
  #sitemap {
    .lockedMsg {
      font-size: 10px;
      padding: 10px 3px;
    }
  }
}

@media (max-width: $xs) {
  .utilityMenu .menuContent.UMinfo {
    padding-left: 20px;
  }
  
  .utilityMenu {
    .menuContent.UMinfo {
      .UMtitle {
        line-height: 1.125em;
      }
    }
  }
  
  .utilityBtn:not(.closeUtilityBtn) {
    
    
    &.open {
      height: 35px;
      width: 35px;
      padding-top: 0;
      
      .icon {
        padding-top: 2px;
      }
    }
    
    .icon {}
    
    .text {}
  }
}

@media (max-width: $xxs) {
  .utilityBtn:not(.closeUtilityBtn) {
    &.open {
      // top: auto;
      // bottom: 20px;
    }
  }
}
