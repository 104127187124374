#homeScreen {
  z-index: 3;
  padding: 0 !important;
  @include maxWidth;
  @include clearfix;
  
  .helpMode & {
    margin-top: 0 !important;
  }
  
  @media all and (max-width: $xl + 220) {
    margin-right: 85px;
    margin-left: 85px;
    margin-top: 30px !important;
  }
  
  @media all and (max-width: $lg) {
    margin-top: 20px !important;
  }
  
  @media all and (max-width: $md) {
    margin-right: 85px;
    margin-left: 85px;
  }
  
  @media all and (max-width: $sm) {
    margin: 15px 25px 115px !important;
  }
  
  @media all and (max-width: $xs) {
    margin: 0 0 100px !important;
  }
}
