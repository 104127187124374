.accordionButtonWrapper {
  border-left: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
  @include zeroMargPad;
  
  &:first-child {
    margin-top: 20px !important;
  }
  
  &.open {
    .accordionButton {
      cursor: pointer;
      color: $light;
      background: $basic;
      border-radius: 10px 10px 10px 0;
      margin-bottom: 0;
      
      &:before {
        text-transform: uppercase;
        content: 'b';
        color: $light;
        font-size: 1.2em;
        left: 10px;
        top: 7px;
      }
    }
  }
  
  p {
    border-left: 0 !important;
  }
}

.accordionButton {
  font-size: 1.3em;
  font-family: $primaryFont;
  background: $primary;
  font-weight: 700;
  outline: none !important;
  color: text-contrast($primary);
  padding: 10px 0 12px 50px;
  display: block;
  position: relative;
  margin-bottom: 5px;
  transition: .2s all;
  border-radius: 0 10px 10px 10px;
  
  @include hover {
    cursor: pointer;
    background: $basic;
    color: $light;
    
    &:before {
      color: $light;
    }
  }
  
  &:before {
    @include iconFont;
    position: absolute;
    left: 13px;
    top: 13px;
    text-transform: lowercase;
    content: 'q';
    font-size: 0.8em;
    color: text-contrast($primary);
    padding: 3px 10px 3px 0;
    transition: .2s all;
  }
}

.accordionWrapper {
  display: none;
  padding: 30px 20px 15px;
  margin-top: -10px;
  margin-bottom: 5px;
  background: lighten($grey, 15%);
  
  p:not(.termContent) {
    border-left: 0 !important;
  }
  
  p:last-child {
    margin-bottom: 0;
  }
  
  h3 {
    font-size: 1.15em;
    margin-bottom: 10px;
  }
}

@media (max-width: $xs) {
  .accordionButton {
    font-size: 1em;
    padding: 10px 0 12px 40px;
  }
  
  .accordionButtonWrapper {
    margin: 0 -20px 0 -20px !important;
  }
  
  .accordionWrapper {
    margin: 0 -20px 3px -20px !important;
  }
}
