.alertGradientTop {
  @include fillSpace;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url(/images/alert-bar-gradient.png) no-repeat 100% 0;
  //opacity: .65;
  transition: .5s;
  
  .inSection & {
    opacity: .85;
  }
}

// .alertGradientTop, .alertGradientRight {
//   @include fillSpace;
//   position: fixed;
//   top: 0;
//   right: 0;
//   width: 100%;
//   background: linear-gradient(180deg, #000 10%, transparent 49%);
//   opacity: .65;
//   transition: .5s;
//   display: none;

//   .inSection & {
//     opacity: 0;
//   }
// }

// .alertGradientRight {
//   background: linear-gradient(-90deg, $basic 10%, transparent 99%);
// }

//No Widget
.alertBarWidget {
  
  .icon-x, .alertOverlay {
    display: none;
  }
  
  .alertBar {
    position: fixed;
    width: 390px;
    height: 225px;
    right: 0;
    top: 0;
    z-index: 12;
    padding: 45px 50px 0 0;
    transition: bottom 500ms;
    
    .ie11 & {
      width: 290px;
      height: 180px;
    }
    
    &.inside {
      @media all and (max-width: $xs) {
        bottom: 0;
      }
    }
    
    .helpTour & {
      right: 0;
      
      .alert {
        width: 60px;
      }
    }
    
    .top {
      display: flex;
      justify-content: space-evenly;
      padding-right: 54px;
      width: 100%;
      
      .ie11 & {
        justify-content: space-between;
        margin-left: -65px;
      }
      
      .alertName {
        right: 0;
        top: -40px;
        padding: 5px 10px;
        font-size: .9em;
        width: auto;
        white-space: nowrap;
        text-align: center;
        
      }
      .item{
        width: 45px;
      }
    }
    
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-end;
      height: 100%;
      padding-right: 50px;
      
      .ie11 & {
        justify-content: space-between;
        margin-top: 30px;
      }
      
      .alertName {
        right: -5px;
        top: 15px;
        transform: translate(0, -50%);
        padding: 5px 10px;
        font-size: .9em;
        white-space: nowrap;
      }
      
      .item {}
    }
    
    
    .item {
      position: relative;
      padding: 10px 0 15px 0;
      margin-bottom: 5px;
      text-align: left;
      color: lighten($grey, 10%);
      background: transparent !important;
      transition: all 300ms;

      &.achievements {
        .Advisor & {
          display: none;
        }
      }
      
      &:hover {
        cursor: pointer;
        color: $primary;
        
        @media (min-height:751px) and (min-width:$sm + 1) {
          .alertName {
            display: block;
          }
        }
      }
      
      &.clicked {
        // border-bottom: 2px solid;
        // border-image-source: $horizGradient;
        // border-image-slice: 1;
        
        .icon {
          color: $primary !important;
        }
      }
      
      &:not(.clicked) {
        background: $secondary;
        
        &:hover {
          background: $primary;
        }
      }
      
      &.hideFeature {
        display: none;
      }
    }
    
    .icon {
      display: inline-block;
      position: absolute;
      top: 20%;
      left: 10px;
      width: 50px;
      font-size: 1.75em;
      text-align: center;
      //color: lighten($grey, 5%);
    }
    
    .ico-checkbox {
      font-size: 2em;
    }
    
    .alertName {
      display: inline-block;
      padding: 5px;
      background: $primary;
      color: $basic;
      text-transform: uppercase;
      font-family: $primaryFont;
      font-weight: 600;
      font-size: 1.2em;
      position: absolute;
      display: none;
    }
    
    .notify {
      position: absolute;
      top: 33px;
      left: 24px;
      padding: 1px 6px;
      color: $light;
      //background: $primary;
      border: 1px solid $primary;
      text-align: center;
      font-size: 0.9em;
      font-family: $basicFont;
      font-weight: 600;
    }
  }
}

.alertBtn {
  display: none;
}
