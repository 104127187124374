#achievements {
  .alertInstruction {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    
    >* {
      max-width: 100%;
    }
    
    h2 {
      padding: 0;
    }
  }
  
  .alertListCopy {
    display: flex;
    flex-wrap: wrap;
    
    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 30px;
      row-gap: 25px;
    }
  }
  
  .achievement {
    width: 250px;
    margin: 10px;
    
    @supports (display: grid) {
      width: auto;
      margin: 0;
    }
    
    padding: 10px;
    border: 1px solid $grey;
    
    .iconWrap {
      position: relative;
      height: 90px;
      width: 90px;
      margin: 0 auto 10px;
      
      &:before {
        content: "c";
        font-family: $iconFont;
        font-size: 42px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 4px));
        color: $basic;
      }
      
      .achievementIcon {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
    
    .achievementTitle {
      text-align: center;
      font-weight: 500;
      color: $basic;
      font-size: 1.45em;
      line-height: 1.1em;
      margin-bottom: 5px;
    }
    
    .achievementDescription {
      display: block;
      font-size: 1em;
      text-align: center;
      margin-bottom: 5px;
    }
    
    .achievementProgressCompleted, .achievementProgressTrack {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      
      circle {
        transition: 0.5s stroke-dashoffset;
        /* axis compensation */
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        stroke-width: 3;
      }
      
      .progress-ring__circle {
        stroke: $basic;
        stroke-dasharray: 270.177px, 270.177px;
        stroke-dashoffset: 270.177px;
      }
      
      .track-ring__circle {
        stroke: $grey;
      }
    }
    
    .achievementProgressCompleted {
      z-index: 5;
    }
  }
  
  .achievement.completed {
    .iconWrap {
      &:before {
        @keyframes hideAchievementLock {
          from {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
          }
          
          to {
            transform: translate(-50%, -50%) scale(0.1);
            opacity: 0;
          }
        }
        
        animation: hideAchievementLock 350ms;
        animation-delay: 1000ms;
        animation-fill-mode: both;
      }
      
      .achievementIcon {
        display: block;
        
        @keyframes showAchievementIcon {
          0% {
            transform: translate(-50%, -50%) scale(0.1);
            opacity: 0;
          }
          
          20% {
            opacity: 1;
          }
          
          100% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
          }
        }
        
        animation: showAchievementIcon 350ms;
        animation-delay: 1350ms;
        animation-fill-mode: both;
      }
    }
    
    .achievementProgressCompleted, .achievementProgressTrack {
      @keyframes hideAchievementProgress {
        from {
          opacity: 1;
        }
        
        to {
          opacity: 0;
        }
      }
      
      animation: hideAchievementProgress 350ms;
      animation-delay: 1000ms;
      animation-fill-mode: backwards;
      opacity: 0;
    }
  }
  
  .overallWrapper {
    .overall {
      position: relative;
      height: 24px;
      width: 100%;
      margin-top: 15px;
      background: $grey;
      border-radius: 5px;
      overflow: hidden;
      
      .overallProgression {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        width: 0;
        padding-right: 5px;
        font-weight: bold;
        font-size: 0.75em;
        background: $primary;
        transition: width 700ms;
        color: $basic;
      }
    }
    
    .iconWrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 180px;
      width: 180px;
      min-width: 180px;
      margin: 0 auto 10px;
      background: $light;
      border-radius: 6px;
      
      img {
        display: none;
        max-width: 140px;
        max-height: 140px;
      }
      
      &:before {
        content: "c";
        font-family: $iconFont;
        font-size: 64px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $basic;
      }
    }
    
    .instruction {
      display: block;
    }
    
    .congratulations {
      display: none;
    }
  }
  
  .overallWrapper.completed {
    .iconWrap {
      &:before {
        display: none;
      }
      
      img {
        display: block;
        animation: showOverallIcon 500ms;
        animation-fill-mode: backwards;
        animation-delay: 1300ms;
        
        @keyframes showOverallIcon {
          0% {
            transform: scale(0.1);
            opacity: 0;
          }
          
          20% {
            opacity: 1;
          }
          
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    }
    
    .instruction {
      display: none;
    }
    
    .congratulations {
      display: block;
    }
    
    .overall {
      animation: hideOverallProgression 250ms;
      animation-fill-mode: both;
      animation-delay: 1600ms;
      
      @keyframes hideOverallProgression {
        0% {
          opacity: 1;
        }
        
        100% {
          opacity: 0;
        }
      }
    }
  }
  
  @supports (display: grid) {
    @media (max-width: $lg) {
      .alertListCopy {
        grid-template-columns: 1fr 1fr;
      }
    }
    
    @media (max-width: $md) {
      .alertListCopy {
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 20px;
        row-gap: 15px;
      }
    }
    
    @media (max-width: $sm) {
      .alertListCopy {
        grid-template-columns: 1fr 1fr;
      }
      
      .overallWrapper {
        .iconWrap {
          height: 100px;
          width: 100px;
          min-width: 100px;
          
          img {
            display: none;
            max-width: 70px;
            max-height: 70px;
          }
        }
      }
    }
    
    @media (max-width: $xxs) {
      .achievement {
        .achievementDescription {
          font-size: 0.85em;
        }
      }
      
      .alertListCopy {
        row-gap: 5px;
        column-gap: 5px;
        grid-template-columns: 1fr;
      }
    }
  }
}





$notificationBgColor: $primary;
$notificationTextColor: text-contrast($primary);

/*@if (lightness($notificationBgColor) > 60) {
    $notificationTextColor: #000;
}*/

.achievementNotification {
  position: fixed;
  top: 20px;
  left: -1px;
  transform: translateX(-100%);
  z-index: 9999;
  display: flex;
  align-items: center;
  width: 330px;
  height: 100px;
  padding: 10px;
  background-color: $notificationBgColor;
  transition: all 300ms;
  pointer-events: none;
  
  // The hover is here for a nice usibility option.
  // Even when the .showing timer expires in the javascript, as the long as the
  // user is hovering over it, they'll get to keep it around! Nice if they're
  // not done reading.
  // Also better than having an X button, because IMO the act of dismissing it
  // should be passive, so as not to be annoying, but the user can be active
  // to keep it around.
  &.showing {
    pointer-events: auto;
    transform: translateX(0);
    left: 20px;
  }
  
  @include hover {
    pointer-events: auto;
    transform: translateX(0);
    left: 20px;
  }
  
  .iconWrap {
    position: relative;
    height: 100%;
    min-width: 80px;
    max-width: 80px;
    padding: 10px;
    border-radius: 3px;
    background-color: $light;
    
    .achievementIcon {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
  
  .textWrap {
    margin-left: 15px;
    color: $notificationTextColor;
    
    .achievementTitle {
      font-weight: 500;
      font-size: 1.3em;
      margin-bottom: 4px;
    }
    
    .achievementDescription {
      display: block;
      line-height: 1.1em;
      margin: 0;
    }
  }
  
  @media (max-width: $xxs) {
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-100%);
    
    &.showing {
      left: 0;
      transform: translateY(0);
    }
    
    @include hover {
      left: 0;
      transform: translateY(0);
    }
  }
}
