$ansHeight: 400px;
$ansWidth: 700px;

@mixin halfBG($color) {
    @include pseudoContent;
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    background: darken($color, 5%);
}

.multiQuizSlide {
    .multiQuizCopy {
        float: none;
        width: 100%;
        padding: 0;
    }

    .slideInstructions {
        @include absolute;
        @include fillSpace;
        overflow: hidden;
        z-index: 10;

        &.viewed {
            .inner {
                top: -30px;
                opacity: 0;
            }
        }

        .inner {
            position: relative;
            top: 45%;
            transform: scale(1) translateY(-55%);
            max-width: 550px;
            margin: 0 auto;
            background: $secondary;
            color: text-contrast($secondary);
            padding: 50px;
            opacity: 1;
            @include transition(.55s);
            -webkit-box-shadow: 5px 5px 1px 1px rgba(0, 0, 0, 0.5);
            box-shadow: 5px 5px 1px 1px rgba(0, 0, 0, 0.5);

            &:after {
                @include halfBG($secondary);
            }

            &:before {
                @include pseudoContent;
                border: 2px solid $light;
                @include absolute;
                width: 95%;
                height: 90%;
                left: 2.5%;
                top: 5%;
                z-index: 1;
            }

            &.loading {
                transform: scale(0) translateY(-55%);
            }
        }

        h2 {
            font-size: 1.8em;
            color: text-contrast($secondary);
            font-family: $primaryFont;
            text-transform: uppercase;
            font-weight: 700;
            @include zeroMargPad;
            text-align: center;
            padding: 0 0 20px;
        }

        p {
            font-size: 1.1em;
            line-height: 1.6em;
        }

        .copy {
            position: relative;
            z-index: 2;
        }

        .startBtn {
            margin-top: 20px;

            span {
                font-family: $primaryFont;
                display: inline-block;
                text-transform: uppercase;
                font-weight: 700;
                padding: 10px 25px;
                font-size: 1.3em;
                background: $primary;
                color: text-contrast($primary);
                cursor: pointer;

                @include hover {
                    background: $primaryHover;
                    color: text-contrast($primaryHover);
                }
            }
        }
    }

    .quizQuestionsHolder {
        @include fillSpace;
        position: relative;
        @include transition(transform, .5s);
    }

    .quizQuestion {
        @include absolute;
        @include fillSpace;

        &.active {
            z-index: 4;

            .buttonHolder {
                top: 25px;

                &.mqButton1 {
                    left: 0;
                    transform: rotate(0deg);
                }

                &.mqButton2 {
                    right: 0;
                    transform: rotate(0deg);
                }
            }

            .question {
                opacity: 1;
            }

            .quizAnswers {
                transform: scale(1);
            }
        }

        .question {
            text-align: center;
            color: $primary;
            font-size: 1.5em;
            padding-bottom: 15px;
            margin-bottom: 0;
            font-family: $primaryFont;
            font-weight: 700;
            opacity: 0;
            @include transition(opacity, .3s);
        }

        .resp {
            display: none;
        }

        &.answered {
            .buttonHolder {
                &:not(.picked) {
                    transform: scale(.8);
                }

                &.correct {
                    .front {
                        &:before {
                            @include transparentBG($correct, .8);
                        }

                        &:after {
                            content: 'a';
                        }
                    }
                }

                .front {
                    &:before {
                        @include pseudoContent;
                        @include absolute;
                        @include transparentBG($wrong, .8);
                        @include fillSpace;
                    }

                    &:after {
                        @include iconFont;
                        font-size: 10em;
                        content: 'b';
                        color: $light;
                        @include vertCenter;
                        position: absolute;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }

    .quizAnswers {
        max-width: $ansWidth;
        width: 100%;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        height: $ansHeight;
        @include transition(transform, .3s);
        transform: scale(.75);
    }

    .ansText {
        font-family: $primaryFont;
        text-align: center;
        color: darken($grey, 25%);
        font-weight: 700;
        font-size: 1.1em;
        text-transform: uppercase;
        margin-top: 5px;
    }

    .buttonHolder {
        perspective: 1000px;
        width: 48%;
        position: absolute;
        height: $ansHeight - 50px;
        top: 0;
        @include transition(.3s);

        @include hover {
            top: 20px;
        }

        &.picked {
            .flipper {
                transform: rotateY(0deg);
            }

            .flipper .front {
                transform: rotateY(180deg);
            }

            .flipper .back {
                transform: rotateY(0deg);
                height: auto;
            }
        }

        &.correct {
            .back {
                background: $correct;

                &:after {
                    background: darken($correct, 5%);
                }
            }
        }

        &.mqButton1 {
            left: -60%;
            transform: rotate(-4deg);
        }

        &.mqButton2 {
            right: -60%;
            transform: rotate(4deg);
        }

        .flipper {
            transition: 0.6s;
            transform-style: preserve-3d;
            position: relative;
            @include fillSpace;
        }

        .tile {
            @include fillSpace();
            @include absolute;
            backface-visibility: hidden;
        }

        .front {
            z-index: 2;
            transform: rotateY(0deg);
            cursor: pointer;
        }

        .letter {
            display: none;
        }

        .questImage {
            @include backgroundCover;
            @include fillSpace;
            @include transition($speed: .5s);
        }

        .back {
            transform: rotateY(180deg);
            background: $wrong;
            padding: 10%;
            color: $light;

            &:before {
                @include pseudoContent;
                border: 2px solid $light;
                @include absolute;
                width: 90%;
                height: 90%;
                left: 5%;
                top: 5%;
                z-index: 1;
            }

            &:after {
                @include halfBG($wrong);
            }

            .responseHolder {
                position: relative;
                z-index: 2;
            }

            h3 {
                color: $light;
                text-transform: uppercase;
                font-size: 1.8em;
                margin-top: 0;
                padding: 0;
                padding-bottom: 20px;
                font-weight: 700;
            }

            p {
                font-size: 1.05em;
                line-height: 1.5em;
            }
        }

        .btn {
            position: relative;
            z-index: 2;
            background: $secondary;
            color: text-contrast($secondary);
            cursor: pointer;
            text-transform: uppercase;
            padding: 7px 15px;
            font-size: 1.1em;
            margin-top: 20px;
            display: inline-block;

            @include hover {
                background: $secondaryHover;
                color: text-contrast($secondaryHover);
            }
        }
    }

    .quizPointsHolder {
        text-align: center;
        max-width: $ansWidth + 100;
        width: 100%;
        margin: 10px auto;
        position: relative;
        padding: 10px 0 8px;
        transform: scale(0);
        @include transition(.75s);

        &.in {
            transform: scale(1);
        }

        .point {
            position: relative;
            line-height: 1em;
            margin: 0 2px;
            height: 50px;
            width: 50px;
            display: inline-block;
            background: $light;
            @include rounded(50%);
            color: #b2b6bf;
            opacity: .85;
            transform: scale(.8);
            @include transition();

            &.correct {
                background: #b6a154 !important;
                color: $light !important;
                animation: answeredRight 1s;
                opacity: 1;
                transform: scale(1);

                .icon {
                    text-shadow: none;
                }
            }


            .icon {
                font-size: 2.1em;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                position: absolute;
            }
        }

        h2 {
            font-size: 2.1em;
            color: $primary;
            text-transform: uppercase;
            padding-bottom: 20px;

            .lg {
                display: block;
                font-weight: 700;
                font-size: 1.4em;
                line-height: 1em;
            }
        }

        .num {
            display: inline-block;
            font-size: 1.2em;
            font-weight: 700;
        }

        .msg {
            font-size: 1.3em;
            font-family: $secondaryFont;
            background: $secondary;
            color: text-contrast($secondary);
            display: inline-block;
            padding: 10px 15px;
            display: none;
            -webkit-box-shadow: 5px 5px 1px 1px rgba(0, 0, 0, 0.5);
            box-shadow: 5px 5px 1px 1px rgba(0, 0, 0, 0.5);
            margin-top: -20px;

            p {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        .reveal {
            transform: scale(0);
        }
    }

    .multiQuizCopy {
        @include transition(.5s);
        height: 100%;
    }

    &.quizFinished {
        .quizQuestionsHolder {
            transform: scale(0);
        }

        .multiQuizCopy {
        }

        .quizPointsHolder {
            margin-top: -350px;

            .point {
                height: 65px;
                width: 65px;

                .icon {
                    font-size: 2.6em;
                }
            }

            .msg.finished {
                display: inline-block;
            }

            &.show {
                .scoreMsg {
                    line-height: 1.5em;
                    transform: scale(1);
                    animation: animateScoreMsg 1s;
                }

                .msg {
                    animation: animateScore 2s;
                    transform: scale(1);
                    margin-top: 0;
                }

                .num {
                }
            }
        }

        &.quizFinishedReturning {
            .msg.finished {
                display: none;
            }

            .msg.finishedReturn {
                display: inline-block;
            }
        }
    }
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .multiQuizSlide .buttonHolder.picked {
        &.picked {
            .flipper {
                transform: rotateY(180deg);
            }

            .flipper .front {
                transform: rotateY(0deg);
            }

            .flipper .back {
                transform: rotateY(180deg);
            }
        }
    }
}

@media (max-width: $sm) {
    .multiQuizSlide {
        .buttonHolder {
            height: 300px;

            .back {
                h3 {
                    font-size: 1.35em;
                    padding-bottom: 5px;
                }

                p {
                    font-size: 1em;
                }
            }
        }

        .quizAnswers {
            height: 360px;
        }

        .quizQuestion {
            .question {
                padding-bottom: 5px;
            }
        }
    }
}

@media (max-width: $xs + 50) {
    .multiQuizSlide {
        .slideInstructions {
            h2 {
                font-size: 1.5em;
                padding-bottom: 10px;
            }

            .inner {
                top: 35%;
                width: 90%;
                padding: 35px;
            }
        }
    }
}

@media (max-width: $xs) {
    .multiQuizSlide {
        .content {
            padding: 20px 8px !important;
        }

        .buttonHolder {
            height: 240px;
            width: 49%;

            .btn {
                font-size: 1em;
                margin-top: 0;
                padding: 7px;
            }

            .back {
                p {
                    font-size: .9em;
                    line-height: 1.35em;
                }
            }
        }

        .quizAnswers {
            height: 300px;
        }

        .quizQuestion {
            .question {
                font-size: 1.25em;
            }

            &.active {
                .buttonHolder.picked {
                    width: 60%;
                    z-index: 100;
                }
            }
        }
    }

    .multiQuizSlide.quizFinished .quizPointsHolder {
        .point {
            height: 50px;
            width: 50px;

            .icon {
                font-size: 2.1em;
            }
        }

        h2 {
            font-size: 2em;
        }
    }
}


@media (max-width: $xxs) {
    .multiQuizSlide {
        .slideInstructions {
            h2 {
                font-size: 1.2em;
                padding-bottom: 10px;
            }

            .inner {
                top: 30%;
                width: 95%;
                padding: 25px;
            }
        }
    }
}
