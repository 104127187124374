/**************EXPAND STYLES*******************/
.revealSlide.Expand {
  .revealContentHolder {
    display: flex;
    flex-direction: column;
    
    &.TitleOnly {
      .revealItemHolder {
        @include hover {
          transform: scale(0.93);
        }
      }
      
      .topicImageHolder {
        display: none;
      }
      
      .copyWrap {
        .topicTitle {
          display: none;
        }
      }
    }
    
    &.ImageOnly {
      .revealItemHolder {
        &.open {
          .topicImageHolder {
            background: $secondary;
          }
          
          .topicImage {
            opacity: .15;
            filter: grayscale(1) blur(4px);
          }
          
          .titleWrap {
            @include transparentBG($primaryHover, .8);
            color: text-contrast($primaryHover);
          }
          
          .revealContent {
            opacity: 1;
          }
        }
        
        .revealContentWrap {
          height: 100%;
          overflow: hidden;
          overflow-x: hidden;
          overflow-y: auto;
          @include scrollbar($color: $primary);
          padding-bottom: 10px;
        }
      }
      
      .titleWrap {
        display: none;
      }
      
      .topicImageHolder {
        @include absolute;
        @include fillSpace;
        background-color: $primary;
        @include transition(.3s);
        
        .topicImage {
          @include absolute;
          @include fillSpace;
          @include transition(.3s);
          @include backgroundCover;
        }
        
        img {
          display: none;
        }
      }
      
      .revealContentWrap {
        @include absolute;
        height: 100%;
        
        .revealContent {
          background: transparent;
          height: 100%;
          opacity: 0;
          transition: opacity .4s ease-in 1s;
        }
      }
    }
    
    &.ImageAndTitle {
      .revealItemHolder {
        &.open {
          .topicImageHolder {
            background: $secondary;
          }
          
          .topicImage {
            opacity: .15;
            filter: grayscale(1) blur(4px);
          }
          
          .revealContent {
            opacity: 1;
          }
        }
        
        .revealContentWrap {
          height: 100%;
          overflow: hidden;
          overflow-x: hidden;
          overflow-y: auto;
          @include scrollbar($color: $primary);
          padding-bottom: 10px;
        }
      }
      
      .titleWrap {
        display: none;
      }
      
      .topicImageHolder {
        @include absolute;
        @include fillSpace;
        background-color: $primary;
        @include transition(.3s);
        
        .topicImage {
          @include absolute;
          @include fillSpace;
          @include transition(.3s);
          @include backgroundCover;
        }
        
        img {
          display: none;
        }
      }
      
      .revealContentWrap {
        @include absolute;
        height: 100%;
        
        .revealContent {
          background: transparent;
          height: 100%;
          opacity: 0;
          transition: opacity .4s ease-in 1s;
        }
      }
    }
  }
  
  .revealItemHolder {
    margin-bottom: 8px;
    @include transition(.3s);
    @include useGPU;
  }
  
  .revealBtnWrap {
    .revealBtn {
      background: $primary;
      color: text-contrast($primary);
      padding: 10px;
      @include transition(.3s);
      
      @include hover {
        background: $primaryHover;
        color: text-contrast($primaryHover);
      }
      
      h2 {
        @include zeroMargPad;
        font-weight: 400;
        font-size: 1.5em;
      }
    }
    
    &.clicked {
      .revealBtn {
        background: $primaryHover;
        color: text-contrast($primaryHover);
      }
    }
  }
  
  .revealContentWrap {
    display: none;
    
    .revealContent {
      background: $secondary;
      color: text-contrast($secondary);
      padding: 15px;
      
      a {
        color: $primary;
        
        &:hover {
          text-decoration: none;
          color: $basic;
        }
      }
    }
  }
  
  &.noImg {
    .content {
      display: block;
      max-width: 2500px;
      width: 100%;
    }
    
    .revealContentHolder {
      padding: 0 15px;
    }
  }
  
  &.Column_Format {
    .revealContentHolder {
      flex-direction: column;
    }
    
    .revealContentWrap {
      padding: 0;
    }
    
    .revealBtnWrap {
      padding: 7px;
    }
    
    .revealItemHolder {
      transform: scale(.9);
      border: 3px solid $primary;
      
      &.open {
        transform: scale(1);
        border-color: $secondary;
      }
    }
    
    @media (max-width: $md) {
      .revealCopy {
        .smTip {
          display: none;
        }
      }
      
      .slideSide {
        .imgHolder, .imgWrap {
          display: none;
        }
        
        .tips {
          display: block;
        }
      }
    }
    
    .ImageOnly {
      .revealItemHolder {
        width: 100%;
        height: auto;
        max-height: 2000px;
        transform: scale(.95);
        border-color: $light;
        
        &.open {
          height: 100%;
          
          .inner {
            height: auto;
            position: relative;
          }
          
          .topicImageHolder {
            background: $secondary;
          }
          
          .topicImage {
            filter: grayscale(.6) blur(4px);
            opacity: .15;
            background-attachment: fixed;
          }
          
          .revealContent {
            padding: 0;
          }
          
          .revealBtnWrap {
            height: auto;
            padding: 0;
            min-height: 1px;
          }
          
          .titleWrap {
            opacity: 1;
            top: 0;
            background: $primaryHover;
            
            h2 {
              &:before {
                transform: rotate(45deg);
              }
            }
          }
        }
        
        .inner {
          width: 100%;
        }
        
        .revealBtnWrap {
          padding: 0 0 20px 0;
          min-height: 100px;
          
          @include hover {
            .titleWrap {
              background: $primaryHover;
              opacity: 1;
            }
          }
          
          .revealBtn {
            padding: 0;
          }
        }
        
        .topicImageHolder {
          position: absolute;
          z-index: 1;
          height: 100%;
        }
      }
      
      .revealContentWrap {
        padding-top: 0;
        width: 100%;
        overflow: auto;
        height: auto;
        position: relative;
        z-index: 10;
        
        .copyWrap {
          font-size: 1.1em;
          color: $light !important;
          max-width: 700px;
          margin: 0 auto;
          width: 100%;
          padding: 15px;
        }
        
        .revealContent {
          padding-top: 10px;
        }
      }
      
      .titleWrap {
        display: inline-block;
        background: $primary;
        padding: 5px 15px 7px 10px;
        @include absolute;
        position: relative;
        z-index: 20;
        @include transition(.3s);
        opacity: 0;
        
        h2 {
          color: text-contrast($primary);
          font-weight: 700;
          padding-left: 20px;
          font-size: 1.1em;
          
          &:before {
            @include iconFont;
            content: "\71";
            display: inline-block;
            margin-right: 5px;
            position: absolute;
            left: 8px;
            top: 10px;
            font-size: .9em;
            transition: transform .2s;
            transform-origin: center center;
          }
        }
      }
      
      .copyWrap {
        .topicTitle {
          display: none;
        }
      }
      
      .imgHolder {
        &:before {
          @include pseudoContent;
          @include absolute;
          @include fillSpace;
        }
      }
      
      .revealBtnWrap {
        .revealBtn {}
      }
      
      @media (max-width: $xs) {
        .revealItemHolder {
          
          .titleWrap {
            h2 {
              font-size: 1.05em;
              line-height: 1.25em;
            }
          }
        }
      }
    }
    
    .ImageAndTitle {
      .revealItemHolder {
        width: 100%;
        height: auto;
        max-height: 2000px;
        transform: scale(.95);
        
        &.open {
          height: 100%;
          
          .inner {
            height: auto;
            position: relative;
          }
          
          .topicImageHolder {
            background: $secondary;
          }
          
          .topicImage {
            filter: grayscale(.6) blur(4px);
            opacity: .15;
            background-attachment: fixed;
          }
          
          .revealContent {
            padding: 0;
          }
          
          .revealBtnWrap {
            height: auto;
            padding: 0;
            min-height: 1px;
          }
          
          .titleWrap {
            opacity: 1;
            top: 0;
            background: $primaryHover;
            
            h2 {
              &:before {
                transform: rotate(45deg);
              }
            }
          }
        }
        
        .inner {
          width: 100%;
        }
        
        .revealBtnWrap {
          padding: 0 0 20px 0;
          min-height: 100px;
          
          @include hover {
            .titleWrap {
              background: $primaryHover;
              opacity: 1;
            }
          }
          
          .revealBtn {
            padding: 0;
          }
        }
        
        .topicImageHolder {
          position: absolute;
          z-index: 1;
          height: 100%;
        }
      }
      
      .revealContentWrap {
        padding-top: 0;
        width: 100%;
        overflow: auto;
        height: auto;
        position: relative;
        z-index: 10;
        
        .copyWrap {
          font-size: 1.1em;
          color: $light !important;
          max-width: 700px;
          margin: 0 auto;
          width: 100%;
          padding: 15px;
        }
        
        .revealContent {
          padding-top: 10px;
        }
      }
      
      .titleWrap {
        display: inline-block;
        background: $primary;
        padding: 5px 15px 7px 10px;
        @include absolute;
        position: relative;
        z-index: 20;
        @include transition(.3s);
        
        h2 {
          color: text-contrast($primary);
          font-weight: 700;
          padding-left: 20px;
          font-size: 1.1em;
          
          &:before {
            @include iconFont;
            content: "\71";
            display: inline-block;
            margin-right: 5px;
            position: absolute;
            left: 8px;
            top: 10px;
            font-size: .9em;
            transition: transform .2s;
            transform-origin: center center;
          }
        }
      }
      
      .copyWrap {
        .topicTitle {
          display: none;
        }
      }
      
      .imgHolder {
        &:before {
          @include pseudoContent;
          @include absolute;
          @include fillSpace;
        }
      }
      
      .revealBtnWrap {
        .revealBtn {}
      }
      
      @media (max-width: $xs) {
        .revealItemHolder {
          
          .titleWrap {
            h2 {
              font-size: 1.05em;
              line-height: 1.25em;
            }
          }
        }
      }
    }
  }
  
  &.Grid_Format {
    &.noImg {
      .content {
        .slideSide {
          display: block;
          margin: 0 auto;
          
          .imgWrap {
            display: none;
          }
          
          .tips {
            display: block;
            opacity: 1;
          }
        }
      }
      
      .revealCopy {
        display: block;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        padding-left: 0;
        padding-bottom: 20px;
        
        .slideCopyHolder {
          width: 100%;
          padding: 0;
          max-width: 960px;
          width: 100%;
          padding: 0 15px;
          margin: 0 auto;
          
          .smTip {
            display: none;
            opacity: 0;
          }
        }
        
        .revealContentHolder {
          width: 100%;
          padding-left: 0;
          max-width: 940px;
          margin: 0 auto;
        }
      }
    }
    
    .revealContentHolder {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      
      &.TitleOnly {
        .revealItemHolder {
          margin: 10px 0 0 1%;
          flex-grow: 1;
          width: calc(98% * (1/3) - 10px - 1px);
        }
      }
      
      &.ImageOnly {
        justify-content: center;
        
        @media (max-width: $md) {
          .revealItemHolder {
            border-width: 5px;
          }
        }
        
        &[data-qty="2"], &[data-qty="4"] {
          .revealItemHolder {
            margin: 10px 0 0 1%;
            width: calc(98% * (1/2) - 10px - 1px);
            
            @media (max-width: $xxs) {
              margin: 0 auto 10px;
              width: 100%;
            }
          }
        }
        
        .titleWrap {
          opacity: 0;
        }
        
        .revealItemHolder {
          margin: 10px 0 0 1%;
          flex-grow: 1;
          width: calc(98% * (1/3) - 10px - 1px);
          height: 250px;
          max-height: 2000px;
          
          @include hover {
            .titleWrap {
              opacity: 1;
            }
          }
          
          @media (max-width: $sm) {
            height: 200px;
            margin: 10px 0 0 1%;
            width: calc(98% * (1/2) - 10px - 1px);
          }
          
          @media (max-width: $xxs) {
            height: 125px;
            margin: 0 auto 10px;
            width: 100%;
            
            &.open {
              height: auto !important;
            }
          }
          
          .inner {
            width: 100%;
            
            @include hover {
              .titleWrap {
                @include transparentBG($primaryHover, .8);
                color: text-contrast($primaryHover);
              }
            }
          }
          
          .revealBtnWrap {
            min-height: 250px;
            padding: 0 0 20px 0;
          }
          
          .copyWrap {
            opacity: 0;
          }
          
          .topicTitle {
            display: none;
          }
          
          .topicImageHolder {
            position: absolute;
            z-index: 1;
            height: 100%;
          }
          
          &.open {
            height: auto;
            border-width: 5px;
            
            .inner {
              height: auto;
              position: relative;
            }
            
            .titleWrap {
              background: $primaryHover;
              color: text-contrast($primaryHover);
              opacity: 1;
            }
            
            .topicImage {
              filter: grayscale(.5) blur(4px);
              opacity: .25;
              background-attachment: fixed;
            }
            
            .revealContentWrap {
              .copyWrap {
                opacity: 1;
              }
            }
            
            .revealBtnWrap {
              height: auto;
              padding: 0;
              min-height: 1px;
            }
            
            .revealContent {}
          }
          
          .titleWrap {
            display: block;
            text-align: center;
            position: relative;
            @include transition();
            top: 0;
            left: 0;
            z-index: 20;
            width: 100%;
            
            h2 {
              font-weight: 700;
              padding: 5px;
              font-size: 1.1em;
            }
          }
        }
        
        .revealContentWrap {
          padding-top: 0;
          width: 100%;
          overflow: auto;
          height: auto;
          position: relative;
          z-index: 10;
          
          .copyWrap {
            margin: 0 auto;
            width: 100%;
            opacity: 0;
          }
        }
      }
      
      &.ImageAndTitle {
        justify-content: center;
        
        @media (max-width: $md) {
          .revealItemHolder {
            border-width: 5px;
          }
        }
        
        &[data-qty="2"], &[data-qty="4"] {
          .revealItemHolder {
            margin: 10px 0 0 1%;
            width: calc(98% * (1/2) - 10px - 1px);
            
            @media (max-width: $xxs) {
              margin: 0 auto 10px;
              width: 100%;
            }
          }
        }
        
        .revealItemHolder {
          margin: 10px 0 0 1%;
          flex-grow: 1;
          width: calc(98% * (1/3) - 10px - 1px);
          height: 250px;
          max-height: 2000px;
          
          @media (max-width: $sm) {
            height: 200px;
            margin: 10px 0 0 1%;
            width: calc(98% * (1/2) - 10px - 1px);
          }
          
          @media (max-width: $xxs) {
            height: 125px;
            margin: 0 auto 10px;
            width: 100%;
            
            &.open {
              height: auto !important;
            }
          }
          
          .titleWrap {
            display: block;
            text-align: center;
            position: relative;
            @include transition();
            top: 0;
            left: 0;
            z-index: 20;
            width: 100%;
            @include transparentBG($basic, .8);
            color: $light;
            
            h2 {
              font-weight: 700;
              padding: 5px;
              font-size: 1.1em;
            }
          }
          
          .inner {
            width: 100%;
            
            @include hover {
              .titleWrap {
                background: $primaryHover;
                color: text-contrast($primaryHover);
              }
            }
          }
          
          .revealBtnWrap {
            min-height: 250px;
            padding: 0 0 20px 0;
          }
          
          .copyWrap {
            opacity: 0;
          }
          
          .topicTitle {
            display: none;
          }
          
          .topicImageHolder {
            position: absolute;
            z-index: 1;
            height: 100%;
          }
          
          &.open {
            height: auto;
            border-width: 5px;
            
            .inner {
              height: auto;
              position: relative;
            }
            
            .titleWrap {
              background: $primaryHover;
              color: text-contrast($primaryHover);
              opacity: 1;
            }
            
            .topicImage {
              filter: grayscale(.5) blur(4px);
              opacity: .25;
              background-attachment: fixed;
            }
            
            .revealContentWrap {
              .copyWrap {
                opacity: 1;
              }
            }
            
            .revealBtnWrap {
              height: auto;
              padding: 0;
              min-height: 1px;
            }
            
            .revealContent {}
          }
        }
        
        .revealContentWrap {
          padding-top: 0;
          width: 100%;
          overflow: auto;
          height: auto;
          position: relative;
          z-index: 10;
          
          .copyWrap {
            margin: 0 auto;
            width: 100%;
            opacity: 0;
          }
        }
      }
    }
  }
  
  @media (max-width: $xxs) {
    .revealContentHolder {
      padding: 0 !important;
      
      &.TitleOnly {
        .revealItemHolder {
          width: 100% !important;
          min-width: 50px !important;
        }
      }
    }
  }
}
