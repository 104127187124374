.slideTopBar {
  list-style: none;
  @include zeroMargPad;
  background: $light;
  display: none;
  position: relative;
  @include maxWidth;
  margin-bottom: 15px;
  font-family: $secondaryFont;
  font-weight: 600;
  padding: 15px 20px;
  text-align: right;
  @include rspMargins;
  display: none !important;
  
  .menuBtn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background: $primary;
    color: text-contrast($primary);
    font-size: 1.3em;
    padding: 14px 10px;
    font-weight: 600;
    text-transform: none;
    width: 140px;
    text-align: center;
    @include borderBox;
    cursor: pointer;
    
    &:hover {
      background: $primaryHover;
      color: text-contrast($primaryHover);
    }
  }
}

.currentSectionTitle {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: $basic;
  font-size: 1.125em;
}

.sectionProgWrap {
  padding-right: 150px;
}

.progress {
  color: $dark;
  font-size: 0.8em;
  display: inline-block;
  padding-left: 10px;
  font-weight: 700;
}

.timer {
  position: absolute;
  top: 50%;
  right: 42px;
  width: 220px;
  height: 5px;
  background: $basic;
  transform: translate(0, -50%);
  z-index: 1;
}

.menuBtn {
  background: $primary;
  @include header;
  color: $basic;
  text-align: center;
  font-size: 1.125em;
  font-weight: 400;
  position: fixed;
  bottom: -75px;
  right: 90px;
  z-index: 126;
  transition: .3s all;
  
  
  .inSection & {
    bottom: 20px;
  }
  
  .icon {
    display: inline-block;
    font-size: 2em;
    padding: 9px;
    border: 3px solid $basic;
  }
  
  .text {
    display: none;
  }
  
  &:hover {
    cursor: $cursor;
    background: $basic;
    color: $light;
    
    .icon {
      border: 3px solid $rocket;
      border-image-source: $horizGradient;
      border-image-slice: 1;
    }
  }
}

.progressLeft {
  opacity: 0;
  @include fixed;
  width: 15px;
  height: 100%;
  background: $basic;
  transition: .5s all;
  z-index: 2;
  
  .inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: $vertGradient;
  }
  
  .inSection & {
    opacity: 1;
  }
}

.progressBottom {
  opacity: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  //background: lighten($grey, 10%);
  z-index: 100;
  
  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: linear-gradient(90deg, #EF7622 10%, #D9272E 49%);
  }
  
  .inSection & {
    opacity: 1;
  }
}

@media (max-width:$lg) {
  .timer {
    right: 42px;
    width: 140px;
  }
  
  .menuBtn {
    right: 68px;
  }
}

@media (max-width: $md) {
  .slideTopBar {
    margin: 10px 85px !important;
  }
}

@media (max-width: $sm + 100) {
  .slideTopBar {
    //margin: 25px 45px 0 45px !important;
  }
  
  .progressLeft {
    display: none;
  }
  
  .timer {
    width: 100%;
    top: 62px;
    right: 0;
    height: 8px;
    transform: translate(0, 0);
  }
  
  .menuBtn {
    right: 0;
    bottom: 0 !important;
    cursor: $cursor;
    background: $basic;
    color: $light;
    display: none;
    
    .inSection & {
      display: block;
    }
    
    .icon {
      border: 3px solid $rocket;
      border-image-source: $horizGradient;
      border-image-slice: 1;
    }
  }
}

@media (max-width: $xs) {}
