/**MIXINS**/
@mixin header {
  color: $primary;
  font-family: $primaryFont;
  text-transform: uppercase;
}

@mixin titleFont {
  @include header;
  font-weight: 400;
}

@mixin mainHeadFont {
  @include header;
  font-weight: 700;
}

@mixin bodyCopy {
  color: $basic;
  font-family: $basicFont;
}

@mixin maxWidth {
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 0;
  @include borderBox;
  @include clearfix;
}

@mixin innerMaxWidth {
  max-width: $maxWidth - 30;
  margin: 0 auto;
  padding: 0 15px;
}

/*------------------------
Function that calculates if text needs to be light or dark
depending on the background color passed.

From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
			
Color brightness is determined by the following formula: 
((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
------------------------*/
@function text-contrast($n) {
  $color-brightness: round((red($n) * 299) + (green($n) * 587) + (blue($n) * 114) / 1000);
  $light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000);
  
  @if abs($color-brightness) < ($light-color/2) {
    @return white;
  }
  
  @else {
    @return black;
  }
}

@mixin button($color: $basic, $hoverColor: $hover) {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.6em 1.5em;
  line-height: 1.1em;
  border: 2px solid $primary;
  border-radius: 0;
  font-family: $basicFont;
  background-color: $color;
  color: text-contrast($color);
  outline-offset: 0;
  text-decoration: none;
  @include transition;
  
  @include hover {
    text-decoration: none;
    background-color: $hoverColor;
    color: text-contrast($hoverColor);
    cursor: pointer;
  }
  
  &:focus {
    text-decoration: none;
    background-color: $hoverColor;
    color: text-contrast($hoverColor);
    cursor: pointer;
    outline: 2px solid $color;
  }
  
  a {
    text-decoration: none;
    color: text-contrast($color);
    @include transition;
  }
  
  &:focus a {
    text-decoration: none;
    color: text-contrast($hoverColor);
  }
  
  @include hover {
    a {
      text-decoration: none;
      color: text-contrast($hoverColor);
    }
  }
}

@mixin maxWidth {
  max-width: $maxWidth;
  //width: 100%;
  margin: 0 auto;
}

@mixin rspMargins {
  @media all and (max-width: $xl + 250) {
    margin: 25px 85px 0 85px !important;
  }
  
  @media all and (max-width: $lg) {}
  
  @media all and (max-width: $md) {
    margin: 0 85px !important;
  }
  
  @media all and (max-width: $sm) {
    margin: 25px 45px 0 45px !important;
  }
  
  @media all and (max-width: $xs) {
    margin: 0 !important;
  }
}

@mixin break {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin reformatList {
  list-style-type: none;
  @include zeroMargPad;
  @include clearfix;
}

/***ADA MIXINS***/
@mixin adaHeaderFont {
  color: $basic;
  font-family: $primaryFont;
  line-height: 1.35em;
}

@mixin adaBtns {
  display: inline-block;
  background: $primary;
  color: text-contrast($primary);
  border: 2px solid $basic;
  font-family: $primaryFont;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1em;
  padding: 7px 10px;
  text-decoration: none;
  cursor: pointer;
  
  &:focus {
    background: $ADAhover;
    color: text-contrast($ADAhover);
  }
  
  @include hover {
    background: $ADAhover;
    color: text-contrast($ADAhover);
  }
}

@mixin adaBtnHover {
  text-decoration: underline;
}

@mixin adaSchoolLogo {
  padding: 20px 25px;
  font-family: $primaryFont;
  padding: 8px 0 5px 97px;
  float: left;
  margin: 8px 0 0;
  padding: 0;
}

@mixin adaIconFont {
  font-family: 'ada' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@mixin navFlex() {
  display: flex;
}
